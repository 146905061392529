// @flow
import { type MiddlewareAPI } from 'redux';
import { normalize } from 'normalizr';
import { identity } from 'lodash';
import * as actions from 'src/store/actions';
import { wrapAPIDispatch } from './utilities';

import API_DATA from './APIData';
import APIError from './APIError';
import type { NormalizrSchema } from './schemas';

type Res = {
  status: number,
  json(): Promise<any>,
  ok: boolean,
};

function toAPIError(
  body: {
    message: string,
    trans?: {
      key?: string,
      params?: { [string]: string },
    },
  },
  res: Res,
): APIError {
  const { message, trans, ...extra } = body;
  const err = new APIError({
    message,
    code: res.status,
    trans: trans || {},
    extra,
  });
  return err;
}

function decodeFetchJSON(res: Res): Promise<any> {
  return res.ok
    ? res.json()
    : res.json().then(json => Promise.reject(toAPIError(json, res)));
}

function toQueryString(params: { [string]: string }): string {
  const paramKeys = Object.keys(params).filter(k => params[k] != null);
  const queryString = paramKeys
    .map(k => [k, encodeURIComponent(String(params[k]))].join('='))
    .join('&');
  return paramKeys.length === 0 ? '' : `?${queryString}`;
}

const noop = (...args) => {};

// Redux middleware ----------------------------------------
type APICall = {
  /** Action `type` to issue in the request/success/error actions */
  type: string,
  /** API endpoint */
  endpoint: string,
  /** HTTP method to use */
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  /** HTTP query parameters (?foo=1&bar=2) */
  query: { [string]: string },
  /** HTTP POST body parameters, as serialized JSON */
  body: { [string]: string },
  /** Whether this is an authenticated request (using access token) */
  authentication: boolean,
  /** Schema describing API response, extracting entities */
  schema: NormalizrSchema,
  /** Preprocess result before applying the schema */
  preprocess: ({ [string]: mixed }) => { [string]: mixed },
  /** Postprocess result after applying the schema */
  postprocess: ({ [string]: mixed }) => { [string]: mixed },
  /** Callbacks, invoked on success/error */
  callbacks: {
    onSuccess: <T>(T) => void,
    onError: <T>(T) => void,
  },
};

type APIMiddlewareOptions = {
  APIServerUrl: string,
};

function createAPIMiddleware(
  options: APIMiddlewareOptions = {},
): MiddlewareAPI<> {
  return store => next => action => {
    if (!(API_DATA in action)) {
      return next(action);
    }

    const { APIServerUrl } = options;
    if (APIServerUrl == null) {
      throw new Error('No APIServerUrl defined');
    }

    // Vital parameters
    const {
      type,
      // Describing which endpoint to target
      endpoint,
      method,
      // Describing data to transmit
      query = {}, // GET parameters
      body, // POST data body
      authentication = false,
      // Describing API response
      schema = {},
      preprocess = identity,
      postprocess = identity,

      // callbacks
      callbacks = {
        onSuccess: noop,
        onError: noop,
      },
    }: APICall = action[API_DATA];

    /*
    const {
      entities = {},
    } = action[API_DATA];
    */

    const actionWith = data => {
      const finalAction = Object.assign({}, action, data);
      delete finalAction[API_DATA];
      return finalAction;
    };

    // Issue action for API request
    next(actionWith({ type, status: 'request' }));

    // Need to do an actual API request
    const url = [APIServerUrl, endpoint, toQueryString(query)].join('');

    const headers = {};
    const opts = { method, headers };
    if (authentication) {
      // TODO: populate this token
      const { accessToken } = store.getState().userData;
      headers.authorization = `Bearer ${accessToken}`;
    }
    if (body) {
      // $FlowFixMe: `opts` should be typed to contain 'body'
      opts.body = JSON.stringify(body);
      headers['Content-Type'] = 'application/json';
    }

    const onFetchSuccess = payload => {
      next(actionWith({ type, status: 'success', payload }));
      setTimeout(() => {
        callbacks.onSuccess(payload);
      }, 0);
    };

    const rejectAPICall = error => {
      next(actionWith({ type, status: 'error', error }));
      setTimeout(() => {
        callbacks.onError(error);
      }, 0);
    };

    const performAPIRequest = () =>
      fetch(url, opts)
        .then(decodeFetchJSON)
        .then(preprocess)
        .then(res => normalize(res, schema))
        .then(postprocess);

    const onFetchError = error => {
      if (error.code === 401 && type !== actions.LOGOUT_USER) {
        const { refreshToken, isRefreshingSession } = store.getState().userData;

        const refreshAction = wrapAPIDispatch(
          store.dispatch,
          actions.refreshSession,
        );
        const logoutAction = wrapAPIDispatch(
          store.dispatch,
          actions.logoutUser,
        );

        const logoutAndReject = err => {
          logoutAction().catch(() => {});
          return rejectAPICall(err);
        };

        if (type === actions.REFRESH_SESSION || refreshToken == null) {
          return logoutAndReject(error);
        }

        const retryAfter = delay =>
          new Promise(() => {
            setTimeout(() => {
              const {
                isRefreshingSession: isStillRefreshing,
              } = store.getState().userData;
              if (!isStillRefreshing) {
                const { accessToken } = store.getState().userData;
                if (accessToken != null) {
                  headers.authorization = `Bearer ${accessToken}`;
                  return performAPIRequest().then(onFetchSuccess, onFetchError);
                }
                return rejectAPICall(error);
              }
              return retryAfter(delay);
            }, delay);
          });
        if (isRefreshingSession) {
          return retryAfter(1000);
        }

        if (!isRefreshingSession) {
          return refreshAction({ token: refreshToken })
            .then(() => {
              const { accessToken } = store.getState().userData;
              headers.authorization = `Bearer ${accessToken}`;
              return performAPIRequest().then(onFetchSuccess, onFetchError);
            })
            .catch(logoutAndReject);
        }
      }
      return rejectAPICall(error);
    };

    return performAPIRequest().then(onFetchSuccess, onFetchError);
  };
}

export default createAPIMiddleware;
