// @flow
import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
  type SuccessCallback,
  type ErrorCallback,
} from 'src/store/middleware/api';

import type { CreateLifestyleCategoryParams } from '../create/createLifestyleCategory';

export type PutLifestyleCategoryParams = $ReadOnly<{
  ...CreateLifestyleCategoryParams,
  +id: string,
}>;

export const PUT_LIFESTYLE_CATEGORY = 'PUT_LIFESTYLE_CATEGORY';
const apiFunc = wrapAPIActionCreator<
  PutLifestyleCategoryParams & { entityName: string },
>(({ entityName, id, ...body }) => ({
  [API_DATA]: {
    type: PUT_LIFESTYLE_CATEGORY,
    endpoint: `/api/${encodeURIComponent(entityName)}category/${id}`,
    method: 'PUT',
    authentication: true,
    body,
    schema: {
      [`${entityName}Category`]: Schemas[`${entityName}Category`],
    },
  },
}));

export function putLifestyleCategory(
  entityName: string,
): <T: PutLifestyleCategoryParams>(
  params: $Exact<T>,
  onSuccess?: SuccessCallback,
  onError?: ErrorCallback,
) => any {
  return (params, onSuccess, onError) =>
    apiFunc({ ...params, entityName }, onSuccess, onError);
}
