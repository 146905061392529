// @flow
import React from 'react';
import { Header } from 'semantic-ui-react';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';
import { useParams } from 'react-router-dom';

import EditEntityComp from './components/EditEntity';

const EditMind = ({ t }: { t: TFunc }) => {
  const { id } = useParams();
  const isNew = id === 'new';
  return (
    <div className="max-width max-width-extra-narrow">
      <Header as="h1" className="page-title">
        {isNew ? t('lifestyle.new', 'New') : t('lifestyle.edit', 'Edit')}
      </Header>
      <EditEntityComp />
    </div>
  );
};

export default withTranslation()(EditMind);
