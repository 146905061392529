// @flow
import React from 'react';
/* :: import * as R from 'react'; */

import { Link } from 'react-router-dom';
import { Header, Form, Button, Segment, Message } from 'semantic-ui-react';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

type Event = {
  target: {
    name: string,
    value: string,
  },
};

type Props = {
  handleChange: (event: Event) => void,
  handleSubmit: () => void,
  message: string,
  errorMessage: string,
  email: {
    value: string,
  },
  loading: boolean,
  t: TFunc,
};

function RecoverPasswordView({
  handleChange,
  handleSubmit,
  message,
  errorMessage,
  email,
  loading,
  t,
}: Props): R.Node {
  return (
    <div className="max-width max-width-extra-narrow">
      <Header as="h1" className="page-title">
        {t('recoverPassword.title')}
      </Header>
      <Segment loading={loading}>
        <div className="grid grid-x4">
          {(message || errorMessage) && (
            <div className="column">
              <div className="grid">
                {message && (
                  <div className="column">
                    <Message success content={message} />
                  </div>
                )}
                {errorMessage && (
                  <div className="column">
                    <Message error content={errorMessage} />
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="column">
            <Form onSubmit={handleSubmit}>
              <div className="grid grid-x4">
                <div className="column">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={email.value}
                    onChange={handleChange}
                  />
                </div>
                <div className="column">
                  <Button fluid disabled={loading}>
                    {t('recoverPassword.recoverPasswordButton')}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Segment>
      <div>
        <Link to="/login">{t('recoverPassword.backToLogin')}</Link>
      </div>
    </div>
  );
}

export default withTranslation()(RecoverPasswordView);
