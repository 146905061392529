// @flow
import React, { useState } from 'react';
/* :: import * as R from 'react'; */

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';
import {
  XYPlot,
  makeWidthFlexible,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  HorizontalBarSeries,
  DiscreteColorLegend,
  Hint,
} from 'react-vis';
import { getChallenge } from 'src/store/utilities';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

const FlexibleXYPlot = makeWidthFlexible(XYPlot);

type Props = {
  challengeEngagement: {
    [string]: {
      started: number,
      finished: number,
    },
  },
  challenges: {
    [string]: {
      title: string,
    },
  },
  t: TFunc,
};

const xTickFormat = v => v.toFixed(0);

const getXTickValues = data => {
  let maxX = Math.max(...data.map(({ x }) => x));
  const xTickStep = maxX < 20 ? 5 : 10;

  if (maxX < xTickStep) maxX = xTickStep;

  const xTickValues = [];
  for (let x = 0; x < maxX; x += xTickStep) {
    xTickValues.push(x);
  }
  xTickValues.push(maxX);

  return xTickValues;
};

function ChallengeChart({
  challengeEngagement = {},
  challenges,
  t,
}: Props): R.Node {
  const [hoveredValue, setHoveredValue] = useState(null);
  const challengeIds = Object.keys(challengeEngagement);

  const startedData = challengeIds.map(id => ({
    y: id,
    x: challengeEngagement[id].started,
  }));
  const finishedData = challengeIds.map(id => ({
    y: id,
    x: challengeEngagement[id].finished,
  }));

  const xTickValues = getXTickValues([...startedData, ...finishedData]);
  const xMax = xTickValues.slice(-1)[0];

  const series = [
    {
      key: 'started',
      title: t('organization.specStats.challengeEngagement.usersStarted'),
      color: '#ed6540',
      data: startedData.sort((a, b) => a.x - b.x),
    },
    {
      key: 'finished',
      title: t('organization.specStats.challengeEngagement.usersFinished'),
      color: '#521b44',
      data: finishedData,
    },
  ];

  const yTickFormat = v => {
    if (!challenges[v]) return '';
    if (challenges[v].cid)
      return t(`model:challenges.${challenges[v].cid}.title`);
    if (challenges[v].title) return challenges[v].title;
    return '';
  };

  if (challengeIds.length === 0) {
    const placeholderTextStyle = {
      textAlign: 'center',
      color: '#AAA',
      margin: '2ex auto',
    };
    return (
      <div style={{ height: '100%' }}>
        <Header as="h3">
          {t('organization.specStats.challengeEngagement.title')}
        </Header>
        <p style={placeholderTextStyle}>
          {t('organization.specStats.challengeEngagement.noData')}
        </p>
      </div>
    );
  }

  return (
    <div className="grid">
      <div className="column column-12">
        <Header as="h3">
          {t('organization.specStats.challengeEngagement.title')}
        </Header>
      </div>
      <div className="column column-12">
        <div className="grid flex-direction-column-reverse flex-direction-row-medium">
          <div className="column column-12 column-10-medium">
            <FlexibleXYPlot
              xDomain={[0, xMax]}
              yType="ordinal"
              height={14 * 2 * (2 + challengeIds.length)}
              margin={{
                left: 14 * 10,
                right: 14 * 2,
                top: 14 * 2,
                bottom: 14 * 3,
              }}
            >
              <VerticalGridLines />
              <HorizontalGridLines />
              {series.map(({ key, data, color, title }) => (
                <HorizontalBarSeries
                  key={key}
                  data={data}
                  stroke={color}
                  fill={color}
                  onValueMouseOver={v => setHoveredValue({ ...v, title })}
                  onValueMouseOut={() => setHoveredValue(null)}
                />
              ))}
              <XAxis tickFormat={xTickFormat} tickValues={xTickValues} />
              <YAxis tickFormat={yTickFormat} />
              {hoveredValue && (
                <Hint value={hoveredValue}>
                  <div
                    style={{
                      margin: 4,
                      padding: 4,
                      borderRadius: 4,
                      background: 'rgba(0,0,0, 0.7)',
                    }}
                  >
                    <span>
                      {hoveredValue.title}: {hoveredValue.x}
                    </span>
                  </div>
                </Hint>
              )}
            </FlexibleXYPlot>
          </div>
          <div className="column column-12 column-2-medium">
            <DiscreteColorLegend orientation="vertical" items={series} />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, { challengeEngagement = {} }) => {
  const challengeIds = Object.keys(challengeEngagement);
  const challengesList = challengeIds.map(id => getChallenge(state, id).entity);

  const challengesMap = {};
  challengeIds.forEach((id, i) => {
    challengesMap[id] = challengesList[i];
  });

  return {
    challenges: challengesMap,
  };
};

export default compose(connect(mapStateToProps))(
  withTranslation()(ChallengeChart),
);
