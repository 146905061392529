// @flow
import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
  type SuccessCallback,
  type ErrorCallback,
} from 'src/store/middleware/api';

import pluralize from 'pluralize';

export type LoadLifestyleCategoryParams = {
  +id: string,
};

export const LOAD_LIFESTYLE_CATEGORY = 'LOAD_LIFESTYLE_CATEGORY';
const apiFunc = wrapAPIActionCreator<
  LoadLifestyleCategoryParams & { entityName: string },
>(({ id, entityName }) => ({
  [API_DATA]: {
    type: LOAD_LIFESTYLE_CATEGORY,
    endpoint: `/api/${encodeURIComponent(entityName)}category/${id}`,
    query: { includeUnpublished: 'true' },
    method: 'GET',
    authentication: true,
    schema: {
      [pluralize.plural(entityName)]: [Schemas[entityName]],
      [`${entityName}Category`]: Schemas[`${entityName}Category`],
    },
  },
}));

export function loadLifestyleCategory(
  entityName: string,
): (
  params: LoadLifestyleCategoryParams,
  onSuccess?: SuccessCallback,
  onError?: ErrorCallback,
) => any {
  return (params, onSuccess, onError) =>
    apiFunc({ ...params, entityName }, onSuccess, onError);
}
