// @flow
import React from 'react';
/* :: import * as R from 'react'; */

import {
  Button,
  Header,
  Icon,
  Input,
  Message,
  Segment,
} from 'semantic-ui-react';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

type Props = {
  email: {
    value: string,
  },
  isLoading: boolean,
  message: ?{
    header?: string,
    content: string,
    success?: boolean,
    error?: boolean,
  },
  handleChange: (event: Event) => void,
  handleSubmit: () => void,
  t: TFunc,
};

function WhitelistEmailView({
  email,
  isLoading,
  message,
  handleChange,
  handleSubmit,
  t,
}: Props): R.Node {
  return (
    <React.Fragment>
      <Header as="h2">{t('organization.whitelistEmail.title')}</Header>
      <Segment className="emphasized">
        <p>
          {t('organization.whitelistEmail.description', {
            buttonName: t('common:buttons.add'),
          })}
        </p>
        <Input
          fluid
          action
          placeholder={t('common:formfields.email')}
          iconPosition="left"
          loading={isLoading}
          value={email.value}
          onChange={handleChange}
          onKeyPress={ev => {
            if (ev.which === 13) handleSubmit();
          }}
          type="email"
          name="email"
          id="email"
        >
          <Icon name="mail" />
          <input />
          <Button onClick={handleSubmit}>{t('common:buttons.add')}</Button>
        </Input>
      </Segment>
      {message && <Message {...message} />}
    </React.Fragment>
  );
}

export default withTranslation()(WhitelistEmailView);
