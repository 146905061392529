// @flow
import Backend from 'i18next-locize-backend';
import { i18n } from '@pija-ab/i18n-react-system';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      ns: ['admin', 'model', 'common', 'backend'],
      defaultNS: 'admin',
      fallbackLng: 'en-US',
      debug: false,
      saveMissing: process.env.REACT_APP_LOCIZE_SAVE_MISSING === 'true',
      appendNamespaceToCIMode: true,

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      backend: {
        projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
        apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
        referenceLng: 'en-US',
      },
      detection: {
        order: ['localStorage', 'navigator'],
        lookupLocalStorage: 'i18nextLng',
      },
    },
    err => {
      if (err) console.error('I18NEXT ERROR', err);
    },
  );

export default i18n;
