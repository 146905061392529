// import { Organization } from 'src/types/common';
import {
  API_DATA,
  // Schemas,
  wrapAPIActionCreator,
} from 'src/store/middleware/api';

type RevokeLatestUserAssessmentTypes = {
  organizationId: string;
  userId: string;
};

export const REVOKE_LATEST_ASSESSMENT = 'REVOKE_LATEST_ASSESSMENT';
export const revokeLatestAssessment = wrapAPIActionCreator(
  ({ organizationId, userId }: RevokeLatestUserAssessmentTypes) => ({
    [API_DATA]: {
      type: REVOKE_LATEST_ASSESSMENT,
      endpoint: `/api/organization/${organizationId}/revokeLatestAssessment`,
      method: 'POST',
      authentication: true,
      body: {
        userId,
      },
      // schema: {
      //   organization: Schemas.organization,
      // },
    },
  }),
);
