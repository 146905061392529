// @flow
import React from 'react';
import { Header, Radio, Select, Button, Icon } from 'semantic-ui-react';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';
import type { Lang } from '../VideosContainer';
import type { Video } from '../VideosView';

type Props = {
  video: Video,
  langs: $ReadOnlyArray<Lang>,
  update: (Video | null) => void,
  t: TFunc,
};

const VideoListEntry = ({ video, langs, update, t }: Props) => {
  return (
    <div className="inner-segment">
      <div className="grid">
        <div className="column min-width-0 flex-grow-1 flex-basis-0">
          <div className="grid grid-x0">
            <div className="column">
              <Header as="h3" size="small" className="secondary">
                {video.name}
              </Header>
            </div>
            <div className="column">
              <Select
                fluid
                placeholder={t(
                  'lifestyle.video.edit.selectLang',
                  'Select language',
                )}
                value={video.lng}
                options={[
                  {
                    key: 'none',
                    value: null,
                    text: t(
                      'lifestyle.video.edit.selectLang',
                      'Select language',
                    ),
                  },
                  ...langs.map(l => ({
                    key: l.code,
                    value: l.code,
                    text: l.name,
                  })),
                ]}
                onChange={(ev, { value }) => update({ ...video, lng: value })}
              />
            </div>
            <div className="column">
              <Radio
                name="lol"
                id={`default-vid-select-${video.key}`}
                label="Default Video"
                checked={video.default}
                value={video.key}
                onClick={() => update({ ...video, default: true })}
              />
            </div>
          </div>
        </div>
        <div className="column flex-basis-auto align-self-center display-flex">
          <Button
            icon
            type="button"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() =>
              // eslint-disable-next-line no-alert
              window.confirm(
                `You sure you wanna remove video "${video.name}" from this entity?`,
              ) && update(null)
            }
          >
            <Icon name="delete" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(VideoListEntry);
