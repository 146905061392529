import React, { useState } from 'react';
import { Table, Button } from 'semantic-ui-react';
import moment from 'moment';
import { withTranslation, TFunc } from '@pija-ab/i18n-react-system';
import { Dialog } from '@headlessui/react';
import ActionsDropdownButton, {
  ActionsDropdownButtonProps,
} from 'src/components/ActionsDropdownButton';
import { User } from 'src/types/common';
import { useWrappedDispatch } from 'src/hooks/useWrappedDispatch';
import * as actions from 'src/store/actions';
import { useParams } from 'react-router';
import { useToast } from 'src/components/ui/use-toast';

type Props = {
  userList: User[];
  type: string;
  sortBy: string;
  direction: 'ascending' | 'descending';
  searchValue: string;
  clearFilters: () => void;
  handleSort: (value: string) => void;
  t: TFunc;
};

const UserListTable = ({
  userList,
  type,
  sortBy,
  direction,
  searchValue,
  clearFilters,
  handleSort,
  t,
}: Props) => {
  const formatDate = (date: string) => moment(date).format('YYYY-MM-DD');
  const formatTime = (time: string) => moment(time).format('HH:mm:ss');

  const [revokeAssessmentUser, setRevokeAssessmentUser] = useState<User>();

  const {
    mutate: mutateRevokeAssessment,
    isLoading: isRevokingAssessment,
  } = useWrappedDispatch(actions.revokeLatestAssessment);

  const { organizationId } = useParams<{ organizationId: string }>();

  const toast = useToast();

  return (
    <>
      <Table selectable sortable singleLine basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortBy === 'email' ? direction : undefined}
              onClick={() => handleSort('email')}
            >
              {t('common:formfields.email')}
            </Table.HeaderCell>
            <Table.HeaderCell
              width={2}
              sorted={sortBy === 'points' ? direction : undefined}
              onClick={() => handleSort('points')}
            >
              {t('common:formfields.points')}
            </Table.HeaderCell>
            <Table.HeaderCell
              width={4}
              sorted={sortBy === 'created' ? direction : undefined}
              onClick={() => handleSort('created')}
            >
              {t('common:formfields.created')}
            </Table.HeaderCell>
            <Table.HeaderCell
              width={4}
              sorted={sortBy === 'updated' ? direction : undefined}
              onClick={() => handleSort('updated')}
            >
              {t('organization.userList.latestAssessment')}
            </Table.HeaderCell>
            <Table.HeaderCell
              width={4}
              sorted={sortBy === 'updated' ? direction : undefined}
              onClick={() => handleSort('updated')}
            >
              {t('organization.userList.userActions')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {userList.length ? (
          <Table.Body>
            {userList.map(user => {
              const { id, email, points, createdAt, latestAssessment } = user;
              return (
                <Table.Row key={id}>
                  <Table.Cell>{email}</Table.Cell>
                  <Table.Cell>{points}</Table.Cell>
                  <Table.Cell>
                    {formatDate(createdAt)}{' '}
                    <span style={{ opacity: 0.5 }}>
                      {formatTime(createdAt)}
                    </span>
                  </Table.Cell>
                  <Table.Cell>
                    {latestAssessment ? (
                      <>
                        {formatDate(latestAssessment.createdAt)}{' '}
                        <span style={{ opacity: 0.5 }}>
                          {formatTime(latestAssessment.createdAt)}
                        </span>
                      </>
                    ) : null}
                  </Table.Cell>
                  <Table.Cell>
                    {/* {formatDate(updatedAt)}{' '}
                <span style={{ opacity: 0.5 }}>{formatTime(updatedAt)}</span> */}
                    <ActionsDropdownButton
                      options={
                        [
                          !latestAssessment?.revoked && {
                            text: t(
                              'organization.userList.actions.revokeAssessment',
                            ),
                            onClick: () => setRevokeAssessmentUser(user),
                          },
                        ].filter(
                          Boolean,
                        ) as ActionsDropdownButtonProps['options']
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        ) : (
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="3">
                <div className="grid grid-x1">
                  <div className="column text-align-center">
                    <p>
                      {t(
                        `organization.userList${
                          type ? `.${type}` : ''
                        }.noUsers`,
                      )}
                    </p>
                  </div>
                  {searchValue && (
                    <div className="column display-flex justify-content-center">
                      <Button onClick={clearFilters} className="link">
                        {t('common:buttons.clearFilters')}
                      </Button>
                    </div>
                  )}
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
      <Dialog
        open={revokeAssessmentUser != null}
        onClose={() => setRevokeAssessmentUser(undefined)}
        className="relative z-50"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          {/* The actual dialog panel  */}
          <Dialog.Panel className="mx-auto max-w-lg rounded bg-white p-10">
            <Dialog.Title>Gör om senaste Assessment</Dialog.Title>

            <Dialog.Description>
              <span>{revokeAssessmentUser?.email} </span>
              <span>
                Detta kommer att plocka bort den senaste Assessment som
                användaren gjort, och de får en ny Assessment av samma typ
              </span>
            </Dialog.Description>
            <div className="flex flex-col md:flex-row gap-x-5 gap-y-3 justify-between">
              <Button
                loading={isRevokingAssessment}
                className=""
                onClick={async () => {
                  if (revokeAssessmentUser) {
                    mutateRevokeAssessment({
                      organizationId,
                      userId: revokeAssessmentUser.id,
                    })
                      .then(() => {
                        toast.toast({
                          title: t('common.messages.changesSaved'),
                        });
                      })
                      .finally(() => {
                        setRevokeAssessmentUser(undefined);
                      });
                  }
                }}
              >
                Ta bort senaste Assessment
              </Button>
              <Button
                inverted
                onClick={ev => {
                  ev.preventDefault();
                  setRevokeAssessmentUser(undefined);
                }}
              >
                Avbryt
              </Button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

export default withTranslation()(UserListTable);
