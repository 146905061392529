// @flow
import React from 'react';
import { Form, Button, Header, Segment, Message } from 'semantic-ui-react';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';
import { Link } from 'react-router-dom';

type Props = {
  email: { value: string },
  password: { value: string },
  handleChange: () => void,
  loading: boolean,
  handleSubmit: () => void,
  errorMessage: string,
  t: TFunc,
};

const LoginView = ({
  email,
  password,
  handleChange,
  loading,
  handleSubmit,
  errorMessage,
  t,
}: Props) => (
  <div className="max-width max-width-extra-narrow">
    <Header as="h1" className="page-title">
      {t('login.title')}
    </Header>
    <Segment loading={loading}>
      <div className="grid grid-x4">
        {errorMessage && (
          <div className="column">
            <Message error content={errorMessage} />
          </div>
        )}
        <div className="column">
          <Form onSubmit={handleSubmit}>
            <div className="grid grid-x4">
              <div className="column">
                <div className="grid">
                  <div className="column">
                    <input
                      type="email"
                      name="email"
                      placeholder={t('common:formfields.email')}
                      disabled={loading}
                      value={email.value}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="column">
                    <input
                      type="password"
                      name="password"
                      placeholder={t('common:formfields.password')}
                      disabled={loading}
                      value={password.value}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="column">
                <Button fluid disabled={loading}>
                  {t('common:buttons.login')}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Segment>
    <div className="text-align-right">
      <Link to="/recover-password">{t('login.forgotpassword')}</Link>
    </div>
  </div>
);

export default withTranslation()(LoginView);
