// @flow
import React, { Suspense } from 'react';

import { Loading } from 'src/components/elements';
import NavigationComponent from '../NavigationComponent';
import SideMenu from './components/SideMenu';
import { Provider as SearchProvider } from '../../utilities/searchContext';

const Page = () => {
  return (
    <Suspense fallback={<Loading center />}>
      <SearchProvider>
        <SideMenu>
          <div className="page">
            <div className="page-main">
              <div className="container">
                <NavigationComponent />
              </div>
            </div>
          </div>
        </SideMenu>
      </SearchProvider>
    </Suspense>
  );
};

export default Page;
