// @flow
import React from 'react';
import { Header, Segment, Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

type Props = {
  organization: {
    displayName: string,
    subdomain: string,
    contactEmail: string,
    id: string,
    parentOrganizationId?: string,
  },
  t: TFunc,
};

const OrganizationListItem = ({ organization, t }: Props) => {
  const { displayName, subdomain, contactEmail, id } = organization;
  return (
    <Segment>
      <div className="grid">
        <div className="column flex-grow-1 flex-basis-0">
          <div className="inner-segment">
            <div className="grid grid-x0">
              <div className="column">
                <Header as="h2" size="medium" className="secondary">
                  {displayName}
                </Header>
              </div>
              <div className="column">
                <p>
                  {subdomain}
                  <span style={{ opacity: '0.5' }}>@holistal.com</span>
                </p>
              </div>
              <div className="column">
                <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
              </div>
            </div>
          </div>
        </div>
        <div className="column flex-basis-auto display-flex">
          <Button
            as={Link}
            to={`/organization/${id}`}
            icon
            labelPosition="right"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {t('common:buttons.view')}
            <Icon name="right arrow" />
          </Button>
        </div>
      </div>
    </Segment>
  );
};

export default withTranslation()(OrganizationListItem);
