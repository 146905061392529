// @flow
import React, { PureComponent } from 'react';
import { Header } from 'semantic-ui-react';
import { Trans } from '@pija-ab/i18n-react-system';

import WhitelistedEmailListSearch from './components/WhitelistedEmailListSearch';
import WhitelistedEmailListTable from './components/WhitelistedEmailListTable';

type Props = {
  emailWhitelist: string[],
};

type Sort = 'email';
type Direction = 'ascending' | 'descending';

type State = {
  sorted: ?Sort,
  direction: ?Direction,
  searchValue: string,
};

class WhitelistedEmailList extends PureComponent<Props, State> {
  state = {
    sorted: null,
    direction: null,
    searchValue: '',
  };

  getFilteredList = (): string[] => {
    const { emailWhitelist } = this.props;
    const { searchValue, sorted, direction } = this.state;

    const matchesQuery = email =>
      email.toLowerCase().search(searchValue.toLowerCase()) !== -1;

    const list =
      sorted === 'email' ? emailWhitelist.slice().sort() : emailWhitelist;

    if (direction === 'descending') list.reverse();

    return list.filter(matchesQuery);
  };

  handleSearchChange = (event: Event) => {
    if (event.target instanceof HTMLInputElement) {
      const { value } = event.target;
      this.setState({ searchValue: value });
    }
  };

  clearFilters = () => {
    this.setState({ searchValue: '' });
  };

  handleChangeSort = (sort: Sort) => {
    const { direction } = this.state;
    const newDirection = direction === 'ascending' ? 'descending' : 'ascending';
    this.setState({
      sorted: sort,
      direction: newDirection,
    });
  };

  render() {
    const { emailWhitelist } = this.props;
    const { sorted, direction, searchValue } = this.state;
    const filteredList = this.getFilteredList();

    const n = filteredList.length;
    return (
      <div className="grid">
        <div className="column">
          <Header as="h3" className="secondary">
            {n !== emailWhitelist.length ? (
              <Trans
                i18nKey="organization.userList.whitelistedEmails.filtered"
                count={n}
              >
                {{ count: n }} Whitelisted Email
                <React.Fragment>
                  <span style={{ opacity: 0.75 }}> - </span>
                  {/* $FlowIssue: <Trans> does magic here. */}
                  <span style={{ opacity: 0.5 }}>
                    Out of {{ totEmails: emailWhitelist.length }}
                  </span>
                </React.Fragment>
              </Trans>
            ) : (
              <Trans
                i18nKey="organization.userList.whitelistedEmails.unfiltered"
                count={n}
              >
                {{ count: n }} Whitelisted Email
              </Trans>
            )}
          </Header>
        </div>
        <div className="column">
          <WhitelistedEmailListSearch
            searchValue={searchValue}
            onSearchChange={this.handleSearchChange}
          />
        </div>
        <div className="column">
          <WhitelistedEmailListTable
            whitelist={filteredList}
            sorted={sorted}
            direction={direction}
            searchValue={searchValue}
            clearFilters={this.clearFilters}
            onChangeSort={this.handleChangeSort}
          />
        </div>
      </div>
    );
  }
}

export default WhitelistedEmailList;
