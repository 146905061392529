import React from 'react';
import { Header } from 'src/components/elements';
import { withTranslation, TFunc } from '@pija-ab/i18n-react-system';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import EditUser from 'src/components/EditUser';

const EditUserView = ({
  t,
  match,
}: {
  t: TFunc;
  match: {
    params: {
      userId: string;
    };
  };
}) => {
  return (
    <div className="max-width max-width-extra-narrow">
      <Header>{t('editUser.title')}</Header>
      <EditUser userId={match.params.userId} />
    </div>
  );
};

export default compose(withRouter)(withTranslation()(EditUserView));
