// @flow
import React from 'react';
import { Input } from 'semantic-ui-react';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

type Props = {
  searchValue: string,
  onSearchChange: () => void,
  t: TFunc,
};

const WhitelistedEmailListSearch = ({
  searchValue,
  onSearchChange,
  t,
}: Props) => (
  <Input
    fluid
    placeholder={t('organization.userList.whitelistedEmails.searchPlaceholder')}
    icon="search"
    iconPosition="left"
    value={searchValue}
    onChange={onSearchChange}
  />
);

export default withTranslation()(WhitelistedEmailListSearch);
