// @flow
import { combineReducers } from 'redux';
import * as ActionTypes from 'src/store/actions';

import type { VimeoVideo } from 'src/utilities/flowtypes';

function all(state: string[] = [], action: any): string[] {
  switch (action.type) {
    case ActionTypes.LOAD_VIMEO_VIDEO:
      return state;
    case ActionTypes.LOAD_ALL_VIMEO_VIDEOS:
      if (action.status === 'success') {
        return action.payload.result.vimeoVideos;
      }
      return state;
    case ActionTypes.LOGOUT_USER:
      if (action.status === 'success') {
        return [];
      }
      return state;
    default:
      return state;
  }
}

function byId(state: VimeoVideo = {}, action: any): VimeoVideo {
  switch (action.type) {
    case ActionTypes.LOAD_VIMEO_VIDEO:
      if (action.status === 'success') {
        const {
          entities: { vimeoVideo },
        } = action.payload;
        return {
          ...state,
          ...vimeoVideo,
        };
      }
      return state;

    case ActionTypes.LOGOUT_USER:
      return {};

    default:
      return state;
  }
}

const organizations = combineReducers({
  all,
  byId,
});

export default organizations;
