// @flow
import { combineReducers } from 'redux';
import * as ActionTypes from 'src/store/actions';

import type { BaseEntity } from 'src/utilities/flowtypes';

function all(
  state: { [string]: string[] } = {},
  action: any,
): { [string]: string[] } {
  switch (action.type) {
    case ActionTypes.LOAD_LIFESTYLE_ENTITY:
    case ActionTypes.PUT_LIFESTYLE_ENTITY:
    case ActionTypes.CREATE_LIFESTYLE_ENTITY:
      return state;
    case ActionTypes.LOAD_ALL_LIFESTYLE_ENTITIES:
      if (action.status === 'success') {
        const [key] = Object.keys(action.payload.result);
        return {
          ...state,
          [key]: action.payload.result[key],
        };
      }
      return state;
    case ActionTypes.LOGOUT_USER:
      if (action.status === 'success') {
        return {};
      }
      return state;
    default:
      return state;
  }
}

function byId<T: BaseEntity>(
  state: { [string]: $Shape<T> } = {},
  action: any,
): { [string]: $Shape<T> } {
  switch (action.type) {
    case ActionTypes.LOAD_LIFESTYLE_ENTITY:
    case ActionTypes.PUT_LIFESTYLE_ENTITY:
    case ActionTypes.CREATE_LIFESTYLE_ENTITY:
      if (action.status === 'success') {
        const [entityName] = Object.keys(action.payload.result);
        return {
          ...state,
          [entityName]: {
            ...state[entityName],
            ...action.payload.entities[entityName],
          },
        };
      }
      return state;

    case ActionTypes.LOGOUT_USER:
      return {};

    default:
      return state;
  }
}

const lifestyleEntities = combineReducers({
  all,
  byId,
});

export default lifestyleEntities;
