// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { wrapAPIDispatch } from 'src/store/middleware/api';
import * as actions from 'src/store/actions';
import { getEntityData } from 'src/store/utilities';
import type { BaseCategory } from 'src/utilities/flowtypes';

import CategoryListView from 'src/components/Lifestyle/CategoryListView';

type Props = {
  loadAllMobilityCategories: () => void,
  categories: $ReadOnlyArray<BaseCategory>,
};

class CategoryListContainer extends Component<Props> {
  componentDidMount() {
    const { loadAllMobilityCategories } = this.props;
    loadAllMobilityCategories();
  }

  render() {
    return <CategoryListView {...this.props} entityName="mobility" />;
  }
}

const mapStateToProps = state => {
  const categories = (
    state.lifestyleCategories.all.mobilityCategories || []
  ).map(
    mobilityCategoryId =>
      getEntityData(state, 'mobilityCategory', mobilityCategoryId).entity,
  );
  return {
    categories,
  };
};
const mapDispatchToProps = dispatch => ({
  loadAllMobilityCategories: wrapAPIDispatch(
    dispatch,
    actions.loadAllLifestyleCategories('mobility'),
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CategoryListContainer),
);
