// @flow
import React, { useState, useCallback } from 'react';
import { Header, Segment, SegmentGroup } from 'semantic-ui-react';
import { Loading } from 'src/components/elements';
import { Trans, withTranslation } from '@pija-ab/i18n-react-system';
import classNames from 'classnames';
import { useSearchContext } from 'src/utilities/searchContext';
import OrganizationListItem from './components/OrganizationListItem';

type Props = {
  organizations: [
    {
      displayName: string,
      subdomain: string,
      contactEmail: string,
      id: string,
    },
  ],
};

const searchFilter = (organizations, search) => {
  if (!search) return organizations;
  return organizations.filter(org => {
    return org.displayName.toLowerCase().includes(search.toLowerCase());
  });
};

const OrganizationListView = ({ organizations }: Props) => {
  const [organizationType, setOrganizationType] = useState('active');
  if (organizations[0] === null) {
    return <Loading center />;
  }
  const { search } = useSearchContext();
  const searchOrgs = search?.['/organizations'];
  const activeOrganizations = searchFilter(organizations, searchOrgs).filter(
    org => !org.archived && !org.isRemoved,
  );
  const archivedOrganizations = searchFilter(organizations, searchOrgs).filter(
    org => org.archived && !org.isRemoved,
  );
  const orgCount = activeOrganizations.length;
  const archivedCount = archivedOrganizations.length;

  const getParents = useCallback(orgs => {
    if (!orgs) return [];
    return orgs
      .filter(org => org.parentOrganizationId == null)
      .sort((a, b) => a.displayName.localeCompare(b.displayName));
  }, []);

  const getChildren = useCallback(
    parentOrganization => {
      if (!organizations) return [];
      if (organizationType === 'active') {
        return organizations
          .filter(
            org =>
              org.parentOrganizationId === parentOrganization.id &&
              !org.archived,
          )
          .sort((a, b) => a.displayName.localeCompare(b.displayName));
      }
      return organizations
        .filter(
          org =>
            org.parentOrganizationId === parentOrganization.id &&
            org.archived === true,
        )
        .sort((a, b) => a.displayName.localeCompare(b.displayName));
    },
    [organizations],
  );

  return (
    <div className="max-width max-width-extra-narrow">
      <button
        type="button"
        className="border-none bg-transparent cursor-pointer"
        onClick={() => setOrganizationType('active')}
      >
        <Header
          as="h1"
          className={classNames(
            'page-title',
            organizationType === 'active' && 'underline',
          )}
        >
          <Trans i18nKey="organizationList.organizations">
            {/* $FlowIssue: <Trans> does magic here. */}
            Organizations <span style={{ opacity: 0.5 }}>({{ orgCount }})</span>
          </Trans>
        </Header>
      </button>
      <button
        type="button"
        className="border-none bg-transparent cursor-pointer"
        onClick={() => setOrganizationType('archived')}
      >
        <Header
          as="h2"
          className={classNames(organizationType === 'archived' && 'underline')}
        >
          <Trans i18nKey="organizationList.archivedOrganizations">
            {/* $FlowIssue: <Trans> does magic here. */}
            Archived <span style={{ opacity: 0.5 }}>({{ archivedCount }})</span>
          </Trans>
        </Header>
      </button>
      {organizationType === 'active' &&
        getParents(activeOrganizations).map(organization => {
          const orgChildren = getChildren(organization);
          return (
            <Segment
              key={organization.id}
              className={orgChildren.length > 0 ? 'emphasized-left' : ''}
            >
              <OrganizationListItem
                key={organization.id}
                organization={organization}
              />
              {/* <div className="ms-4"> */}
              {orgChildren.length > 0 && (
                <SegmentGroup>
                  {orgChildren.map(childOrg => (
                    <OrganizationListItem
                      key={childOrg.id}
                      organization={childOrg}
                    />
                  ))}
                </SegmentGroup>
              )}
              {/* </div> */}
            </Segment>
          );
        })}
      {organizationType === 'archived' &&
        archivedOrganizations.map(organization => {
          return (
            <Segment key={organization.id}>
              <OrganizationListItem
                key={organization.id}
                organization={organization}
              />
            </Segment>
          );
        })}
    </div>
  );
};

export default withTranslation()(OrganizationListView);
