import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
} from 'src/store/middleware/api';

export const ARCHIVE_USER = 'ARCHIVE_USER';
export const archiveUser = wrapAPIActionCreator(
  ({ userId }: { userId: string }) => ({
    [API_DATA]: {
      type: ARCHIVE_USER,
      endpoint: `/api/user/${userId}/archive`,
      method: 'POST',
      authentication: true,
      schema: {
        user: Schemas.user,
      },
    },
  }),
);
