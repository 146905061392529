// @flow
import { schema } from 'normalizr';

const { Entity } = schema;

export type NormalizrSchema =
  | schema.Array
  | schema.Entity
  | schema.Object
  | schema.Union
  | schema.Values;

// Declare entity types
export const challenge = new Entity('challenges', {}, {});
export const user = new Entity('users', {}, {});
export const organization = new Entity('organizations', {}, {});
export const goal = new Entity('goals', {}, {});
export const vimeoVideo = new Entity('vimeoVideo', {}, {});

export const mobilityCategory = new Entity('mobilityCategory', {}, {});
export const mobility = new Entity(
  'mobility',
  { categories: [mobilityCategory] },
  {},
);

export const sleepCategory = new Entity('sleepCategory', {}, {});
export const sleep = new Entity('sleep', { categories: [sleepCategory] }, {});

export const mindCategory = new Entity('mindCategory', {}, {});
export const mind = new Entity('mind', { categories: [mindCategory] }, {});
