import React, { useRef, MouseEventHandler } from 'react';
import { Dialog } from '@headlessui/react';
import { withTranslation, TFunc } from '@pija-ab/i18n-react-system';

export interface ArchiveOrganizationModalProps {
  setIsModalOpen: (value: boolean) => void;
  isModalOpen: boolean;
  handleArchiveOrganization: MouseEventHandler<HTMLButtonElement>;
  t: TFunc;
  childOrganizationCount: number;
}

function ArchiveOrganizationModal({
  setIsModalOpen,
  isModalOpen,
  handleArchiveOrganization,
  t,
  childOrganizationCount,
}: Readonly<ArchiveOrganizationModalProps>): JSX.Element {
  const completeButtonRef = useRef(null);

  const confirmArchive: MouseEventHandler<HTMLButtonElement> = evt => {
    setIsModalOpen(false);
    handleArchiveOrganization(evt);
  };

  return (
    <Dialog
      initialFocus={completeButtonRef}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/70" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4 min-h-full text-center">
        <Dialog.Panel className="p-6 w-full  mx-auto max-w-sm rounded bg-white text-left align-middle">
          <Dialog.Title
            as="h3"
            className="text-md font-medium leading-6 text-gray-900"
          >
            {t(
              'organization.archive.areYouSure',
              'Are you sure you want to archive this organization?',
            )}
          </Dialog.Title>
          {childOrganizationCount > 0 && (
            <Dialog.Description className="font-medium leading-6 text-gray-900">
              {t(
                'organization.archive.archiveSubOrganizations',
                'You will archive {{childOrganizationCount}} sub-organizations aswell.',
                {
                  childOrganizationCount,
                },
              )}
            </Dialog.Description>
          )}

          <div className="flex justify-between mt-4">
            <button
              type="button"
              onClick={() => setIsModalOpen(false)}
              className="p-2 bg-green-500 text-white rounded-md border-none cursor-pointer"
            >
              {t('common:buttons.cancel', 'Cancel')}
            </button>
            <button
              type="button"
              ref={completeButtonRef}
              onClick={confirmArchive}
              className="p-2 bg-red-500 text-white rounded-md border-none cursor-pointer"
            >
              {t('organization.archive.archive', 'Archive')}
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default withTranslation()(ArchiveOrganizationModal);
