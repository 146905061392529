// @flow
import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
} from 'src/store/middleware/api';

type WhitelistEmailTypes = {
  organizationId: string,
  emailWhitelist: string[],
};

export const WHITELIST_EMAIL = 'WHITELIST_EMAIL';
export const whitelistEmail = wrapAPIActionCreator<WhitelistEmailTypes>(
  ({ organizationId, emailWhitelist }) => ({
    [API_DATA]: {
      type: WHITELIST_EMAIL,
      endpoint: `/api/organization/${organizationId}/email-whitelist`,
      method: 'POST',
      authentication: true,
      body: {
        emailWhitelist,
      },
      schema: {
        organization: Schemas.organization,
      },
    },
  }),
);
