import React, { Component } from 'react';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { wrapAPIDispatch } from 'src/store/middleware/api';
import * as actions from 'src/store/actions';

import {
  HHI,
  Organization,
  OrganizationStatistics,
  SubOrgHHI,
  User,
} from 'src/types/common';
import { getOrganization, getLoggedInUser, getUser } from 'src/store/utilities';
import NotFound from 'src/scenes/NotFound';

import OrganizationView from './OrganizationView';
import SettingsView from './SettingsView';
import { TFunc } from '@pija-ab/i18n-react-system';
import UsersView from './UsersView';
import OrganizationStatisticsSpecific from './components/OrganizationStatisticsSpecific';

type OrganizationContainerProps = {
  match: {
    params: {
      organizationId: string;
    };
  };
  loadOrganization: ({ organizationId }: { organizationId: string }) => void;
  loadOrganizationStatistics: ({
    organizationId,
  }: {
    organizationId: string;
  }) => void;
  loadChildOrganizations: ({
    organizationId,
  }: {
    organizationId: string;
  }) => void;
  loggedInUser: {
    role: string;
    organizationId: string;
  };
  organization: Organization;
  childOrganizations: Organization[];
  archiveOrganization: ({
    organizationId,
  }: {
    organizationId: string;
  }) => Promise<void>;
  unarchiveOrganization: ({
    organizationId,
  }: {
    organizationId: string;
  }) => Promise<void>;
  viewType: string;
  organizationStatistics: OrganizationStatistics;
  t: TFunc;
  aggregatedParentHHI: {
    organizationHHI: HHI;
    subOrgs?: SubOrgHHI[];
  };
};

class OrganizationContainer extends Component<OrganizationContainerProps> {
  state = {
    loading: false,
  };

  componentDidMount() {
    const { loggedInUser } = this.props;
    const {
      match: {
        params: { organizationId },
      },
      loadOrganization,
      loadOrganizationStatistics,
      loadChildOrganizations,
    } = this.props;
    if (
      loggedInUser.role === 'hr' &&
      loggedInUser.organizationId !== organizationId
    ) {
      return;
    }
    if (loggedInUser.role === 'administrator') {
      Promise.all([loadChildOrganizations({ organizationId })]).catch(() => {});
    }
    Promise.all([
      loadOrganization({ organizationId }),
      loadOrganizationStatistics({ organizationId }),
    ]).catch(() => {});
  }

  render() {
    const {
      match: {
        params: { organizationId },
      },
      archiveOrganization,
      unarchiveOrganization,
    } = this.props;
    const {
      loggedInUser,
      viewType,
      childOrganizations,
      organization,
      organizationStatistics,
    } = this.props;

    const { loading } = this.state;

    if (
      loggedInUser.role === 'hr' &&
      loggedInUser.organizationId !== organizationId
    ) {
      return <NotFound />;
    }

    if (viewType === 'statisticsView') {
      return (
        // <StatisticsView
        //   organizationStatistics={this.props.organizationStatistics}
        //   organization={this.props.organization}
        //   childOrganizations={childOrganizations}
        // />
        <div className="column">
          <OrganizationStatisticsSpecific
            organizationStatistics={organizationStatistics}
            organization={organization}
            childOrganizations={childOrganizations}
            aggregatedParentHHI={this.props.aggregatedParentHHI}
          />
        </div>
      );
    }
    if (childOrganizations !== null && viewType === 'settingsView') {
      return (
        <SettingsView
          organizationStatistics={this.props.organizationStatistics}
          aggregatedParentHHI={this.props.aggregatedParentHHI}
          userRole={loggedInUser.role}
          organization={this.props.organization}
          childOrganizations={childOrganizations}
        />
      );
    }
    if (viewType === 'usersView') {
      return (
        <UsersView {...this.props} organization={this.props.organization} />
      );
    }

    return (
      <OrganizationView
        {...this.props}
        userRole={loggedInUser.role}
        loading={loading}
        archiveOrganizationAction={archiveOrganization}
        unarchiveOrganizationAction={unarchiveOrganization}
        childOrganizations={childOrganizations}
      />
    );
  }
}
// @ts-expect-error State has no type yet
const mapStateToProps = (state, ownProps: OrganizationContainerProps) => {
  const { organizationId } = ownProps.match.params;

  const {
    hrUsers: hrUserIds = [],
    users: userIds = [],
    statistics = {},
    adminUsers: adminUserIds = [],
    aggregatedParentHHI = {},
  } = state.organizations.byId[organizationId] || {};
  const childOrganizationIds =
    state.organizations.childOrganizationsByParentId[organizationId] || [];

  const adminUsers = adminUserIds
    .map((id: string) => getUser(state, id).entity)
    .filter((user: User) => !user.archived);
  const hrUsers = hrUserIds
    .map((id: string) => getUser(state, id).entity)
    .filter((user: User) => !user.archived);
  const users = userIds
    .map((id: string) => getUser(state, id).entity)
    .filter((user: User) => !user.archived);

  return {
    loggedInUser: getLoggedInUser(state).entity,
    organization: getOrganization(state, organizationId).entity,
    childOrganizations: childOrganizationIds.map(
      (orgId: string) => getOrganization(state, orgId).entity,
    ),
    organizationStatistics: statistics,
    adminUsers,
    hrUsers,
    users,
    aggregatedParentHHI,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadOrganization: wrapAPIDispatch(dispatch, actions.loadOrganization),
  loadChildOrganizations: wrapAPIDispatch(
    dispatch,
    actions.loadChildOrganizations,
  ),
  archiveOrganization: wrapAPIDispatch(dispatch, actions.archiveOrganization),
  unarchiveOrganization: wrapAPIDispatch(
    dispatch,
    actions.unarchiveOrganization,
  ),
  loadOrganizationStatistics: wrapAPIDispatch(
    dispatch,
    actions.loadOrganizationStatistics,
  ),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OrganizationContainer);
