// @flow
import React from 'react';
import { Header } from 'semantic-ui-react';

type Props = {
  id: string,
  value: number,
  text: string,
};

const StatisticItem = ({ id, value, text }: Props) => (
  <div
    className={`organization-statistic-item organization-statistic-item-type-${id}`}
  >
    <Header as="h3" size="large">
      {value}
      <Header.Subheader>{text}</Header.Subheader>
    </Header>
  </div>
);

export default StatisticItem;
