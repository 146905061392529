// @flow
import { combineReducers } from 'redux';
import * as ActionTypes from 'src/store/actions';

import type { Organization } from 'src/utilities/flowtypes';

function all(state: string[] = [], action: any): string[] {
  switch (action.type) {
    case ActionTypes.LOAD_ORGANIZATION:
      return state;
    case ActionTypes.LOAD_ALL_ORGANIZATIONS:
      if (action.status === 'success') {
        return action.payload.result.organizations;
      }
      return state;
    case ActionTypes.LOGOUT_USER:
      if (action.status === 'success') {
        return [];
      }
      return state;
    default:
      return state;
  }
}

function byId(state: Organization = {}, action: any): Organization {
  switch (action.type) {
    case ActionTypes.LOAD_ORGANIZATION:
      if (action.status === 'success') {
        const {
          organization,
          users,
          hrUsers,
          adminUsers,
          aggregatedParentHHI,
        } = action.payload.result;
        return {
          ...state,
          [organization]: {
            ...(state[organization] || {}),
            users,
            hrUsers,
            adminUsers,
            aggregatedParentHHI,
          },
        };
      }
      return state;

    case ActionTypes.LOAD_ORGANIZATION_STATISTICS:
      if (action.status === 'success') {
        const { organization, organizationStatistics } = action.payload.result;
        return {
          ...state,
          [organization]: {
            ...(state[organization] || {}),
            statistics: organizationStatistics,
          },
        };
      }
      return state;

    case ActionTypes.LOGOUT_USER:
      return {};

    default:
      return state;
  }
}

function childOrganizationsByParentId(
  state: Record<string, string[]> = {},
  action: any,
): Record<string, string[]> {
  switch (action.type) {
    case ActionTypes.LOAD_CHILD_ORGANIZATIONS:
      if (action.status === 'success') {
        const { childOrganizations } = action.payload.result;
        if (childOrganizations && childOrganizations.length > 0) {
          return {
            ...state,
            [action.actionParams.organizationId]: childOrganizations,
          };
        }
      }
      return state;

    case ActionTypes.LOGOUT_USER:
      return {};

    default:
      return state;
  }
}

const organizations = combineReducers({
  all,
  byId,
  childOrganizationsByParentId,
});

export default organizations;
