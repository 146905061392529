// @flow
import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
  type SuccessCallback,
  type ErrorCallback,
} from 'src/store/middleware/api';

export type CreateLifestyleEntityParams = {
  +referenceTitle: string,
  +author: string,
  +referenceDescription: string,
  +categories: $ReadOnlyArray<string>,
  +published: boolean,
};

export type CreateVideoEntityParams = {
  ...CreateLifestyleEntityParams,
  +videos: $ReadOnlyArray<{ +id: string, +lng: string, +default: boolean }>,
  +imageURL?: string,
};

export type CreateMobilityParams = {
  ...CreateVideoEntityParams,
  +referenceSteps: $ReadOnlyArray<string>,
};

export const CREATE_LIFESTYLE_ENTITY = 'CREATE_LIFESTYLE_ENTITY';
const apiFunc = wrapAPIActionCreator<
  CreateLifestyleEntityParams & { entityName: string },
>(({ entityName, ...body }) => ({
  [API_DATA]: {
    type: CREATE_LIFESTYLE_ENTITY,
    endpoint: `/api/${encodeURIComponent(entityName)}`,
    method: 'POST',
    authentication: true,
    body,
    schema: {
      [entityName]: Schemas[entityName],
    },
  },
}));

export function createLifestyleEntity(
  entityName: string,
): <T: CreateLifestyleEntityParams>(
  params: $Exact<T>,
  onSuccess?: SuccessCallback,
  onError?: ErrorCallback,
) => any {
  return (params, onSuccess, onError) =>
    apiFunc({ ...params, entityName }, onSuccess, onError);
}
