// @flow
import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
} from 'src/store/middleware/api';

export const LOAD_ALL_ORGANIZATIONS = 'LOAD_ALL_ORGANIZATIONS';
export const loadAllOrganizations = wrapAPIActionCreator<void>(() => ({
  [API_DATA]: {
    type: LOAD_ALL_ORGANIZATIONS,
    endpoint: '/api/organization',
    method: 'GET',
    authentication: true,
    schema: { organizations: [Schemas.organization] },
  },
}));
