// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';
import { Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { getIsLoggedIn, getLoggedInUser } from 'src/store/utilities';
import { wrapAPIDispatch } from 'src/store/middleware/api';
import * as actions from 'src/store/actions';

type Props = {
  t: TFunc,
};

const NotFound = withTranslation()(({ t }: Props) => (
  <div className="text-align-center">
    <Header as="h1" className="page-title">
      {t('notfound.title')}
    </Header>
    <p>{t('notfound.text')}</p>
    <Link to="/">{t('common:buttons.home')}</Link>
  </div>
));

type PageProps = {
  isLoggedIn: boolean,
  loggedInUser: {
    role: string,
  },
  isLoadingUser: boolean,
  loadCurrentUser: () => Promise<void>,
};

class NotFoundPage extends PureComponent<PageProps> {
  componentDidMount() {
    const {
      isLoggedIn,
      loggedInUser,
      isLoadingUser,
      loadCurrentUser,
    } = this.props;
    if (!isLoggedIn || isLoadingUser) {
      return;
    }
    if (loggedInUser == null) {
      loadCurrentUser().catch(error => console.error(error));
    }
  }

  render() {
    return <NotFound />;
  }
}

const mapStateToProps = state => ({
  isLoadingUser: state.userData.isLoadingUser,
  isLoggedIn: getIsLoggedIn(state),
  loggedInUser: getLoggedInUser(state).entity,
});

const mapDispatchToProps = dispatch => ({
  loadCurrentUser: wrapAPIDispatch(dispatch, actions.loadCurrentUser),
});

const NotFoundPageExport = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotFoundPage);

export { NotFound as default, NotFoundPageExport as NotFoundPage };
