// @flow
import React from 'react';
/* :: import * as R from 'react'; */

import { Link } from 'react-router-dom';
import { Header, Form, Button, Segment, Message } from 'semantic-ui-react';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

type Event = {
  target: {
    name: string,
    value: string,
  },
};

type Props = {
  password: {
    value: string,
  },
  confirmPassword: {
    value: string,
  },
  message: string,
  errorMessage: string,
  handleChange: (event: Event) => void,
  handleSubmit: () => void,
  loading: boolean,
  completed: boolean,
  t: TFunc,
};

function ResetPasswordView({
  password,
  confirmPassword,
  handleChange,
  handleSubmit,
  message,
  errorMessage,
  loading,
  completed,
  t,
}: Props): R.Node {
  return (
    <div className="max-width max-width-extra-narrow">
      <Header as="h1" className="page-title">
        {!completed
          ? t('resetPassword.title')
          : t('resetPassword.passwordReset')}
      </Header>
      <Segment loading={loading}>
        <div className="grid grid-x4">
          {(message || errorMessage) && (
            <div className="column">
              <div className="grid">
                {message && (
                  <div className="column">
                    <Message success content={message} />
                  </div>
                )}
                {errorMessage && (
                  <div className="column">
                    <Message error content={errorMessage} />
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="column">
            {!completed ? (
              <Form onSubmit={handleSubmit}>
                <div className="grid grid-x4">
                  <div className="column">
                    <div className="grid">
                      <div className="column">
                        <label className="form-label" htmlFor="password">
                          {t('resetPassword.newPassword')}
                        </label>
                        <input
                          type="password"
                          name="password"
                          id="password"
                          value={password.value}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="column">
                        <label className="form-label" htmlFor="confirmPassword">
                          {t('resetPassword.repeatPassword')}
                        </label>
                        <input
                          type="password"
                          name="confirmPassword"
                          id="confirmPassword"
                          value={confirmPassword.value}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <Button fluid disabled={loading}>
                      {t('resetPassword.resetPasswordButton')}
                    </Button>
                  </div>
                </div>
              </Form>
            ) : (
              <Button fluid as={Link} to="/login">
                {t('resetPassword.backToLogin')}
              </Button>
            )}
          </div>
        </div>
      </Segment>
    </div>
  );
}

export default withTranslation()(ResetPasswordView);
