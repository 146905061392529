// @flow
import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
  type SuccessCallback,
  type ErrorCallback,
} from 'src/store/middleware/api';

import type {
  CreateLifestyleEntityParams,
  CreateVideoEntityParams,
  CreateMobilityParams,
} from '../create/createLifestyleEntity';

export type PutLifestyleEntityParams = $ReadOnly<{
  ...CreateLifestyleEntityParams,
  +id: string,
}>;

export type PutVideoEntityParams = {
  ...CreateVideoEntityParams,
  +id: string,
};

export type PutMobilityParams = {
  ...CreateMobilityParams,
  +referenceSteps: $ReadOnlyArray<string>,
};

export const PUT_LIFESTYLE_ENTITY = 'PUT_LIFESTYLE_ENTITY';
const apiFunc = wrapAPIActionCreator<
  PutLifestyleEntityParams & { entityName: string },
>(({ entityName, id, ...body }) => ({
  [API_DATA]: {
    type: PUT_LIFESTYLE_ENTITY,
    endpoint: `/api/${encodeURIComponent(entityName)}/${id}`,
    authentication: true,
    method: 'PUT',
    body,
    schema: {
      [entityName]: Schemas[entityName],
    },
  },
}));

export function putLifestyleEntity(
  entityName: string,
): <T: PutLifestyleEntityParams>(
  params: $Exact<T>,
  onSuccess?: SuccessCallback,
  onError?: ErrorCallback,
) => any {
  return (params, onSuccess, onError) =>
    apiFunc({ ...params, entityName }, onSuccess, onError);
}
