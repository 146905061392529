// @flow
import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { UserIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

const UserDropdown = ({
  user,
  t,
  logout,
}: {
  user: { email: string },
  t: TFunc,
  logout: () => void,
}): R.Node => {
  const { role, id: userId } = user || {};
  const displayedRole = t(`common:userTypes.${role}`, role);

  const userNavigation = user
    ? [
        { name: t('header.user.role', { role: displayedRole }), type: 'text' },
        {
          name: t('header.user.userInfo'),
          type: 'link',
          href: `/user/${userId}`,
        },
        { name: t('common:buttons.logOut'), type: 'button', onClick: logout },
      ]
    : [];

  const renderUserNavigation = item => {
    if (item.type === 'link') {
      return (
        <Menu.Item key={item.name} className="px-2 py-2">
          {({ active }) => (
            <Link
              to={item.href}
              className={classNames(
                active ? 'bg-gray-50' : '',
                'block px-3 py-1 leading-6 text-gray-900 no-underline hover:no-underline',
              )}
            >
              {item.name}
            </Link>
          )}
        </Menu.Item>
      );
    }
    if (item.type === 'button') {
      return (
        <Menu.Button
          onClick={item.onClick}
          key={item.name}
          className="inline-flex w-full bg-transparent hover:bg-gray-50 px-3 py-2 outline-none cursor-pointer border-none hover:text-primary-500"
        >
          {item.name}
        </Menu.Button>
      );
    }
    return (
      <Menu.Item key={item.name} as="div" className="px-2 py-2 text-center">
        {item.name}
      </Menu.Item>
    );
  };

  return (
    <Menu as="div" className="relative">
      <Menu.Button className="-m-1.5 flex items-center p-1.5 bg-transparent border-none cursor-pointer">
        <span className="sr-only">Open user menu</span>
        <div className="h-8 w-8 bg-slate-200 p-1.5 rounded-full">
          <UserIcon className="text-paradiso-500" />
        </div>
        <span className="hidden lg:flex lg:items-center">
          <span
            className="ml-4 font-semibold leading-6 text-paradiso-500"
            aria-hidden="true"
          >
            {user.email}
          </span>
          <ChevronDownIcon
            className="ml-2 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2.5 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none min-w-[140px]">
          {userNavigation.map(item => renderUserNavigation(item))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default withTranslation()(UserDropdown);
