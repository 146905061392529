import React from 'react';
import { Loading } from 'src/components/elements';
import AssessmentInterval from './components/AssessmentInterval';
import EmployeeCounts from './components/EmployeeCounts';
import Incentive from './components/Incentive';
import { withTranslation } from '@pija-ab/i18n-react-system';
import { HHI, Organization, SubOrgHHI } from 'src/types/common';

const SettingsView = ({
  userRole,
  organization,
}: {
  userRole: string;
  aggregatedParentHHI: {
    organizationHHI: HHI;
    subOrgs?: SubOrgHHI[];
  };
  organization: Organization;
  childOrganizations: Organization[];
}) => {
  const { incentive, employeeCounts, isParentOnly } = organization ?? {};

  if (!organization) {
    return <Loading center />;
  }

  return (
    <>
      <div className="column">
        {!isParentOnly && (
          <>
            <div className="column">
              <Incentive
                incentive={incentive}
                organizationId={organization.id}
              />
            </div>
            {userRole === 'administrator' && (
              <div className="column">
                <EmployeeCounts
                  employeeCounts={employeeCounts}
                  organizationId={organization.id}
                />
              </div>
            )}

            {userRole === 'administrator' && (
              <div className="column">
                <AssessmentInterval
                  assessmentIntervalDays={organization.assessmentIntervalDays}
                  organizationId={organization.id}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default withTranslation()(SettingsView);
