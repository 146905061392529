// @flow
import React, { useState, useEffect } from 'react';
import * as actions from 'src/store/actions';
import { Segment, Form, Button, Message } from 'semantic-ui-react';
import { Loading } from 'src/components/elements';
import {
  withTranslation,
  backendT,
  type TFunc,
} from '@pija-ab/i18n-react-system';

import type {
  BaseCategory,
  ModifyableBaseCategory,
} from 'src/utilities/flowtypes';

import BasicCategoryInfo from 'src/components/Lifestyle/BasicCategoryInfo';

type Props = {
  initialCategory: ?BaseCategory,
  loading: boolean,
  error: ?Error,
  remove: null | (() => void),
  successKey: ?string,
  save: (
    $ReadOnly<{
      ...actions.CreateLifestyleCategoryParams,
      +id?: string,
    }>,
  ) => Promise<void>,
  t: TFunc,
};

const DEFAULT_CATEGORY: ModifyableBaseCategory = {
  referenceTitle: '',
  referenceDescription: '',
  image: null,
  published: true,
};

const EditCategoryView = ({
  initialCategory,
  loading,
  error,
  successKey,
  remove,
  save,
  t,
}: Props) => {
  const [category, updateCategory] = useState<ModifyableBaseCategory>(
    initialCategory || DEFAULT_CATEGORY,
  );
  useEffect(() => {
    updateCategory(initialCategory || DEFAULT_CATEGORY);
  }, [initialCategory]);
  if (loading) {
    return <Loading center />;
  }
  return (
    <Form
      onSubmit={() => save(category)}
      success={successKey != null}
      error={error != null}
    >
      {(error != null || successKey != null) && (
        <Segment className="grid">
          <div className="column">
            {error != null && <Message error content={backendT(t, error)} />}
            {successKey != null && (
              <Message
                success
                content={t(`lifestyle.category.edit.success.${successKey}`)}
              />
            )}
          </div>
        </Segment>
      )}
      <BasicCategoryInfo category={category} update={updateCategory} />
      {(error != null || successKey != null) && (
        <Segment className="grid">
          <div className="column">
            {error != null && <Message error content={backendT(t, error)} />}
            {successKey != null && (
              <Message
                success
                content={t(`lifestyle.category.edit.success.${successKey}`)}
              />
            )}
          </div>
        </Segment>
      )}
      <Segment className="grid">
        <div
          className={['column', remove && 'column-6'].filter(Boolean).join(' ')}
        >
          <Button fluid>{t('common:buttons.save')}</Button>
        </div>
        {remove != null && (
          <div className="column column-6">
            <Button
              type="button"
              color="red"
              fluid
              onClick={() =>
                // eslint-disable-next-line no-alert
                window.confirm(
                  t(
                    'lifestyle.category.edit.confirmRemove',
                    'Are you sure you want to delete this category?',
                  ),
                ) && remove()
              }
            >
              {t('common:buttons.remove')}
            </Button>
          </div>
        )}
      </Segment>
    </Form>
  );
};

export default withTranslation()(EditCategoryView);
