// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { wrapAPIDispatch } from 'src/store/middleware/api';
import * as actions from 'src/store/actions';
import { getUser } from 'src/store/utilities';

import NotFound from 'src/scenes/NotFound';
import UserView from './UserView';

type Props = {
  loadUser: ({ userId: string }) => Promise<any>,
  match: {
    params: {
      userId: string,
    },
  },
  user: {
    email: string,
    createdAt: string,
  },
};

type State = {
  error: boolean,
};

class UserContainer extends Component<Props, State> {
  state = {
    error: false,
  };

  componentDidMount() {
    const {
      match: {
        params: { userId },
      },
    } = this.props;
    const { loadUser } = this.props;
    loadUser({ userId }).catch(() => {
      this.setState({
        error: true,
      });
    });
  }

  render() {
    const { error } = this.state;
    if (error) {
      return <NotFound />;
    }
    return <UserView {...this.props} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { userId } = ownProps.match.params;
  return {
    user: getUser(state, userId).entity,
  };
};

const mapDispatchToProps = dispatch => ({
  loadUser: wrapAPIDispatch(dispatch, actions.loadUser),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(UserContainer);
