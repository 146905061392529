// @flow
import React from 'react';
import { Input } from 'semantic-ui-react';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

type Props = {
  type: string,
  searchValue: string,
  onSearchChange: (SyntheticEvent<Input>) => void,
  t: TFunc,
};

const UserListSearch = ({ type, searchValue, onSearchChange, t }: Props) => (
  <Input
    fluid
    placeholder={t(
      `organization.userList${type ? `.${type}` : ''}.searchPlaceholder`,
    )}
    icon="search"
    iconPosition="left"
    value={searchValue}
    onChange={onSearchChange}
  />
);

export default withTranslation()(UserListSearch);
