// @flow
import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

type Props = {
  whitelist: string[],
  sorted: string,
  direction: string,
  searchValue: string,
  clearFilters: () => void,
  onChangeSort: string => void,
  t: TFunc,
};

const WhitelistedEmailListTable = ({
  whitelist,
  sorted,
  direction,
  searchValue,
  clearFilters,
  onChangeSort,
  t,
}: Props) => (
  <Table selectable sortable singleLine basic="very">
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell
          sorted={sorted === 'email' ? direction : null}
          onClick={() => onChangeSort('email')}
        >
          {t('common:formfields.email')}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    {whitelist.length ? (
      <Table.Body>
        {whitelist.map(email => (
          <Table.Row key={email}>
            <Table.Cell>{email}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    ) : (
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell>
            <div className="grid grid-x1">
              <div className="column text-align-center">
                <p>{t('organization.userList.whitelistedEmails.noEmails')}</p>
              </div>
              {searchValue && (
                <div className="column display-flex justify-content-center">
                  <Button onClick={clearFilters} className="link">
                    {t('common:buttons.clearFilters')}
                  </Button>
                </div>
              )}
            </div>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    )}
  </Table>
);

export default withTranslation()(WhitelistedEmailListTable);
