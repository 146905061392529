import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from 'src/store/actions';
import { wrapAPIDispatch } from 'src/store/middleware/api';

import IncentiveView from './IncentiveView';
import { Dispatch } from 'redux';

type IncentiveContainerProps = {
  updateIncentive: (args: {
    organizationId: string;
    incentive: string;
  }) => void;
  organizationId: string;
  incentive: string;
};

type Event = {
  target: {
    name: string;
    value: string;
  };
};

const IncentiveContainer = ({
  incentive,
  organizationId,
  updateIncentive,
}: IncentiveContainerProps) => {
  const [incentiveValue, setIncentiveValue] = useState(incentive);

  const handleChange = useCallback((event: Event) => {
    const { value } = event.target;
    setIncentiveValue(value);
  }, []);

  const handleSubmit = useCallback(() => {
    updateIncentive({
      organizationId,
      incentive: incentiveValue,
    });
  }, [updateIncentive, incentiveValue, organizationId]);
  return (
    <IncentiveView
      incentive={incentiveValue}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateIncentive: wrapAPIDispatch(dispatch, actions.updateIncentive),
});

export default connect(null, mapDispatchToProps)(IncentiveContainer);
