// @flow
import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import { createBrowserHistory } from 'history';
import createRootReducer from './reducers';

import createAPIMiddleware from './middleware/api/middleware';

const { REACT_APP_API_SERVER } = process.env;

export const history = createBrowserHistory();

if (REACT_APP_API_SERVER == null)
  throw new Error(
    'REACT_APP_API_SERVER environment variable not specified. Aborting....',
  );

const store = createStore(
  createRootReducer(history),
  composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history),
      createAPIMiddleware({ APIServerUrl: REACT_APP_API_SERVER }),
    ),
  ),
);

function onRehydration() {
  // TODO: handle accessToken, trigger "onLogin" as necessary
}

export const persistor = persistStore(store, null, onRehydration);

export default store;
