// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { i18n } from '@pija-ab/i18n-react-system';
import { wrapAPIDispatch } from 'src/store/middleware/api';
import * as actions from 'src/store/actions';
import type { ModifyableVideoEntity } from 'src/utilities/flowtypes';

import { getVimeoVideo } from 'src/store/utilities';
import VideosView from './VideosView';

type ExposedProps = {
  videos: $PropertyType<ModifyableVideoEntity, 'videos'>,
  update: (
    | $PropertyType<ModifyableVideoEntity, 'videos'>
    | ((
        $PropertyType<ModifyableVideoEntity, 'videos'>,
      ) => $PropertyType<ModifyableVideoEntity, 'videos'>),
  ) => void,
};

type Props = {
  ...ExposedProps,
  loadAllVimeoVideos: () => Promise<void>,
};

export type Lang = {
  name: string,
  nativeName: string,
  code: string,
};

type State = {
  loading: boolean,
  error: ?Error,
  langs: $ReadOnlyArray<Lang>,
};

class VideosContainer extends Component<Props, State> {
  state = {
    loading: true,
    error: null,
    langs: [],
  };

  componentDidMount() {
    const { loadAllVimeoVideos } = this.props;
    loadAllVimeoVideos()
      .catch(err => this.setState({ error: err }))
      .finally(() => this.setState({ loading: false }));
    i18n.services.backendConnector.backend.getLanguages((err, data) => {
      if (err) {
        this.setState({
          error: err,
        });
        return;
      }

      const langs: Lang[] = Object.keys(data).map(key => ({
        code: key.split('-')[0],
        name: data[key].name,
        nativeName: data[key].nativeName,
      }));

      this.setState({
        langs,
      });
    });
  }

  render() {
    const { loading, error, langs } = this.state;
    return (
      <VideosView
        {...this.props}
        langs={langs}
        loading={loading}
        error={error}
      />
    );
  }
}

const mapStateToProps = state => {
  const vimeoVideos = state.vimeoVideos.all.map(
    vimeoVideoId => getVimeoVideo(state, vimeoVideoId).entity,
  );
  return {
    vimeoVideos,
  };
};

const mapDispatchToProps = dispatch => ({
  loadAllVimeoVideos: wrapAPIDispatch(dispatch, actions.loadAllVimeoVideos),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideosContainer);
