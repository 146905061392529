// @flow
import React from 'react';
import { Header, Segment, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

import CategoryList from './components/CategoryList';

type Props = {
  t: TFunc,
  match: { path: string },
};

const ListCategories = ({ t, match }: Props) => (
  <div className="max-width max-width-extra-narrow">
    <Header as="h1" className="page-title">
      {t('lifestyle.mindCategory.title', 'Manage mind categories')}
    </Header>
    <CategoryList />
    <Segment>
      <Link to={`${match.path}/new`}>
        <Button fluid type="button">
          {t('common:buttons.createNew', 'Create new')}
        </Button>
      </Link>
    </Segment>
  </div>
);

export default withTranslation()(ListCategories);
