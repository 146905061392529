// @flow
import React, { PureComponent } from 'react';
/* :: import * as R from 'react'; */

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import NoAuthComponentView from './NoAuthComponentView';

type Props = {
  userData: {
    accessToken: string,
  },
  children: R.Node,
};

class NoAuthComponentContainer extends PureComponent<Props> {
  render() {
    const {
      userData: { accessToken },
    } = this.props;
    if (accessToken) {
      return <Redirect to="/" />;
    }
    return <NoAuthComponentView {...this.props} />;
  }
}

const mapStateToProps = state => ({
  userData: state.userData,
});

export default connect(mapStateToProps)(NoAuthComponentContainer);
