// @flow
import React from 'react';
import type { ComponentType } from 'react';
import displayNameOf from './displayNameOf';

/**
 * Transforms a "provider" component into a HOC.  A component is a "provider"
 * if it wraps a single child, taking some props and providing some kind of
 * non-visual service to this part of the tree.  Think Redux <Provider>, or
 * React's Context <Provider>.
 */
export default <T: {}, U: {}>(
  ProviderComp: ComponentType<T>,
  providerProps: T,
) => (Comp: ComponentType<U>) => {
  if (ProviderComp == null) {
    throw new Error(
      `Can't create asHOC(${String(ProviderComp)})(${displayNameOf(
        Comp,
      )}) because ProviderComp is undefined/null`,
    );
  }
  const WrappedComp = (props: U) => (
    <ProviderComp {...providerProps}>
      <Comp {...props} />
    </ProviderComp>
  );
  WrappedComp.displayName = `asHOC(${displayNameOf(
    ProviderComp,
  )})(${displayNameOf(Comp)})`;
  return WrappedComp;
};
