// @flow
export const LOGOUT_USER = 'LOGOUT_USER';
export function logoutUser(): {
  type: typeof LOGOUT_USER,
  status: string,
} {
  return {
    type: LOGOUT_USER,
    status: 'success',
  };
}
