// @flow
import React from 'react';
import { Header, Button, Icon } from 'semantic-ui-react';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';
import type { EmbeddedBaseCategory } from 'src/utilities/flowtypes';

type Props = {
  category: EmbeddedBaseCategory,
  remove: string => void,
  t: TFunc,
  entityName: string,
};

const CategoryListItem = ({ category, remove, t, entityName }: Props) => {
  return (
    <div className="inner-segment">
      <div className="grid">
        <div className="column min-width-0 flex-grow-1 flex-basis-0">
          <div className="grid grid-x0">
            <div className="column">
              <Header as="h3" size="small" className="secondary">
                {t(
                  `${entityName}:categoryEntity.${category.id}.title`,
                  category.referenceTitle,
                )}
              </Header>
            </div>
            <div className="column">
              <p
                className="ellipsis"
                title={t(
                  `${entityName}:categoryEntity.${category.id}.description`,
                  category.referenceDescription,
                )}
              >
                {t(
                  `${entityName}:categoryEntity.${category.id}.description`,
                  category.referenceDescription,
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="column flex-basis-auto align-self-center display-flex">
          <Button
            icon
            type="button"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() =>
              // eslint-disable-next-line no-alert
              window.confirm(
                `Are you sure you wanna remove category "${category.referenceTitle}" from this lifestyle entity?`,
              ) && remove(category.id)
            }
          >
            <Icon name="delete" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()<Props>(CategoryListItem);
