// @flow
import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
} from 'src/store/middleware/api';

export type LoginUserParams = {
  email: string,
  password?: string,
  code?: string,
};

export const LOGIN_USER = 'LOGIN_USER';
export const loginUser = wrapAPIActionCreator<LoginUserParams>(
  ({ email, password, code }) => ({
    [API_DATA]: {
      type: LOGIN_USER,
      endpoint: '/api/user/login',
      method: 'POST',
      body: {
        email,
        password,
        code,
      },
      schema: {
        user: Schemas.user,
      },
    },
  }),
);
