// @flow
import { API_DATA, wrapAPIActionCreator } from 'src/store/middleware/api';

export type RequestLoginCodeParams = {
  email: string,
};

export const REQUEST_LOGIN_CODE = 'REQUEST_LOGIN_CODE';
export const requestLoginCode = wrapAPIActionCreator<RequestLoginCodeParams>(
  ({ email }) => ({
    [API_DATA]: {
      type: REQUEST_LOGIN_CODE,
      endpoint: '/api/user/generate-login-code',
      method: 'POST',
      body: {
        email,
      },
    },
  }),
);
