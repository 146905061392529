// @flow
import React from 'react';
import { Popup, Button, Icon } from 'semantic-ui-react';

import type { User } from 'src/utilities/flowtypes';

import UsersFilter from '../UsersFilter';

type Props = {
  userList: User[],
};

const UserListSearch = ({ userList }: Props) => (
  <Popup
    on="click"
    position="bottom right"
    trigger={
      <Button disabled icon labelPosition="left">
        <Icon name="filter" />
        Filter
      </Button>
    }
    content={<UsersFilter userList={userList} />}
  />
);

export default UserListSearch;
