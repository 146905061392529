// @flow
import React, { PureComponent } from 'react';
/* :: import * as R from 'react'; */

import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Loading } from 'src/components/elements';
import { getIsLoggedIn, getLoggedInUser } from 'src/store/utilities';
import { wrapAPIDispatch } from 'src/store/middleware/api';
import * as actions from 'src/store/actions';

import UserPage from 'src/scenes/UserPage';
import NotFound from 'src/scenes/NotFound';

type Props = {
  isLoggedIn: boolean,
  loggedInUser: {
    role: string,
  },
  isLoadingUser: boolean,
  loadCurrentUser: () => Promise<void>,
  children: R.Node,
  admin: boolean,
  customerAdmin: boolean,
};

class AuthenticatedComponentContainer extends PureComponent<Props> {
  componentDidMount() {
    const {
      isLoggedIn,
      loggedInUser,
      isLoadingUser,
      loadCurrentUser,
    } = this.props;
    if (!isLoggedIn || isLoadingUser) {
      return;
    }
    if (loggedInUser == null) {
      loadCurrentUser().catch(console.error);
    }
  }

  render() {
    const {
      isLoggedIn,
      loggedInUser,
      admin,
      customerAdmin,
      children,
    } = this.props;

    if (!isLoggedIn) {
      return <Redirect to="/login" />;
    }
    if (loggedInUser == null) {
      return <Loading center />;
    }
    if (loggedInUser.role === 'user') {
      return <UserPage />;
    }

    if (admin && customerAdmin) {
      if (!['administrator', 'customer_admin'].includes(loggedInUser.role)) {
        return <NotFound />;
      }
    } else if (admin && loggedInUser.role !== 'administrator') {
      return <NotFound />;
    } else if (customerAdmin && loggedInUser.role !== 'customer_admin') {
      return <NotFound />;
    }
    return children;
  }
}

const mapStateToProps = state => ({
  isLoadingUser: state.userData.isLoadingUser,
  isLoggedIn: getIsLoggedIn(state),
  loggedInUser: getLoggedInUser(state).entity,
});

const mapDispatchToProps = dispatch => ({
  loadCurrentUser: wrapAPIDispatch(dispatch, actions.loadCurrentUser),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthenticatedComponentContainer);
