// @flow
import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
} from 'src/store/middleware/api';

type LoadVimeoVideoParams = {
  vimeoVideoId: string,
};

export const LOAD_VIMEO_VIDEO = 'LOAD_VIMEO_VIDEO';
export const loadVimeoVideo = wrapAPIActionCreator<LoadVimeoVideoParams>(
  ({ vimeoVideoId }) => ({
    [API_DATA]: {
      type: LOAD_VIMEO_VIDEO,
      endpoint: `/api/vimeo/${vimeoVideoId}`,
      method: 'GET',
      authentication: true,
      schema: {
        vimeoVideo: Schemas.vimeoVideo,
      },
    },
  }),
);
