import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TFunc, withTranslation } from '@pija-ab/i18n-react-system';
import * as actions from 'src/store/actions';
import { wrapAPIDispatch } from 'src/store/middleware/api';
import { getOrganization, getUser } from 'src/store/utilities';

import { Loading } from 'src/components/elements';

import EditUserForm from 'src/components/EditUserForm';

type Props = {
  t: TFunc;
  userId: string;
};

const initialState = {
  email: '',
  organizationId: '',
  role: '',
  message: '',
  errorMessage: '',
};

const CreateUserView = ({ userId, t }: Props): JSX.Element => {
  const [initialDataSet, setInitialDataSet] = useState(false);
  const dispatch = useDispatch();
  const [state, setState] = useState<null | {
    email: string;
    organizationId: string;
    role: string;
    message: string;
    errorMessage: string;
  }>(null);

  const { user, organization } = useSelector(selectorState => {
    const userData = getUser(selectorState, userId).entity;

    if (!userData) {
      return {};
    }
    const org = getOrganization(selectorState, userData.organizationId)?.entity;

    if (org) {
      return {
        user: userData,
        organization: org,
      };
    }
    return { user: userData };
  });

  useEffect(() => {
    const loadUser = wrapAPIDispatch(dispatch, actions.loadUser);
    loadUser({ userId });
  }, []);

  useEffect(() => {
    if (!initialDataSet && user) {
      const userRole = user.role;
      const userHasOrganizationId = user.organizationId;
      const orgHasSubdomain = organization && organization.subdomain;

      if (userRole === 'administrator' || orgHasSubdomain) {
        setState({
          email: user.email,
          organizationId: organization?.id || '',
          role: userRole,
          message: '',
          errorMessage: '',
        });
        setInitialDataSet(true);
      }

      if (userRole !== 'administrator' && userHasOrganizationId) {
        const loadOrg = wrapAPIDispatch(dispatch, actions.loadOrganization);
        loadOrg({ organizationId: user.organizationId }).catch(() => {
          setState({
            email: user.email,
            organizationId: organization?.id || '',
            role: userRole,
            message: '',
            errorMessage: '',
          });
        });
      }
    }
  }, [user, organization]);

  const updateUser = async () => {
    const { email, organizationId, role } = state || {};
    if (!email) {
      setState({
        ...(state || initialState),
        message: '',
        errorMessage: t('createUser.noEmail'),
      });
      return;
    }
    const updateUserArguments: {
      id: string;
      email: string;
      role: string;
      organizationId?: string;
    } = {
      id: userId,
      email,
      role: role || '',
    };

    if (organizationId && role !== 'administrator') {
      updateUserArguments.organizationId = organizationId;
    }
    try {
      const update = wrapAPIDispatch(dispatch, actions.updateUser);
      await update(updateUserArguments);
      setState({
        ...(state || initialState),
        message: t('createUser.updated'),
        errorMessage: '',
      });
    } catch (error) {
      let message = 'Unknown Error';
      if (error instanceof Error) {
        message = error.message;
      }
      setState({
        ...(state || initialState),
        message: '',
        errorMessage: message,
      });
    }
  };

  if (!state) {
    return <Loading />;
  }
  return (
    <EditUserForm
      state={state}
      setState={setState}
      type="edit"
      onSubmit={updateUser}
      user={user}
    />
  );
};

export default withTranslation()(CreateUserView);
