// @flow
import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

const CreateUserView = ({ t }: { t: TFunc }) => (
  <div className="max-width max-width-extra-narrow">
    <Header as="h1" className="page-title">
      {t('lifestyle.start.title', 'Lifestyle content')}
    </Header>
    <Segment>
      <p>
        Use the navigation above the header to manage mobilities and the
        mobility categories.
      </p>
    </Segment>
  </div>
);

export default withTranslation()(CreateUserView);
