// @flow
import React from 'react';
import { Header } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

type Props = {
  user: {
    email: string,
    organizationId: string,
    role: string,
  },
  t: TFunc,
};

const InitialPageView = ({ user, t }: Props) => {
  if (user.role === 'hr' || user.role === 'divisionManager') {
    return <Redirect to={`/organization/${user.organizationId}`} />;
  }
  if (user.role === 'administrator' || user.role === 'customer_admin') {
    return <Redirect to="/organizations" />;
  }
  return (
    <Header as="h1" className="page-title text-align-center">
      {t('welcome')}
    </Header>
  );
};

export default withTranslation()(InitialPageView);
