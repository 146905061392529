import { Organization } from 'src/types/common';
import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
} from 'src/store/middleware/api';

type UpdateOrganizationTypes = {
  organizationId: string;
  fields: Partial<Pick<Organization, 'incentive' | 'assessmentIntervalDays'>>;
};

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const updateOrganization = wrapAPIActionCreator(
  ({ organizationId, fields }: UpdateOrganizationTypes) => ({
    [API_DATA]: {
      type: UPDATE_ORGANIZATION,
      endpoint: `/api/organization/${organizationId}`,
      method: 'POST',
      authentication: true,
      body: {
        ...fields,
      },
      schema: {
        organization: Schemas.organization,
      },
    },
  }),
);
