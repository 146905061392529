// @flow
import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
  type SuccessCallback,
  type ErrorCallback,
} from 'src/store/middleware/api';

import pluralize from 'pluralize';

export const LOAD_ALL_LIFESTYLE_ENTITIES = 'LOAD_ALL_LIFESTYLE_ENTITIES';
const apiFunc = wrapAPIActionCreator<{ entityName: string }>(
  ({ entityName }) => ({
    [API_DATA]: {
      type: LOAD_ALL_LIFESTYLE_ENTITIES,
      endpoint: `/api/${encodeURIComponent(entityName)}`,
      query: { includeUnpublished: 'true' },
      method: 'GET',
      authentication: true,
      schema: {
        [pluralize.plural(entityName)]: [Schemas[entityName]],
      },
    },
  }),
);

export function loadAllLifestyleEntities(
  entityName: string,
): (params: void, onSuccess?: SuccessCallback, onError?: ErrorCallback) => any {
  return (params, onSuccess, onError) =>
    apiFunc({ entityName }, onSuccess, onError);
}
