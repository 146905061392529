import React from 'react';

export default function Table({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}): JSX.Element {
  return (
    <div className="overflow-x-auto p-4 bg-white shadow-lg rounded-[4px]">
      <table className="table table-auto items-left bg-transparent w-full border-collapse">
        {children}
      </table>
    </div>
  );
}
