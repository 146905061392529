// @flow
import React from 'react';
import { compose } from 'redux';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { Toaster } from 'src/components/ui/toaster';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import asHOC from 'src/utilities/asHOC';
import store, { history, persistor } from 'src/store/index.admin';

import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_SERVER;

axios.interceptors.request.use(
  config => {
    const accessToken = store.getState().userData?.accessToken;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  error => Promise.reject(error),
);

import Page from 'src/components/Page';

const queryClient = new QueryClient();

const App = () => (
  <>
    <Page />
    <Toaster />
  </>
);

export default compose(
  asHOC(Provider, { store }),
  asHOC(PersistGate, { loading: null, persistor }),
  asHOC(ConnectedRouter, { history }),
  asHOC(QueryClientProvider, { client: queryClient }),
)(App);
