// @flow
import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { UserIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

const LangDropdown = ({
  onChange,
  curLngIndex,
  langs,
}: {
  onChange: (code: string) => void,
  curLngIndex: number,
  langs: Lang[],
}): R.Node => {
  return (
    <Menu as="div" className="relative">
      <Menu.Button className="-m-1.5 flex items-center p-1.5 bg-transparent border-none cursor-pointer">
        <span className="sr-only">Open user menu</span>
        <span className="flex items-center">
          <span
            className="ml-4 font-semibold leading-6 text-paradiso-500"
            aria-hidden="true"
          >
            {curLngIndex >= 0 &&
              langs &&
              `${langs[curLngIndex].name} (${langs[curLngIndex].nativeName})`}
          </span>
          <ChevronDownIcon
            className="ml-2 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2.5 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none min-w-[165px]">
          {langs &&
            langs.map((item, index) => {
              return (
                <Menu.Item key={item.code}>
                  {({ active }) => (
                    <button
                      type="button"
                      onClick={() => onChange(item.code)}
                      className={classNames(
                        index === curLngIndex && 'font-semibold',
                        'inline-flex w-full bg-transparent hover:bg-gray-50 px-3 py-2 outline-none cursor-pointer border-none hover:text-primary-500 text-start',
                      )}
                    >
                      {`${item.name} (${item.nativeName})`}
                    </button>
                  )}
                </Menu.Item>
              );
            })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default withTranslation()(LangDropdown);
