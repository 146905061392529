// @flow
import React from 'react';
import { Segment } from 'semantic-ui-react';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

import 'react-vis/dist/style.css';

import StatisticItem from './components/StatisticItem';
import UserTypeChart from './components/UserTypeChart';
import ActiveUserChart from './components/ActiveUserChart';
import ParticipationRateChart from './components/ParticipationRateChart';

type Props = {
  numEmployeesRegistered: number,
  byMonth: {
    label: string,
    numCreatedUsers: number,
    numActiveUsers: number,
    numEmployees?: number,
  }[],
  hrUsers: mixed[],
  emailWhitelist: mixed[],
  employeeCounts: {
    _id: string,
    count: number,
    date: date,
  }[],
  t: TFunc,
};

const OrganizationStatistics = ({
  numEmployeesRegistered,
  byMonth,
  hrUsers,
  emailWhitelist,
  employeeCounts,
  t,
}: Props) => {
  const statistics = [
    {
      id: 'users-reg',
      value: numEmployeesRegistered,
      text: t('organization.stats.registeredUsers'),
    },
    {
      id: 'users-active',
      value:
        byMonth && byMonth.slice(-1)[0] && byMonth.slice(-1)[0].numActiveUsers,
      text: t('organization.stats.activeUsers'),
    },
    /*
    {
      id: 'hr-users',
      value: hrUsers.length,
      text: 'HR Users',
    },
    */
    {
      id: 'whitelisted-emails',
      value: emailWhitelist.length,
      text: t('organization.stats.whitelistedEmails'),
    },
  ];
  return (
    <Segment className="organization-statistics">
      <div className="grid">
        {statistics.map(statistic => (
          <div
            key={statistic.id}
            className="column column-6 column-3-medium display-flex"
          >
            <StatisticItem {...statistic} />
          </div>
        ))}
        <div className="column column-6 column-3-medium display-flex">
          <UserTypeChart
            numUsers={numEmployeesRegistered}
            numHrUsers={hrUsers.length}
            numWhitelisted={emailWhitelist.length}
          />
        </div>
        <div className="column column-12">
          <ActiveUserChart byMonth={byMonth} />
        </div>
        <div className="column column-12">
          <ParticipationRateChart
            numEmployees={numEmployeesRegistered}
            byMonth={byMonth}
            employeeCounts={employeeCounts}
          />
        </div>
      </div>
    </Segment>
  );
};

export default withTranslation()(OrganizationStatistics);
