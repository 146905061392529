// @flow

type Params = {
  code: number,
  message: string,
  trans: {
    key?: string,
    params?: { [key: string]: string },
  },
};

class APIError extends Error {
  code = 0;

  params: ?{ [key: string]: string };

  key: ?string;

  trans: $PropertyType<Params, 'trans'>;

  constructor(params: Params) {
    const { code, message, trans } = params;
    super(message);
    this.code = code;
    Object.defineProperty(this, 'trans', {
      value: trans,
    });
  }
}

APIError.prototype.name = APIError.name;

export default APIError;
