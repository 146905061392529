// @flow
import { combineReducers } from 'redux';
import * as ActionTypes from 'src/store/actions';

import type { BaseCategory } from 'src/utilities/flowtypes';

function all(
  state: { [string]: string[] } = {},
  action: any,
): { [string]: string[] } {
  switch (action.type) {
    case ActionTypes.LOAD_LIFESTYLE_CATEGORY:
    case ActionTypes.PUT_LIFESTYLE_CATEGORY:
    case ActionTypes.CREATE_LIFESTYLE_CATEGORY:
      return state;
    case ActionTypes.LOAD_ALL_LIFESTYLE_CATEGORIES:
      if (action.status === 'success') {
        const [key] = Object.keys(action.payload.result);
        return {
          ...state,
          [key]: action.payload.result[key],
        };
      }
      return state;
    case ActionTypes.LOGOUT_USER:
      if (action.status === 'success') {
        return {};
      }
      return state;
    default:
      return state;
  }
}

function byId(state: BaseCategory = {}, action: any): BaseCategory {
  switch (action.type) {
    case ActionTypes.LOAD_LIFESTYLE_CATEGORY:
    case ActionTypes.PUT_LIFESTYLE_CATEGORY:
    case ActionTypes.CREATE_LIFESTYLE_CATEGORY:
      if (action.status === 'success') {
        const entityName: string | void = Object.keys(
          action.payload.result,
        ).find(
          k =>
            typeof action.payload.result[k] === 'string' &&
            k.match(/Category$/),
        );
        if (!entityName) return state;
        return {
          ...state,
          [entityName]: {
            ...state[entityName],
            ...action.payload.entities[entityName],
          },
        };
      }
      return state;

    case ActionTypes.LOGOUT_USER:
      return {};

    default:
      return state;
  }
}

const lifestyleCategories = combineReducers({
  all,
  byId,
});

export default lifestyleCategories;
