import React from 'react';
import classNames from 'classnames';

export default function TableHeadCell({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}): JSX.Element {
  return (
    <th
      className={classNames(
        'p-3 text-gray-900 font-normal font-sans align-middle border border-solid border-slate-200 text-[14px] border-l-0 border-t-0 border-r-1 last:border-r-0 whitespace-nowrap text-left',
        className,
      )}
    >
      {children}
    </th>
  );
}
