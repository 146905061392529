// @flow
import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
} from 'src/store/middleware/api';

type GetUserTypes = {
  userId: string,
};

export const LOAD_USER = 'LOAD_USER';
export const loadUser = wrapAPIActionCreator<GetUserTypes>(({ userId }) => ({
  [API_DATA]: {
    type: LOAD_USER,
    endpoint: `/api/user/${userId}`,
    method: 'GET',
    authentication: true,
    schema: { user: Schemas.user },
  },
}));
