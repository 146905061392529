import classNames from 'classnames';
import React from 'react';

export default function TableRow({
  children,
  onClick,
  hover = true,
  className = '',
}: {
  children: React.ReactNode | React.ReactNode[];
  onClick?: () => void;
  hover?: boolean;
  className?: string;
}): JSX.Element {
  return (
    <tr
      onClick={onClick}
      className={classNames(
        hover ? 'hover:bg-gray-200/30 hover:text-gray-900' : '',
        className,
      )}
    >
      {children}
    </tr>
  );
}
