import { HHI } from 'src/types/common';

export const HHIRawToDisplayed = (raw: HHI): HHI => {
  return {
    ...raw,
    index: Math.round(raw.index * 100),
    foodPreferences: Math.round(raw.foodPreferences * 100),
    eatingBehavior: Math.round(raw.eatingBehavior * 100),
    energyLevel: Math.round(raw.energyLevel * 100),
    sleepQuality: Math.round(raw.sleepQuality * 100),
    physicalActivity: Math.round(raw.physicalActivity * 100),
  };
};
