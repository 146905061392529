import classNames from 'classnames';
import React from 'react';

export default function TableCell({
  colSpan,
  className,
  children,
}: {
  colSpan?: number;
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}): JSX.Element {
  return (
    <td
      colSpan={colSpan}
      className={classNames(
        'p-3 align-middle text-xs whitespace-nowrap text-left border-solid border-slate-200 border-t-0 border-l-0 border-r-0 border-b',
        className,
      )}
    >
      {children}
    </td>
  );
}
