// @flow
import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
} from 'src/store/middleware/api';

type UnarchiveOrganizationTypes = {
  organizationId: string,
};

export const UNARCHIVE_ORGANIZAION = 'UNARCHIVE_ORGANIZAION';
export const unarchiveOrganization = wrapAPIActionCreator<UnarchiveOrganizationTypes>(
  ({ organizationId }) => ({
    [API_DATA]: {
      type: UNARCHIVE_ORGANIZAION,
      endpoint: `/api/organization/${organizationId}/unarchive`,
      method: 'POST',
      authentication: true,
      schema: {
        organization: Schemas.organization,
      },
    },
  }),
);
