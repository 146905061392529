// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import type { BackendMessage } from '@pija-ab/i18n-react-system';

import * as actions from 'src/store/actions';
import { wrapAPIDispatch } from 'src/store/middleware/api';

import RecoverPasswordView from './RecoverPasswordView';

type Props = {
  recoverPassword: ({ email: string }) => Promise<{
    result: BackendMessage,
  }>,
};

type State = {
  email: {
    value: string,
  },
  message: string,
  errorMessage: string,
  completed: boolean,
  loading: boolean,
};

type Event = {
  target: {
    name: string,
    value: string,
  },
};

const initialState = {
  email: {
    value: '',
  },
  message: '',
  errorMessage: '',
  completed: false,
  loading: false,
};

class RecoverPasswordContainer extends Component<Props, State> {
  state = initialState;

  handleChange = (event: Event) => {
    const { name, value } = event.target;
    this.setState(oldState => ({ [name]: { ...oldState[name], value } }));
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    const { email } = this.state;
    const { recoverPassword } = this.props;
    recoverPassword({
      email: email.value,
    })
      .then(({ result }) => {
        this.setState({
          ...initialState,
          message: result.message,
          errorMessage: '',
          loading: false,
        });
      })
      .catch(error => {
        this.setState({
          message: '',
          errorMessage: error.message,
          loading: false,
        });
      });
  };

  render() {
    return (
      <RecoverPasswordView
        {...this.state}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  recoverPassword: wrapAPIDispatch(dispatch, actions.forgotPassword),
});

export default connect(null, mapDispatchToProps)(RecoverPasswordContainer);
