import React from 'react';

import { Header, Segment, Input, Icon, Button } from 'semantic-ui-react';
import { withTranslation, TFunc } from '@pija-ab/i18n-react-system';

type IncentiveViewProps = {
  incentive: string;
  handleChange: () => void;
  handleSubmit: () => void;
  t: TFunc;
};

function IncentiveView({
  incentive,
  handleChange,
  handleSubmit,
  t,
}: IncentiveViewProps): JSX.Element {
  return (
    <React.Fragment>
      <Header as="h2">{t('organization.incentive.title')}</Header>
      <Segment>
        <p>{t('organization.incentive.description')}</p>
        <Input
          fluid
          action
          id="incentive"
          type="text"
          name="incentive"
          placeholder="Incentive"
          iconPosition="left"
          value={incentive}
          onChange={handleChange}
        >
          <Icon name="star" />
          <input />
          <Button onClick={handleSubmit}>{t('common:buttons.update')}</Button>
        </Input>
      </Segment>
    </React.Fragment>
  );
}

export default withTranslation()(IncentiveView);
