import React, { Fragment, useCallback, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

export default function Dropdown({
  title,
  titleStyle,
  options,
  onSelect,
  placeholderTitle,
}: {
  title?: string;
  titleStyle?: string;
  options: {
    id: string | null;
    name: string;
    disabled?: boolean;
  }[];
  onSelect: (option: { id: string | null }) => void;
  placeholderTitle?: string;
}): JSX.Element {
  const [selected, setSelected] = useState<{
    id: string | null;
    name: string;
  } | null>(null);

  const handleOnChange = useCallback(
    (value: { id: string | null; name: string }) => {
      if (value.id === null) {
        setSelected(null);
        onSelect({ id: null });
      } else {
        setSelected(value);
        onSelect(value);
      }
    },
    [],
  );

  return (
    <Listbox value={selected} onChange={handleOnChange}>
      {({ open }) => (
        <>
          {title && (
            <Listbox.Label
              className={classNames(
                'text-xl font-extrabold text-paradiso-700 mb-2',
                titleStyle,
              )}
            >
              {title}
            </Listbox.Label>
          )}
          <div className="relative">
            <Listbox.Button className="relative w-full cursor-default border-none rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate text-base font-barlow">
                {selected?.name || placeholderTitle}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm list-none pl-0">
                {options.map(option => (
                  <Listbox.Option
                    key={option.id}
                    disabled={option.disabled}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        option.disabled
                          ? 'cursor-not-allowed'
                          : 'cursor-pointer',
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            option.disabled ? 'text-gray-400' : '',
                            'block truncate text-base font-barlow',
                          )}
                        >
                          {option.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
