// @flow
import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
} from 'src/store/middleware/api';

type UpdateEmployeeCountTypes = {
  organizationId: string,
  employeeCount: { count: number, date: Date },
};

export const UPDATE_EMPLOYEE_COUNT = 'UPDATE_EMPLOYEE_COUNT';
export const updateEmployeeCount = wrapAPIActionCreator<UpdateEmployeeCountTypes>(
  ({ organizationId, employeeCount }) => ({
    [API_DATA]: {
      type: UPDATE_EMPLOYEE_COUNT,
      endpoint: `/api/organization/${organizationId}/employeeCounts`,
      method: 'POST',
      authentication: true,
      body: {
        employeeCount,
      },
      schema: {
        organization: Schemas.organization,
      },
    },
  }),
);
