import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface HeaderProps {
  children: ReactNode;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: string;
}

const Header: React.FC<HeaderProps> = ({
  children,
  as,
  className,
}: {
  children: ReactNode;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: string;
}) => {
  return as ? (
    React.createElement(
      as,
      { className: classNames(`text-3xl font-extrabold`, className) },
      children,
    )
  ) : (
    <h1 className={classNames('text-3xl font-extrabold', className)}>
      {children}
    </h1>
  );
};

export default Header;
