// @flow
import React from 'react';
import { Input, Segment, Checkbox } from 'semantic-ui-react';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

import type { ModifyableBaseCategory } from 'src/utilities/flowtypes';
import { parseInt } from 'lodash';

type Props = {|
  category: {
    +referenceTitle: string,
    +referenceDescription: string,
    +image: ?string,
    +weight: ?number,
    +published: boolean,
  },
  update: (
    ModifyableBaseCategory | (ModifyableBaseCategory => ModifyableBaseCategory),
  ) => void,
  t: TFunc,
|};

const BasicInfo = ({ category, update, t }: Props) => {
  const setReferenceTitle = referenceTitle =>
    update(c => ({ ...c, referenceTitle }));
  const setReferenceDescription = referenceDescription =>
    update(c => ({ ...c, referenceDescription }));
  const setImage = image => update(c => ({ ...c, image }));
  const setWeight = weight => update(c => ({ ...c, weight: parseInt(weight) }));
  const setPublished = published => update(c => ({ ...c, published }));
  return (
    <Segment className="grid">
      <div className="column">
        <h3>{t('lifestyle.category.edit.basics', 'Basic info')}</h3>
        <hr />
      </div>
      <div className="column">
        <label className="form-label" htmlFor="referenceTitle">
          {t('lifestyle.category.edit.referenceTitle', 'Reference title')}
        </label>
        <Input
          fluid
          type="text"
          id="referenceTitle"
          name="referenceTitle"
          value={(category && category.referenceTitle) || ''}
          onChange={(ev, { value }) => setReferenceTitle(value)}
        />
      </div>
      <div className="column">
        <label className="form-label" htmlFor="referenceDescription">
          {t(
            'lifestyle.category.edit.referenceDescription',
            'Reference description',
          )}
        </label>
        <Input
          fluid
          type="text"
          id="referenceDescription"
          name="referenceDescription"
          value={(category && category.referenceDescription) || ''}
          onChange={(ev, { value }) => setReferenceDescription(value)}
        />
      </div>
      <div className="column">
        <label className="form-label" htmlFor="image">
          {t('lifestyle.category.edit.image', 'Image URL (Amazon S3)')}
        </label>
        <Input
          fluid
          type="text"
          id="image"
          name="image"
          value={(category && category.image) || ''}
          onChange={(ev, { value }) => setImage(value)}
        />
      </div>
      <div className="column">
        <label className="form-label" htmlFor="weight">
          {t('lifestyle.category.edit.weight', 'Weight')}
        </label>
        <Input
          fluid
          type="number"
          id="weight"
          name="weight"
          value={(category && category.weight) || ''}
          onChange={(ev, { value }) => setWeight(value)}
        />
      </div>
      <div className="column">
        <label className="form-label" htmlFor="published">
          {t('lifestyle.category.edit.published', 'Published')}
        </label>
        <div>
          <Checkbox
            id="published"
            name="published"
            checked={
              category && category.published != null ? category.published : true
            }
            onClick={ev => setPublished(ev.target.checked)}
          />
        </div>
      </div>
    </Segment>
  );
};

export default withTranslation()<Props>(BasicInfo);
