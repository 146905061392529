// @flow
import React from 'react';
import { Segment, Dropdown } from 'semantic-ui-react';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

import type {
  EmbeddedBaseCategory,
  ModifyableBaseEntity,
} from 'src/utilities/flowtypes';
import CategoryListItem from './components/CategoryListItem';

type Props = {
  categories: $PropertyType<ModifyableBaseEntity, 'categories'>,
  availableCategories: $ReadOnlyArray<EmbeddedBaseCategory>,
  update: (
    | $PropertyType<ModifyableBaseEntity, 'categories'>
    | ((
        $PropertyType<ModifyableBaseEntity, 'categories'>,
      ) => $PropertyType<ModifyableBaseEntity, 'categories'>),
  ) => void,
  t: TFunc,
  entityName: string,
};

const CategoriesView = ({
  categories,
  availableCategories,
  update,
  t,
  entityName,
}: Props) => {
  const remove = toRemove => {
    update(oldCategories => oldCategories.filter(({ id }) => id !== toRemove));
  };
  const handleChange = (ev, { value }) => {
    update(oldCategories => {
      if (oldCategories.some(({ id }) => id === value)) return oldCategories;
      return [
        ...oldCategories,
        availableCategories.find(({ id }) => id === value),
      ].filter(Boolean);
    });
  };
  return (
    <Segment className="grid">
      <div className="column">
        <h3>{t('lifestyle.baseEntity.edit.categories', 'Categories')}</h3>
        <hr />
      </div>
      <div className="column">
        {categories.map(cat => {
          return (
            <CategoryListItem
              key={cat.id}
              category={cat}
              remove={remove}
              entityName={entityName}
            />
          );
        })}
        <Dropdown
          placeholder={t(
            'lifestyle.baseEntity.edit.addCategory',
            'Add category',
          )}
          fluid
          search
          selection
          options={[
            {
              key: 'abort',
              value: null,
              text: t('lifestyle.baseEntity.edit.addCategory', 'Add category'),
            },
            ...availableCategories
              .filter(({ id }) => !categories.some(({ id: cid }) => cid === id))
              .map(c => ({
                key: c.id,
                value: c.id,
                text: t(
                  `${entityName}:categoryEntity.${c.id}.title`,
                  c.referenceTitle,
                ),
              })),
          ]}
          value={null}
          onChange={handleChange}
          selectOnNavigation={false}
        />
      </div>
    </Segment>
  );
};

export default withTranslation()(CategoriesView);
