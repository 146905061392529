import React from 'react';
import { Tab } from '@headlessui/react';
import { Header } from 'src/components/elements';
import classNames from 'classnames';

export type Tab = {
  tab: string;
  content: JSX.Element;
};

export const Tabs = ({
  tabs,
}: {
  tabs: Tab[];
  // tabClass?: string;
}): JSX.Element => {
  return (
    <Tab.Group>
      <Tab.List className="mb-2">
        {tabs.map(({ tab }) => (
          <Tab
            key={tab}
            className={({ selected }) =>
              classNames(
                'relative border-none bg-transparent text-paradiso-700 cursor-pointer',
                selected &&
                  'after:content-[""] after:h-0.5 after:w-full after:bg-primary-500 after:absolute after:-b-0.5 after:left-0',
              )
            }
          >
            <Header as="h2" className="text-[24px]">
              {tab}
            </Header>
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels>
        {tabs.map(({ tab, content }) => (
          <Tab.Panel key={`tabs-${tab}-content`}>{content}</Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default Tabs;
