import React, { useEffect } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { useSearchContext } from 'src/utilities/searchContext';
import { useLocation } from 'react-router';
import { useForm } from 'react-hook-form';

const activePaths = ['/users', '/organizations'];

type SearchForm = {
  searchField: string;
};

const PageHeaderSearch = () => {
  const { pathname } = useLocation();
  if (!activePaths.includes(pathname)) {
    return null;
  }

  const { search, dispatch } = useSearchContext();
  const { register, watch, reset, setFocus } = useForm<SearchForm>();

  useEffect(() => {
    const subscription = watch(({ searchField }) =>
      dispatch?.({
        type: 'UPDATE_SEARCH',
        path: pathname,
        term: searchField ?? '',
      }),
    );
    return () => subscription.unsubscribe();
  }, [watch, pathname]);

  useEffect(() => {
    reset({ searchField: search?.[pathname] ?? '' });
  }, [pathname]);

  useEffect(() => {
    // Keyboard handler to target search field
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check for 'Cmd + K' (Mac) or 'Ctrl + K' (Windows/Linux)
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault(); // Prevent default behavior (like browser's search)
        setFocus('searchField');
      }
    };

    // Add the keydown event listener
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <form
      className="relative flex h-12 items-center justify-center"
      onSubmit={event => event.preventDefault()}
    >
      <label htmlFor="searchField" className="sr-only">
        Search
      </label>
      <MagnifyingGlassIcon
        className="self-center text-lg pointer-events-none absolute inset-y-0 left-0 h-full w-6 text-secondary-500 font-semibold"
        aria-hidden="true"
      />
      <input
        id="searchField"
        className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 text-lg font-medium"
        placeholder="Search..."
        type="search"
        {...register('searchField')}
      />
    </form>
  );
};

export default PageHeaderSearch;
