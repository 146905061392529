// @flow
import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
} from 'src/store/middleware/api';

type LoadOrganizationTypes = {
  organizationId: string,
};

export const LOAD_ORGANIZATION = 'LOAD_ORGANIZATION';
export const loadOrganization = wrapAPIActionCreator<LoadOrganizationTypes>(
  ({ organizationId }) => ({
    [API_DATA]: {
      type: LOAD_ORGANIZATION,
      endpoint: `/api/organization/${organizationId}`,
      method: 'GET',
      authentication: true,
      schema: {
        organization: Schemas.organization,
        users: [Schemas.user],
        hrUsers: [Schemas.user],
        adminUsers: [Schemas.user],
      },
    },
  }),
);
