// @flow
import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
  type SuccessCallback,
  type ErrorCallback,
} from 'src/store/middleware/api';

export type LoadLifestyleEntityParams = {
  +id: string,
};

export const LOAD_LIFESTYLE_ENTITY = 'LOAD_LIFESTYLE_ENTITY';
const apiFunc = wrapAPIActionCreator<
  LoadLifestyleEntityParams & { entityName: string },
>(({ id, entityName }) => ({
  [API_DATA]: {
    type: LOAD_LIFESTYLE_ENTITY,
    endpoint: `/api/${encodeURIComponent(entityName)}/${id}`,
    query: { includeUnpublished: 'true' },
    method: 'GET',
    authentication: true,
    schema: {
      [entityName]: Schemas[entityName],
    },
  },
}));

export function loadLifestyleEntity(
  entityName: string,
): (
  params: LoadLifestyleEntityParams,
  onSuccess?: SuccessCallback,
  onError?: ErrorCallback,
) => any {
  return (params, onSuccess, onError) =>
    apiFunc({ ...params, entityName }, onSuccess, onError);
}
