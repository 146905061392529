// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getUser } from 'src/store/utilities';

import InitialPageView from './InitialPageView';

type Props = {
  user: {
    email: string,
    organizationId: string,
    role: string,
  },
};

class InitialPageContainer extends PureComponent<Props> {
  render() {
    return <InitialPageView {...this.props} />;
  }
}

const mapStateToProps = state => ({
  user: getUser(state, state.userData.user).entity,
});

export default connect(mapStateToProps)(InitialPageContainer);
