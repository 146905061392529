// @flow
import { compose } from 'redux';
import API_DATA from './APIData';
import type { SuccessCallback, ErrorCallback } from './callbackTypes';

function noop() {}

/**
 * Utility which wraps an API action creator (taking an object with
 * parameters, returning an action with [API_DATA] defined) such that it
 * receives optional onSuccess/onError callbacks as second and third parameter.
 */
export function wrapAPIActionCreator<T>(
  actionCreator: T => any,
): (params: T, onSuccess?: SuccessCallback, onError?: ErrorCallback) => any {
  return (
    params: T,
    onSuccess: SuccessCallback = noop,
    onError: ErrorCallback = noop,
  ) => {
    const action = actionCreator(params);

    return {
      ...action,
      [API_DATA]: {
        ...action[API_DATA],
        callbacks: { onSuccess, onError },
      },
      actionParams: params,
    };
  };
}

/**
 * Utility which wraps an API action with onSuccess/onError callbacks,
 * composing it with `dispatch` and returning a bound action creator which
 * returns a promise instead.
 */
export function wrapAPIDispatch<T>(
  dispatch: any => void,
  actionCreator: T => any,
): any => Promise<any> {
  return (params: any) =>
    new Promise<any>(compose(dispatch, actionCreator).bind(null, params));
}

/** Rename properties in `obj` according to `mapping` (mapping new keys to old). */
export function renameProperties(
  obj: { [string]: mixed },
  mapping: { [string]: mixed },
): any {
  const res = Object.assign({}, obj);
  Object.keys(mapping).forEach(to => {
    const from = mapping[to];
    res[to] = res[from];
    delete res[from];
  });
  return res;
}
