// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { wrapAPIDispatch } from 'src/store/middleware/api';
import * as actions from 'src/store/actions';
import type { BaseCategory } from 'src/utilities/flowtypes';

import EditCategoryView from 'src/components/Lifestyle/EditCategoryView';

type Props = {
  match: {
    params: {
      id: string,
    },
  },
  history: {
    push(string): void,
  },
  loadLifestyleCategory: ({ id: string }) => Promise<void>,
  createLifestyleCategory: actions.CreateLifestyleCategoryParams => Promise<{
    result: { sleepCategory: string },
  }>,
  putLifestyleCategory: actions.PutLifestyleCategoryParams => Promise<void>,
  deleteLifestyleCategory: actions.DeleteLifestyleCategoryParams => Promise<void>,
  category: BaseCategory,
};

type State = {
  loading: boolean,
  error: ?Error,
  successKey: ?string,
};

class EditCategoryContainer extends Component<Props, State> {
  state = {
    loading: true,
    error: null,
    successKey: null,
  };

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      loadLifestyleCategory,
    } = this.props;
    if (id !== 'new') {
      loadLifestyleCategory({ id })
        .catch(err => this.setState({ error: err, successKey: null }))
        .finally(() => this.setState({ loading: false }));
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      category,
      createLifestyleCategory,
      putLifestyleCategory,
      deleteLifestyleCategory,
      history,
    } = this.props;
    const { loading, error, successKey } = this.state;
    const save = ({
      id,
      ...data
    }: $ReadOnly<{
      ...actions.CreateLifestyleCategoryParams,
      +id?: string,
    }>) => {
      if (id) {
        putLifestyleCategory({ ...data, id })
          .then(() => {
            this.setState({ error: null, successKey: 'saved' });
          })
          .catch(err => {
            this.setState({ error: err, successKey: null });
          });
      } else {
        createLifestyleCategory(data)
          .then(res => {
            this.setState({ error: null, successKey: 'created' });
            history.push(
              `/manage-lifestyle/sleepCategory/${encodeURIComponent(
                res.result.sleepCategory,
              )}`,
            );
          })
          .catch(err => {
            this.setState({ error: err, successKey: null });
          });
      }
    };
    const remove =
      category && category.id
        ? () => {
            deleteLifestyleCategory(category)
              .then(() => {
                history.push('/manage-lifestyle/sleepCategory/');
              })
              .catch(err => {
                this.setState({ error: err, successKey: null });
              });
          }
        : null;
    return (
      <EditCategoryView
        loading={loading}
        error={error}
        remove={remove}
        successKey={successKey}
        initialCategory={category}
        save={save}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps.match.params;
  const category = (state.lifestyleCategories.byId.sleepCategory || {})[id];
  return {
    category,
  };
};

const mapDispatchToProps = dispatch => ({
  loadLifestyleCategory: wrapAPIDispatch(
    dispatch,
    actions.loadLifestyleCategory('sleep'),
  ),
  createLifestyleCategory: wrapAPIDispatch(
    dispatch,
    actions.createLifestyleCategory('sleep'),
  ),
  putLifestyleCategory: wrapAPIDispatch(
    dispatch,
    actions.putLifestyleCategory('sleep'),
  ),
  deleteLifestyleCategory: wrapAPIDispatch(
    dispatch,
    actions.deleteLifestyleCategory('sleep'),
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditCategoryContainer),
);
