// @flow
import React, { useState, useEffect } from 'react';
import { Input, Segment } from 'semantic-ui-react';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

import type { ModifyableBaseEntity } from 'src/utilities/flowtypes';

type Props = {|
  imgURL: ModifyableBaseEntity,
  update: <T: ModifyableBaseEntity>(T | (T => T)) => void,
  t: TFunc,
|};

const ImageURL = ({ imgURL, update, t }: Props) => {
  const [imageURL, setImageURL] = useState<string>('');

  useEffect(() => {
    if (imgURL) {
      setImageURL(imgURL);
    }
  }, [imgURL]);

  const onBlur = () => {
    update<ModifyableBaseEntity>(m => ({
      ...m,
      imageURL,
    }));
  };
  return (
    <Segment className="grid">
      <div className="column">
        <h3>{t('lifestyle.baseEntity.edit.imageURL', 'Image')}</h3>
        <hr />
      </div>
      <div className="column">
        <label className="form-label" htmlFor="imageURL">
          {t('lifestyle.baseEntity.edit.imageURL', 'ImageURL')}
        </label>
        <Input
          fluid
          type="text"
          id="imageURL"
          name="imageURL"
          value={imageURL}
          onChange={(ev, { value }) => setImageURL(value)}
          onBlur={onBlur}
        />
      </div>
    </Segment>
  );
};

const thing = withTranslation()(ImageURL);

export default thing;
