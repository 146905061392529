// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { wrapAPIDispatch } from 'src/store/middleware/api';
import * as actions from 'src/store/actions';
import { getEntityData } from 'src/store/utilities';
import type { VideoEntity } from 'src/utilities/flowtypes';

import EntityListView from 'src/components/Lifestyle/EntityListView';

type Props = {
  loadAllEntities: () => void,
  entities: $ReadOnlyArray<VideoEntity>,
};

class SleepListContainer extends Component<Props> {
  componentDidMount() {
    const { loadAllEntities } = this.props;
    loadAllEntities();
  }

  render() {
    return <EntityListView {...this.props} entityName="sleep" />;
  }
}

const mapStateToProps = state => {
  const sleepies = (state.lifestyleEntities.all.sleepies || [])
    .map(sleepId => getEntityData(state, 'sleep', sleepId).entity)
    .map(({ categories, ...rest }) => ({
      ...rest,
      categories: categories.map(
        categoryId => getEntityData(state, 'sleepCategory', categoryId).entity,
      ),
    }));
  return {
    entities: sleepies,
  };
};
const mapDispatchToProps = dispatch => ({
  loadAllEntities: wrapAPIDispatch(
    dispatch,
    actions.loadAllLifestyleEntities('sleep'),
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SleepListContainer),
);
