import React from 'react';
import classnames from 'classnames';

const Message = ({
  className,
  content,
  type,
}: {
  className?: string;
  content: string;
  type: 'success' | 'error' | 'warning';
}): JSX.Element => {
  return (
    <div
      className={classnames(
        'px-5 py-3 rounded',
        type === 'success' &&
          'bg-fern-500 bg-opacity-10 text-fern-500 border-fern-500 border-width-3 border border-solid',
        type === 'error' &&
          'bg-rose-500 bg-opacity-10 text-rose-500 border-rose-500 border-width-3 border border-solid',
        type === 'warning' &&
          'bg-supernova-500 bg-opacity-10 text-supernova-500 border-supernova-500 border-width-3 border border-solid',
        className,
      )}
    >
      {content}
    </div>
  );
};

export default Message;
