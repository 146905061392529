// @flow
import {
  API_DATA,
  wrapAPIActionCreator,
  type SuccessCallback,
  type ErrorCallback,
} from 'src/store/middleware/api';

export type DeleteLifestyleEntityParams = {
  +id: string,
};

export const DELETE_LIFESTYLE_ENTITY = 'DELETE_LIFESTYLE_ENTITY';
const apiFunc = wrapAPIActionCreator<
  DeleteLifestyleEntityParams & { entityName: string },
>(({ id, entityName }) => ({
  [API_DATA]: {
    type: DELETE_LIFESTYLE_ENTITY,
    endpoint: `/api/${entityName}/${id}`,
    authentication: true,
    method: 'DELETE',
  },
}));

export function deleteLifestyleEntity(
  entityName: string,
): (
  params: DeleteLifestyleEntityParams,
  onSuccess?: SuccessCallback,
  onError?: ErrorCallback,
) => any {
  return (params, onSuccess, onError) =>
    apiFunc({ ...params, entityName }, onSuccess, onError);
}
