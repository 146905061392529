import React from 'react';
import classNames from 'classnames';

interface ButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  loading?: boolean;
  children: JSX.Element | JSX.Element[] | string;
  disabled?: boolean;
  type?: 'info' | 'success' | 'warning' | 'danger';
  submit?: boolean;
  ref?: React.Ref<HTMLButtonElement>;
}

const Button = ({
  onClick,
  className,
  loading = false,
  children,
  disabled = false,
  type,
  submit = false,
}: ButtonProps): JSX.Element => {
  return (
    <button
      type={submit ? 'submit' : 'button'}
      className={classNames(
        'relative flex justify-center items-center rounded border-0 py-2 px-4 text-white font-semibold',
        !type &&
          'bg-black-haze-500 hover:bg-black-haze-700 active:bg-black-haze-800',
        type === 'info' &&
          'bg-primary-500 hover:bg-primary-700 active:bg-primary-800',
        type === 'success' &&
          'bg-fern-500 hover:bg-fern-700 active:bg-fern-800',
        type === 'warning' &&
          'bg-supernova-500 hover:bg-supernova-700 active:bg-supernova-800',
        type === 'danger' && 'bg-rose-500 hover:bg-rose-700 active:bg-rose-800',
        disabled || loading ? 'cursor-not-allowed' : 'cursor-pointer',
        className,
      )}
      onClick={onClick}
      disabled={loading || disabled}
    >
      <span className={classNames(loading ? 'opacity-0' : 'opacity-100')}>
        {children}
      </span>
      {loading && (
        <svg
          className="animate-spin h-5 w-5 text-white absolute"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      )}
    </button>
  );
};

export default Button;
