import React from 'react';
import ListAllUsers from './ListAllUsers';

const Users = () => {
  return (
    <div className="max-width">
      <ListAllUsers />
    </div>
  );
};

export default Users;
