// @flow
import React, { Component } from 'react';
import { Header, Grid } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment, { type Moment } from 'moment';

import type { User } from 'src/utilities/flowtypes';
import filterUsersByDate from 'src/utilities/filterUsersByDate';
import DateButton from './components/DateButton';
import 'react-datepicker/dist/react-datepicker.css';

type Props = {
  userList: User[],
};

type State = {
  fromDate: Moment,
  toDate: Moment,
};

class UsersFilter extends Component<Props, State> {
  state = {
    fromDate: moment(),
    toDate: moment(),
  };

  handleChangeFromDate = (data: Moment) => {
    this.setState({
      fromDate: data,
    });
  };

  handleChangeToDate = (data: Moment) => {
    this.setState({
      toDate: data,
    });
  };

  render() {
    const { userList: users } = this.props;
    const { fromDate, toDate } = this.state;
    const filteredUsers = filterUsersByDate({
      users,
      fromDate,
      toDate,
    });
    return (
      <div>
        <Header as="h4">Filter users by date: ({filteredUsers.length})</Header>
        <Grid columns="1">
          <Grid.Column>
            From date:
            <DatePicker
              customInput={<DateButton />}
              selected={fromDate}
              onChange={this.handleChangeFromDate}
            />
          </Grid.Column>
          <Grid.Column>
            To date:
            <DatePicker
              customInput={<DateButton />}
              selected={toDate}
              onChange={this.handleChangeToDate}
            />
          </Grid.Column>
        </Grid>
        <div>
          {filteredUsers.map(user => (
            <div key={user.id}>{user.email}</div>
          ))}
        </div>
      </div>
    );
  }
}

export default UsersFilter;
