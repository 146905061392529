// @flow
import React from 'react';
import { Header, Segment, Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';
import type { BaseCategory } from 'src/utilities/flowtypes';
import classnames from 'classnames';

type Props = {
  category: BaseCategory,
  t: TFunc,
  entityName: string,
};

const CategoryListItem = ({ category, t, entityName }: Props) => {
  const { referenceTitle, referenceDescription, id, published } = category;
  return (
    <Segment>
      <div className="grid">
        <div className="column min-width-0 flex-grow-1 flex-basis-0">
          <div className="inner-segment">
            <div className="grid grid-x0">
              <div className="column">
                <Header
                  as="h2"
                  size="medium"
                  className={classnames([
                    'secondary',
                    !published && 'unpublished',
                  ])}
                >
                  {t(
                    `${entityName}:categoryEntity.${id}.title`,
                    referenceTitle,
                  )}
                </Header>
              </div>
              <div className="column">
                <p
                  className="ellipsis"
                  title={t(
                    `${entityName}:categoryEntity.${id}.description`,
                    referenceDescription,
                  )}
                >
                  {t(
                    `${entityName}:categoryEntity.${id}.description`,
                    referenceDescription,
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="column flex-basis-auto display-flex">
          <Button
            as={Link}
            to={`/manage-lifestyle/${entityName}Category/${id}`}
            icon
            labelPosition="right"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {t('common:buttons.edit')}
            <Icon name="right arrow" />
          </Button>
        </div>
      </div>
    </Segment>
  );
};

export default withTranslation()<Props>(CategoryListItem);
