// @flow
import React, { PureComponent } from 'react';
import { Header, Input } from 'semantic-ui-react';
import { Trans } from '@pija-ab/i18n-react-system';

import type { User } from 'src/utilities/flowtypes';
import UserListSearch from './components/UserListSearch';
import UserListFilter from './components/UserListFilter';
import UserListTable from './components/UserListTable';

type Props = {
  users: User[],
  type?: string | null,
};

type SortKey = null | 'email' | 'points' | 'created' | 'updated';
type SortDirection = 'ascending' | 'descending';
type State = {
  sortBy: SortKey,
  direction: SortDirection,
  searchValue: string,
};

const initialState = {
  sortBy: null,
  direction: 'ascending',
  searchValue: '',
};

type CompareFunc = {
  (aVal: Date, bVal: Date): number,
  (aVal: number, bVal: number): number,
  (aVal: string, bVal: string): number,
};

class UsersList extends PureComponent<Props, State> {
  static defaultProps = {
    type: null,
  };

  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }

  onSearchChange = (event: SyntheticEvent<Input>) => {
    this.setState({ searchValue: event.currentTarget.value });
  };

  filterBySearchValue = (users: User[], searchValue: string = ''): User[] => {
    const lowerCaseVal = searchValue.toLowerCase();
    return users.filter(user =>
      user.email.toLowerCase().includes(lowerCaseVal),
    );
  };

  clearFilters = () => {
    this.setState(initialState);
  };

  handleSort = (sort: SortKey) => {
    const { sortBy, direction } = this.state;
    if (sortBy !== sort) {
      this.setState({
        sortBy: sort,
        direction: 'ascending',
      });
    } else {
      this.setState({
        direction: direction === 'ascending' ? 'descending' : 'ascending',
      });
    }
  };

  sortUsersBy = (
    users: User[],
    sortBy: SortKey,
    direction: SortDirection,
  ): User[] => {
    const compare: CompareFunc = (aVal, bVal) => {
      // CompareFunc signature is typed correctly.
      // Flow just can't know that aVal and bVal will always be of the same (comparable) type within function.
      // $FlowIssue
      if (aVal < bVal) return -1;
      // $FlowIssue
      if (aVal > bVal) return +1;
      return 0;
    };
    const sortedUsers = users.slice().sort((a, b) => {
      switch (sortBy) {
        case 'email':
          return compare(a.email, b.email);
        case 'points':
          return compare(a.points, b.points);
        case 'created':
          return compare(new Date(a.createdAt), new Date(b.createdAt));
        case 'updated':
          return compare(new Date(a.updatedAt), new Date(b.updatedAt));
        default:
          return 0;
      }
    });
    return direction === 'ascending' ? sortedUsers : sortedUsers.reverse();
  };

  render() {
    const { users: initialUserList, type } = this.props;
    const { sortBy, direction, searchValue } = this.state;
    const sortedinitialUserList = this.sortUsersBy(
      initialUserList,
      sortBy,
      direction,
    );
    const userList = this.filterBySearchValue(
      sortedinitialUserList,
      searchValue,
    );
    return (
      <div className="grid">
        <div className="column">
          <Header as="h3" className="secondary">
            {userList.length !== initialUserList.length ? (
              <Trans
                i18nKey={`organization.userList${
                  type ? `.${type}` : ''
                }.filtered`}
                count={userList.length}
              >
                {{ count: userList.length }} User
                <React.Fragment>
                  <span style={{ opacity: 0.75 }}> - </span>
                  {/* $FlowIssue: <Trans> does magic here. */}
                  <span style={{ opacity: 0.5 }}>
                    Out of {{ totUsers: initialUserList.length }}
                  </span>
                </React.Fragment>
              </Trans>
            ) : (
              <Trans
                i18nKey={`organization.userList${
                  type ? `.${type}` : ''
                }.unfiltered`}
                count={userList.length}
              >
                {{ count: userList.length }} User
              </Trans>
            )}
          </Header>
        </div>
        <div className="column">
          <div className="grid">
            <div className="column flex-grow-1 flex-basis-auto">
              <UserListSearch
                type={type}
                searchValue={searchValue}
                onSearchChange={this.onSearchChange}
              />
            </div>
            <div className="column flex-basis-auto display-flex display-none">
              <UserListFilter userList={userList} />
            </div>
          </div>
        </div>
        <div className="column">
          <UserListTable
            userList={userList}
            type={type}
            sortBy={sortBy}
            direction={direction}
            searchValue={searchValue}
            clearFilters={this.clearFilters}
            handleSort={this.handleSort}
          />
        </div>
      </div>
    );
  }
}

export default UsersList;
