// @flow
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';
import * as actions from 'src/store/actions';
import { wrapAPIDispatch } from 'src/store/middleware/api';

import EditUserForm from './EditUserForm';

type Props = {
  t: TFunc,
};

const initialState = {
  email: '',
  password: '',
  organizationId: null,
  role: null,
  message: '',
  errorMessage: '',
};
const CreateUserView = (props: Props): R.Node => {
  const dispatch = useDispatch();
  const [state, setState] = useState(initialState);

  const createUser = async () => {
    const { t } = props;
    const { email, password, organizationId, role } = state;
    const createUserArguments = {
      email,
      password,
      role: role || '',
    };
    if (organizationId && role !== 'administrator') {
      createUserArguments.organizationId = organizationId;
    }
    try {
      const create = wrapAPIDispatch(dispatch, actions.createUser);
      await create(createUserArguments);
      setState({
        ...initialState,
        message: t('createUser.created'),
        errorMessage: '',
      });
    } catch (error) {
      setState({
        ...state,
        message: '',
        errorMessage: error.message,
      });
    }
  };

  return (
    <EditUserForm
      state={state}
      setState={setState}
      type="create"
      onSubmit={createUser}
    />
  );
};

export default withTranslation()(CreateUserView);
