import React from 'react';

import { Header, Segment, Input, Icon, Button } from 'semantic-ui-react';
import { withTranslation, TFunc } from '@pija-ab/i18n-react-system';

type Props = {
  employeeCount?: number;
  handleChange: () => void;
  handleSubmit: () => void;
  t: TFunc;
};

function EmployeeCountsView({
  employeeCount,
  handleChange,
  handleSubmit,
  t,
}: Props): JSX.Element {
  return (
    <React.Fragment>
      <Header as="h2">{t('organization.employeeCounts.title')}</Header>
      <Segment>
        <p>{t('organization.employeeCounts.description')}</p>
        <Input
          fluid
          action
          id="count"
          type="number"
          name="count"
          min="0"
          placeholder="Employee Count"
          iconPosition="left"
          value={employeeCount}
          onChange={handleChange}
        >
          <Icon name="numbered list" />
          <input />
          <Button onClick={handleSubmit}>{t('common:buttons.update')}</Button>
        </Input>
      </Segment>
    </React.Fragment>
  );
}

export default withTranslation()(EmployeeCountsView);
