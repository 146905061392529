import React, { useState } from 'react';

import { Header, Segment, Loading, Dropdown } from 'src/components/elements';
import { Tabs, Tab } from 'src/components/elements/Tabs';
import { withTranslation, Trans, TFunc } from '@pija-ab/i18n-react-system';
import HHIChart from 'src/scenes/Organization/components/OrganizationStatisticsSpecific/components/HHIChart';

import moment from 'moment';

import { User, Organization } from 'src/types/common';

import Button from 'src/components/elements/Button';
import OrganizationStatistics from './components/OrganizationStatistics';
import UserList from './components/UserList';
import WhitelistedEmailList from './components/WhitelistedEmailList';
import ArchiveOrganizationModal from './ArchiveOrganizationModal';

type HHI = {
  index: number;
  foodPreferences: number;
  eatingBehavior: number;
  energyLevel: number;
  sleepQuality: number;
  physicalActivity: number;
};

type SubOrgHHI = {
  orgId: string;
  orgName: string;
  hhi: HHI;
};

type OrganizationViewProps = Readonly<{
  organization: Organization;
  organizationStatistics: {
    numEmployeesRegistered: number;
    numEmployeesWithHHI: number;
    employeesPerGoal: { [key: string]: number };
    averageHHI: HHI;
    globalAverageHHI: HHI;
    parentAverageHHI?: HHI;
    byMonth: {
      label: string;
      numCreatedUsers: number;
      numActiveUsers: number;
      numEmployees?: number;
    }[];
    challengeEngagement: {
      [key: string]: {
        started: number;
        finished: number;
      };
    };
  };
  userRole: string;
  adminUsers: User[];
  hrUsers: User[];
  users: User[];
  t: TFunc;
  archiveOrganizationAction: ({
    organizationId,
  }: {
    organizationId: string;
  }) => Promise<void>;
  unarchiveOrganizationAction: ({
    organizationId,
  }: {
    organizationId: string;
  }) => Promise<void>;
  childOrganizations: Organization[];
  aggregatedParentHHI: {
    organizationHHI?: HHI;
    subOrgs?: SubOrgHHI[];
  };
  viewType: string;
}>;

function OrganizationView({
  organization,
  organizationStatistics,
  adminUsers,
  hrUsers,
  users,
  aggregatedParentHHI,
  archiveOrganizationAction,
  unarchiveOrganizationAction,
  userRole,
  childOrganizations,
  t,
}: OrganizationViewProps): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedSubOrg, setSelectedSubOrg] = useState<SubOrgHHI | null>(null);
  if (organization === null || hrUsers[0] === null || users[0] === null) {
    return <Loading center />;
  }
  const archiveOrganization = async () => {
    try {
      setLoading(true);
      await archiveOrganizationAction({ organizationId: organization.id });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const unarchiveOrganization = async () => {
    try {
      setLoading(true);
      await unarchiveOrganizationAction({ organizationId: organization.id });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  if (organization.archived && userRole !== 'administrator') {
    return (
      <div className="flex items-center justify-center">
        The organization has been archived
      </div>
    );
  }

  const {
    displayName,
    subdomain,
    emailWhitelist,
    employeeCounts,
    parentOrganizationId,
    isParentOnly,
    // incentive,
  } = organization;

  const {
    numEmployeesRegistered,
    byMonth,
    // employeesPerGoal,
    // averageHHI,
    // parentAverageHHI,
    globalAverageHHI,
    // challengeEngagement,
  } = organizationStatistics;

  const subOrgs = aggregatedParentHHI.subOrgs?.map(org => ({
    id: org.orgId,
    name: org.orgName,
    disabled:
      org.hhi != null
        ? Object.values(org.hhi).filter(val => val > 0).length === 0
        : false,
  }));

  const onSelectSubOrganization = (option: { id: string | null }) => {
    if (option.id == null) {
      setSelectedSubOrg(null);
      return;
    }
    const selectedOrg = aggregatedParentHHI?.subOrgs?.find(
      org => org.orgId === option.id,
    );
    if (selectedOrg) {
      setSelectedSubOrg(selectedOrg);
    }
  };

  const renderUserTabs = () => {
    return (
      <Tabs
        tabs={
          [
            !isParentOnly && {
              tab: t('organization.tabMenu.users', {
                usersCount: users.length,
              }),
              content: (
                <Segment>
                  <UserList users={users} />
                </Segment>
              ),
            },
            !isParentOnly && {
              tab: t('organization.tabMenu.hrUsers', {
                hrUsersCount: hrUsers.length,
              }),
              content: (
                <Segment>
                  <UserList users={hrUsers} type="hr" />
                </Segment>
              ),
            },
            (isParentOnly || parentOrganizationId != null) && {
              tab: t('organization.tabMenu.adminUsers', {
                adminUsersCount: adminUsers.length,
              }),
              content: (
                <Segment>
                  <UserList users={adminUsers} type="admins" />
                </Segment>
              ),
            },
            !isParentOnly && {
              tab: t('organization.tabMenu.whitelistEmail', {
                emailsCount: emailWhitelist.length,
              }),
              content: (
                <Segment>
                  <WhitelistedEmailList emailWhitelist={emailWhitelist} />
                </Segment>
              ),
            },
          ].filter(Boolean) as Tab[]
        }
      />
    );
  };

  if (organization.archived) {
    return (
      <div className="grid grid-x4">
        <div className="column flex justify-between items-center">
          <Header as="h1">
            <Trans i18nKey="organization.title" values={{ displayName }}>
              Organization:<span className="color-primary">{displayName}</span>
            </Trans>
            <Header>
              {subdomain}
              <span style={{ opacity: '0.5' }}>@holistal.com</span>
            </Header>
          </Header>
        </div>
        <div className="column">
          <Header as="h2">
            {t(
              'organization.archive.informationHeader',
              "This organization and it's users will be removed permanently in {{days}} days",
              {
                days: moment(organization.archiveDate)
                  .startOf('day')
                  .add(90, 'days')
                  .diff(moment(), 'days'),
              },
            )}
          </Header>
          {renderUserTabs()}
        </div>
        {organization.archived && userRole === 'administrator' && (
          <div className="w-full p-3.5 flex justify-end">
            <Button
              className="p-2 bg-green-500 text-white rounded-md border-none"
              loading={loading}
              onClick={unarchiveOrganization}
            >
              {t('common:buttons.unarchive', 'Unarchive')}
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="grid grid-x4">
        <div className="column">
          <Header as="h1" className="mb-0">
            <Trans i18nKey="organization.title" values={{ displayName }}>
              Organization:<span className="color-primary">{displayName}</span>
            </Trans>
          </Header>
          <Header as="h2" className="text-base font-normal mt-0">
            {subdomain}
            <span style={{ opacity: '0.5' }}>@holistal.com</span>
          </Header>
        </div>
        {!isParentOnly && (
          <>
            <div className="column">
              <OrganizationStatistics
                numEmployeesRegistered={numEmployeesRegistered}
                byMonth={byMonth}
                hrUsers={hrUsers}
                emailWhitelist={emailWhitelist}
                employeeCounts={employeeCounts}
              />
            </div>
            {/* <div className="column">
              <OrganizationStatisticsSpecific
                employeesPerGoal={employeesPerGoal}
                averageHHI={averageHHI}
                parentAverageHHI={parentAverageHHI}
                globalAverageHHI={globalAverageHHI}
                challengeEngagement={challengeEngagement}
              />
            </div>
            <div className="column">
              <Incentive
                incentive={incentive}
                organizationId={organization.id}
              />
            </div>
            {userRole === 'administrator' && (
              <div className="column">
                <EmployeeCounts
                  employeeCounts={employeeCounts}
                  organizationId={organization.id}
                />
              </div>
            )}

            <div className="column">
              <AssessmentInterval
                assessmentIntervalDays={organization.assessmentIntervalDays}
                organizationId={organization.id}
              />
            </div> */}
          </>
        )}
        {isParentOnly && (
          <div className="column">
            <Segment className="w-full">
              <div className="">
                <div className="flex md:items-center justify-between flex-col md:flex-row">
                  <Header>{t('organization.specStats.HHI.title')}</Header>
                  {aggregatedParentHHI.subOrgs ? (
                    <div className="md:w-1/3 md:min-w-[300px]">
                      <Dropdown
                        placeholderTitle={t(
                          'organization.dropdown.placeholder',
                        )}
                        options={[
                          {
                            id: null,
                            name: t('organization.dropdown.clear'),
                          },
                          ...(subOrgs ?? []),
                        ]}
                        onSelect={onSelectSubOrganization}
                      />
                    </div>
                  ) : (
                    <Loading center />
                  )}
                </div>
                <HHIChart
                  hideTitle
                  averageHHI={aggregatedParentHHI.organizationHHI}
                  globalAverageHHI={globalAverageHHI}
                  divisionAverageHHI={selectedSubOrg?.hhi}
                  divisionName={selectedSubOrg?.orgName}
                />
              </div>
            </Segment>
          </div>
        )}
        {!organization.archived && userRole === 'administrator' && (
          <div className="w-full p-3.5 flex justify-end">
            <button
              type="button"
              className="p-2 bg-red-500 text-white rounded-md border-none cursor-pointer"
              onClick={() => setIsModalOpen(true)}
            >
              {t('common:buttons.archive', 'Archive')}
            </button>
          </div>
        )}
      </div>
      <ArchiveOrganizationModal
        handleArchiveOrganization={archiveOrganization}
        childOrganizationCount={childOrganizations.length}
        organization={organization}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
}

export default withTranslation()(OrganizationView);
