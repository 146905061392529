// @flow
import React, { PureComponent } from 'react';
import { Button, Icon } from 'semantic-ui-react';

type Props = {
  value?: string | null,
  onClick?: () => void,
};

class DateButton extends PureComponent<Props> {
  static defaultProps = {
    onClick: () => {},
    value: null,
  };

  render() {
    const { value = null, onClick } = this.props;
    return (
      <Button compact basic icon labelPosition="right" onClick={onClick}>
        {value}
        <Icon name="dropdown" />
      </Button>
    );
  }
}

export default DateButton;
