// @flow
import React from 'react';
import { Header } from 'semantic-ui-react';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

import CreateOrganization from 'src/components/CreateOrganization';

const CreateOrganizationView = ({ t }: { t: TFunc }) => (
  <div className="max-width max-width-extra-narrow">
    <Header as="h1" className="page-title">
      {t('createOrganization.title')}
    </Header>
    <CreateOrganization />
  </div>
);

export default withTranslation()(CreateOrganizationView);
