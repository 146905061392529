import React, { MouseEventHandler } from 'react';
import { Dialog } from '@headlessui/react';
import { withTranslation, TFunc } from '@pija-ab/i18n-react-system';
import Button from './Button';

export interface ModalProps {
  setIsModalOpen: (value: boolean) => void;
  isModalOpen: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  description?: string;
  title: string;
  t: TFunc;
  confirmButtonClassNames?: string; // write this as a tailwind class eg: bg-red-500
  cancelButtonClassNames?: string; // write this as a tailwind class eg: bg-red-500
  confirmButtonText?: string;
}

function Modal({
  setIsModalOpen,
  isModalOpen,
  onClick,
  description,
  title,
  confirmButtonClassNames,
  confirmButtonText,
  cancelButtonClassNames,
  t,
}: Readonly<ModalProps>): JSX.Element {
  const handleOnClick: MouseEventHandler<HTMLButtonElement> = evt => {
    setIsModalOpen(false);
    onClick(evt);
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/70" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4 min-h-full text-center">
        <Dialog.Panel className="p-6 w-full  mx-auto max-w-sm rounded bg-white text-left align-middle">
          <Dialog.Title
            as="h3"
            className="text-md font-medium leading-6 text-gray-900"
          >
            {title}
          </Dialog.Title>
          {description && (
            <Dialog.Description className="font-medium leading-6 text-gray-900">
              {description}
            </Dialog.Description>
          )}
          <div className="flex justify-between mt-4">
            <Button
              onClick={() => setIsModalOpen(false)}
              className={cancelButtonClassNames}
            >
              {t('common:buttons.cancel', 'Cancel')}
            </Button>
            <Button onClick={handleOnClick} className={confirmButtonClassNames}>
              {confirmButtonText || t('common:buttons.confirm', 'Confirm')}
            </Button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default withTranslation()(Modal);
