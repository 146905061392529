// @flow
import React, { useState } from 'react';
/* :: import * as R from 'react'; */

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';
import {
  DiscreteColorLegend,
  RadialChart,
  makeWidthFlexible,
  Hint,
} from 'react-vis';
import { getGoal } from 'src/store/utilities';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

const FlexibleRadialChart = makeWidthFlexible(RadialChart);

type Props = {
  goalCounts: { [string]: number },
  goals: { [string]: ?{ title: string, gid: string } },
  t: TFunc,
};

const colors = [
  '#286a74',
  '#ed6440',
  '#58254b',

  '#70b2b8',
  '#ea9b67',
  '#7d3c6e',

  '#43858b',
  '#eb8155',
  '#612e55',

  '#a3d0d4',
  '#a0518d',
  '#874278',

  '#89c4c9',
  '#ffc28c',
];

const sum = arr => arr.reduce((a, b) => a + b, 0);
const valuesOf = obj => Object.keys(obj).map(key => obj[key]);

function GoalBreakdownChart({ goalCounts = {}, goals, t }: Props): R.Node {
  const [hoveredValue, setHoveredValue] = useState(null);
  const goalTotal = sum(valuesOf(goalCounts));
  const toPercentage = v => `${((v / goalTotal) * 100).toFixed(0)}%`;
  const data = Object.keys(goalCounts)
    .filter(key => goalCounts[key] > 0)
    .sort((a, b) => goalCounts[b] - goalCounts[a])
    .map((id, i) => ({
      angle: goalCounts[id],
      color: colors[i % colors.length],
      title: `${
        goals[id]
          ? t(`model:goals.${goals[id].gid}.title`)
          : t('organization.specStats.goalsBreakdown.goals.noTitle')
      } (${toPercentage(goalCounts[id])})`,
    }));

  return (
    <div style={{ height: '100%' }}>
      <Header as="h3">
        {t('organization.specStats.goalsBreakdown.title')}
      </Header>
      <div className="grid">
        <div className="column column-6 display-flex">
          <DiscreteColorLegend items={data} />
        </div>
        <div className="column column-6 display-flex">
          <FlexibleRadialChart
            height={14 * 14}
            data={data}
            colorType="literal"
            onValueMouseOver={v => setHoveredValue(v)}
            onValueMouseOut={() => setHoveredValue(null)}
          >
            {hoveredValue && (
              <Hint value={hoveredValue}>
                <div
                  style={{
                    margin: 4,
                    padding: 4,
                    borderRadius: 4,
                    background: 'rgba(0,0,0, 0.7)',
                  }}
                >
                  <span>{hoveredValue.title}</span>
                </div>
              </Hint>
            )}
          </FlexibleRadialChart>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, { goalCounts = {} }) => {
  const goalIds = Object.keys(goalCounts);
  const goalsList = goalIds.map(id => getGoal(state, id).entity);

  const goalsMap = {};
  goalIds.forEach((id, i) => {
    goalsMap[id] = goalsList[i];
  });

  return {
    goals: goalsMap,
  };
};

export default compose(connect(mapStateToProps))(
  withTranslation()(GoalBreakdownChart),
);
