// @flow
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import entities from './entities';
import userData from './userData';
import organizations from './organizations';
import { lifestyleEntities, lifestyleCategories } from './lifestyle';
import vimeoVideos from './vimeoVideos';
import users from './users';

export const reducers = {
  entities,
  userData,
  organizations,
  lifestyleEntities,
  lifestyleCategories,
  vimeoVideos,
  users,
};

export default (history: any) =>
  combineReducers({ router: connectRouter(history), ...reducers });
