// @flow
import React, { Fragment, useState, useCallback, SyntheticEvent } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  XMarkIcon,
  UsersIcon,
  UserPlusIcon,
  BuildingOffice2Icon,
  BuildingOfficeIcon,
  LifebuoyIcon,
  ChartBarIcon,
  Cog6ToothIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { withTranslation, TFunc } from '@pija-ab/i18n-react-system';
import UserDropdown from './UserDropdown';
import LangDropdown from './LangDropdown';
import PageHeaderSearch from '../PageHeader/components/PageHeaderSearch';
import { Organization, User } from 'src/types/common';
import { Lang } from './SidemenuContainer';

type Props = {
  children: JSX.Element;
  user?: User;
  logout: () => void;
  t: TFunc;
  organization: {
    id: string;
    displayName: string;
  };
  curLngIndex: number;
  langs: Lang[];
  onChange: (
    event: SyntheticEvent,
    data: { active: boolean; value: number },
  ) => void;
  childOrganizations: Organization[];
  parentOrganization: Organization | null;
};

type LinkItem = {
  key: string;
  url: string;
  text: string;
  icon: typeof Bars3Icon;
  current: boolean;
};

const Header = () => {
  return (
    <h1 className="text-primary-400">
      <Link to="/" className="font-barlow text-burnt-sienna-400 no-underline">
        Holistal Admin
      </Link>
    </h1>
  );
};

function SideMenuLayout({
  children,
  user,
  logout,
  organization,
  t,
  curLngIndex,
  langs,
  onChange,
  childOrganizations,
  parentOrganization,
}: Props) {
  const { pathname } = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  let links = [] as LinkItem[];

  if (user && user.role === 'administrator') {
    links = [
      {
        key: 'manage-lifestyle',
        url: '/manage-lifestyle',
        text: t('header.menu.manageLifestyle', 'Manage Lifestyle'),
        icon: LifebuoyIcon,
        current: pathname.includes('/manage-lifestyle'),
      },
      {
        key: 'organizations',
        url: '/organizations',
        text: t('header.menu.organizations', 'Organizations'),
        icon: BuildingOffice2Icon,
        current: pathname.includes('/organization'),
      },
      {
        key: 'create-organization',
        url: '/create-organization',
        text: t('header.menu.createOrganization', 'Create Organization'),
        icon: BuildingOfficeIcon,
        current: pathname.includes('/create-organization'),
      },
      {
        key: 'users',
        url: '/users',
        text: t('header.menu.listUsers', 'Users'),
        icon: UsersIcon,
        current:
          pathname.includes('/users') && !pathname.includes('/organization'),
      },
      {
        key: 'create-user',
        url: '/create-user',
        text: t('header.menu.createUser', 'Create User'),
        icon: UserPlusIcon,
        current: pathname.includes('/create-user'),
      },
    ];
  }

  const renderLinkItem = (item: LinkItem) => {
    return (
      <li key={item.key} className="list-none">
        <Link
          onClick={() => setSidebarOpen(false)}
          to={item.url}
          className={classNames(
            item.current
              ? 'bg-gray-50 text-primary-400'
              : 'text-gray-700 hover:text-primary-400 hover:bg-gray-50',
            'group flex gap-x-3 rounded-md p-2 leading-6 font-semibold no-underline hover:no-underline',
          )}
        >
          <item.icon
            className={classNames(
              item.current
                ? 'text-primary-400'
                : 'text-gray-400 group-hover:text-primary-400',
              'h-6 w-6 shrink-0',
            )}
            aria-hidden="true"
          />
          {item.text}
        </Link>
      </li>
    );
  };

  const renderOrganizationLink = useCallback(() => {
    if (
      pathname.includes('/organization') &&
      organization &&
      organization.displayName
    ) {
      const organizationLinks = [
        {
          key: 'Statistics',
          display: t('sideMenu.organization.statistics'),
          link: `/organization/${organization.id}/statistics`,
          icon: ChartBarIcon,
          current: pathname.includes('/statistics'),
        },
        {
          key: 'Settings',
          display: t('sideMenu.organization.settings'),
          link: `/organization/${organization.id}/settings`,
          icon: Cog6ToothIcon,
          current: pathname.includes('/settings'),
        },
        {
          key: 'Users',
          display: t('sideMenu.organization.users'),
          link: `/organization/${organization.id}/users`,
          icon: UsersIcon,
          current: pathname.includes('/users'),
        },
      ];

      const sidemenuIsOrg =
        pathname.includes(`/organization/${organization.id}`) &&
        !pathname.includes('/statistics') &&
        !pathname.includes('/settings') &&
        !pathname.includes('/users');
      return (
        <>
          <li className={classNames('list-none relative')}>
            <Link
              onClick={() => setSidebarOpen(false)}
              className={classNames(
                sidemenuIsOrg
                  ? 'bg-gray-50 text-primary-400'
                  : 'text-gray-700 hover:text-primary-400 hover:bg-gray-50',
                'group flex gap-x-3 rounded-md p-2 leading-6 font-semibold no-underline hover:no-underline',
              )}
              to={`/organization/${organization.id}`}
            >
              <BuildingOfficeIcon
                className={classNames(
                  sidemenuIsOrg
                    ? 'text-primary-400'
                    : 'text-gray-400 group-hover:text-primary-400',
                  'h-6 w-6 shrink-0',
                )}
                aria-hidden="true"
              />
              <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                {organization.displayName}
              </span>
            </Link>
            <ul className="-mx-2 space-y-1 list-none">
              {organizationLinks.map(link => (
                <li className={classNames('list-none -ml-4')} key={link.key}>
                  <Link
                    onClick={() => setSidebarOpen(false)}
                    className={classNames(
                      link.current
                        ? 'bg-gray-50 text-primary-400'
                        : 'text-gray-700 hover:text-primary-400 hover:bg-gray-50',
                      'group flex gap-x-3 rounded-md p-2 leading-6 font-semibold no-underline hover:no-underline',
                    )}
                    to={link.link}
                  >
                    <link.icon
                      className={classNames(
                        link.current
                          ? 'text-primary-400'
                          : 'text-gray-400 group-hover:text-primary-400',
                        'h-6 w-6 shrink-0',
                      )}
                      aria-hidden="true"
                    />
                    <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                      {link.display}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
            {(childOrganizations.length > 0 || links.length > 0) && <hr />}
          </li>
          {childOrganizations && childOrganizations.length > 0 && (
            <>
              {parentOrganization && (
                <li>
                  <Link
                    onClick={() => setSidebarOpen(false)}
                    className={classNames(
                      'text-gray-700 hover:text-primary-400 hover:bg-gray-50',
                      'group flex gap-x-3 rounded-md p-2 leading-6 font-semibold no-underline hover:no-underline',
                    )}
                    to={`/organization/${parentOrganization.id}`}
                  >
                    <BuildingOffice2Icon
                      className={classNames(
                        'text-gray-400 group-hover:text-primary-400',
                        'h-6 w-6 shrink-0',
                      )}
                      aria-hidden="true"
                    />
                    <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                      {parentOrganization.displayName}
                    </span>
                  </Link>
                </li>
              )}
              <li className={classNames('list-none relative')}>
                <ul
                  className={classNames(
                    '-ml-6 space-y-1 list-none overflow-hidden whitespace-nowrap text-ellipsis',
                  )}
                >
                  {childOrganizations.map(childOrg => (
                    <li key={childOrg.id}>
                      <Link
                        onClick={() => setSidebarOpen(false)}
                        className={classNames(
                          'text-gray-700 hover:text-primary-400 hover:bg-gray-50',
                          'group flex gap-x-3 rounded-md p-2 leading-6 font-semibold no-underline hover:no-underline',
                        )}
                        to={`/organization/${childOrg.id}`}
                      >
                        <BuildingOfficeIcon
                          className={classNames(
                            'text-gray-400 group-hover:text-primary-400',
                            'h-6 w-6 shrink-0',
                          )}
                          aria-hidden="true"
                        />
                        <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                          {childOrg.displayName}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
                {links.length > 0 && <hr />}
              </li>
            </>
          )}
        </>
      );
    }
  }, [organization, pathname]);

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5 bg-transparent border-none cursor-pointer"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <Header />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="-mx-2 space-y-1 list-none pl-0">
                        {renderOrganizationLink()}
                        {links.map(renderLinkItem)}
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        {user && (
          <div
            className={classNames(
              'hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col',
            )}
          >
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
              <div className="flex h-16 shrink-0 items-center">
                <Header />
              </div>
              <nav className="flex flex-1 flex-col">
                <ul className="-mx-2 space-y-1 list-none pl-0">
                  {renderOrganizationLink()}
                  {links.map(renderLinkItem)}
                </ul>
              </nav>
            </div>
          </div>
        )}

        <div className={classNames(user ? 'lg:pl-72' : '')}>
          <div className="sticky top-0 z-40 lg:mx-auto lg:px-8 bg-white">
            <div className="flex h-16 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-0 lg:shadow-none">
              {user && (
                <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-700 lg:hidden bg-transparent border-none cursor-pointer"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
              )}

              <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                {!user && (
                  <div className="flex items-center self-stretch -ml-2">
                    <Header />
                  </div>
                )}
                <div className="flex items-center flex-1">
                  <PageHeaderSearch />
                </div>
                <div className="flex items-center gap-x-4 lg:gap-x-6">
                  {/* Profile dropdown */}
                  {user && (
                    <>
                      <LangDropdown
                        onChange={onChange}
                        curLngIndex={curLngIndex}
                        langs={langs}
                      />
                      <div
                        className="h-6 w-px bg-gray-200 lg:hidden"
                        aria-hidden="true"
                      />
                      <UserDropdown user={user} logout={logout} />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <main className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              {children}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(SideMenuLayout);
