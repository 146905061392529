// @flow
import {
  API_DATA,
  wrapAPIActionCreator,
  type SuccessCallback,
  type ErrorCallback,
} from 'src/store/middleware/api';

export type DeleteLifestyleCategoryParams = {
  +id: string,
};

export const DELETE_LIFESTYLE_CATEGORY = 'DELETE_LIFESTYLE_CATEGORY';
const apiFunc = wrapAPIActionCreator<
  DeleteLifestyleCategoryParams & { entityName: string },
>(({ id, entityName }) => ({
  [API_DATA]: {
    type: DELETE_LIFESTYLE_CATEGORY,
    endpoint: `/api/${encodeURIComponent(entityName)}category/${id}`,
    authentication: true,
    method: 'DELETE',
  },
}));

export function deleteLifestyleCategory(
  entityName: string,
): (
  params: DeleteLifestyleCategoryParams,
  onSuccess?: SuccessCallback,
  onError?: ErrorCallback,
) => any {
  return (params, onSuccess, onError) =>
    apiFunc({ ...params, entityName }, onSuccess, onError);
}
