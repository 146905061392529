import React from 'react';
import WhitelistEmail from './components/WhitelistEmail';
import { Organization, User } from 'src/types/common';
import { Tabs, Tab } from 'src/components/elements/Tabs';
import { Segment } from 'src/components/elements';
import UserList from './components/UserList';
import { TFunc, withTranslation } from '@pija-ab/i18n-react-system';
import WhitelistedEmailList from './components/WhitelistedEmailList';

const UsersView = ({
  organization,
  adminUsers,
  hrUsers,
  users,
  t,
}: {
  organization: Organization;
  adminUsers: User[];
  hrUsers: User[];
  users: User[];
  t: TFunc;
}) => {
  const {
    isParentOnly,
    emailWhitelist,
    parentOrganizationId,
  } = organization ?? {
    isParentOnly: false,
    emailWhitelist: [],
    parentOrganizationId: null,
  };

  const renderUserTabs = () => {
    return (
      <Tabs
        tabs={
          [
            !isParentOnly && {
              tab: t('organization.tabMenu.users', {
                usersCount: users.length,
              }),
              content: (
                <Segment>
                  <UserList users={users} />
                </Segment>
              ),
            },
            !isParentOnly && {
              tab: t('organization.tabMenu.hrUsers', {
                hrUsersCount: hrUsers.length,
              }),
              content: (
                <Segment>
                  <UserList users={hrUsers} type="hr" />
                </Segment>
              ),
            },
            (isParentOnly || parentOrganizationId != null) && {
              tab: t('organization.tabMenu.adminUsers', {
                adminUsersCount: adminUsers.length,
              }),
              content: (
                <Segment>
                  <UserList users={adminUsers} type="admins" />
                </Segment>
              ),
            },
            !isParentOnly && {
              tab: t('organization.tabMenu.whitelistEmail', {
                emailsCount: emailWhitelist.length,
              }),
              content: (
                <Segment>
                  <WhitelistedEmailList emailWhitelist={emailWhitelist} />
                </Segment>
              ),
            },
          ].filter(Boolean) as Tab[]
        }
      />
    );
  };

  return (
    <div className="column">
      {!isParentOnly ? (
        <div className="column">
          <WhitelistEmail emailWhitelist={emailWhitelist} />
        </div>
      ) : null}
      <div className="column">{renderUserTabs()}</div>
    </div>
  );
};

export default withTranslation()(UsersView);
