import React, { useMemo } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'src/components/Table';

import {
  ArrowUpRightIcon,
  ArrowDownRightIcon,
  ArrowRightIcon,
} from '@heroicons/react/20/solid';
import clsx from 'clsx';

const Trends = {
  up: 'up',
  down: 'down',
  unchanged: 'unchanged',
} as const;

export type Trend = keyof typeof Trends | null;

const { up, down, unchanged } = Trends;

export type MatrixValueColumn = {
  type: 'value';
  key: string;
  id: string;
  header: string;
  headerClassName?: string;
};

export type MatrixTitleColumn = {
  type: 'title';
  id: string;
  key: string;
  header: string;
  headerClassName?: string;
};

export type MatrixColumns = MatrixTitleColumn | MatrixValueColumn;

export type MatrixValueField = {
  label: string | null;
  value: string | number | null;
  trend: Trend | null;
};
export type MatrixDataRow = Record<string, MatrixValueField | string>;

type MatrixTableProps = {
  columns: MatrixColumns[];
  data: MatrixDataRow[];
};

const isValueColumn = (column: MatrixColumns): column is MatrixValueColumn =>
  column.type === 'value';

const isTitleColumn = (column: MatrixColumns): column is MatrixTitleColumn =>
  column.type === 'title';

const isMatrixValueField = (
  field: MatrixValueField | string,
): field is MatrixValueField =>
  typeof field === 'object' &&
  'value' in field &&
  'trend' in field &&
  'label' in field;

const isMatrixTitleField = (
  field: MatrixValueField | string,
): field is string => typeof field === 'string';

const MatrixValueCell = ({ label, value, trend }: MatrixValueField) => (
  <div
    className={clsx(
      'flex flex-col gap-2 w-full h-full',
      trend === up && 'bg-green-200',
      trend === down && 'bg-red-200',
      (trend == null || trend === 'unchanged') && 'bg-white-200',
    )}
  >
    <div className="p-3">
      {label != null && <div>{label}</div>}
      <div className="flex items-center">
        <span className="text-xl">{value ?? ''}</span>
        {trend === up && <ArrowUpRightIcon className="w-6 h-6" />}
        {trend === down && <ArrowDownRightIcon className="w-6 h-6" />}
        {trend === unchanged && <ArrowRightIcon className="w-6 h-6" />}
      </div>
    </div>
  </div>
);

export default function MatrixChart({
  data,
  columns,
}: MatrixTableProps): JSX.Element {
  const cols: ColumnDef<MatrixDataRow>[] = useMemo(() => {
    return columns.map(col => {
      if (isTitleColumn(col)) {
        return {
          id: col.key,
          header: col.header,
          cell: ({ row }) => {
            const title = row.original[col.key];
            if (isMatrixTitleField(title)) {
              return <div className="">{title}</div>;
            }
          },
        };
      }

      if (isValueColumn(col)) {
        return {
          id: col.key,
          header: col.header,
          accessorFn: row => row[col.key],
          cell: ({ row }) => {
            const values = row.original[col.key];
            if (isMatrixValueField(values)) {
              return <MatrixValueCell {...values} />;
            } else {
              throw new Error('Invalid value row');
            }
          },
        };
      }
      throw new Error('Invalid column');
    });
  }, [columns]);

  const table = useReactTable({
    data,
    columns: cols,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <Table>
        <TableHead>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                const col = columns.find(c => c.id === header.id);
                return (
                  <TableHeadCell
                    key={header.id}
                    className={clsx(col?.headerClassName)}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHeadCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map(row => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map(cell => (
                <TableCell key={cell.id} className="!p-0">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
