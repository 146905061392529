import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { wrapAPIDispatch } from 'src/store/middleware/api';

export const useWrappedDispatch = (action: unknown) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<unknown>(null);
  const dispatch = useDispatch();
  const callback = useCallback(wrapAPIDispatch(dispatch, action), [action]);

  const mutate = useCallback(
    async (params: unknown) => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);

      try {
        const res = await callback(params);

        setData(res);
        return res;
      } catch (err) {
        if (err instanceof Error) {
          setError(err);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [callback],
  );

  const state = useMemo(
    () => ({
      isLoading,
      error,
      data,
      mutate,
    }),
    [isLoading, error, data],
  );
  return state;
};
