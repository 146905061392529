import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from 'src/store/actions';
import { wrapAPIDispatch } from 'src/store/middleware/api';

import EmployeeCountsView from './EmployeeCountsView';
import { Dispatch } from 'redux';

type EmployeeCountsContainerProps = {
  updateEmployeeCount: (args: {
    organizationId: string;
    employeeCount: { count: number; date: Date };
  }) => void;
  organizationId: string;
  employeeCounts: { count: number; date: Date }[];
};

type Event = {
  target: {
    name: string;
    value: string;
  };
};

const EmployeeCountsContainer = ({
  employeeCounts,
  organizationId,
  updateEmployeeCount,
}: EmployeeCountsContainerProps) => {
  const [employeeCountState, setEmployeeCountState] = useState<
    number | undefined
  >(() => {
    const latestEmployeeCount = employeeCounts?.at(-1);

    return latestEmployeeCount?.count;
  });

  const handleChange = useCallback((event: Event) => {
    const { value } = event.target;
    const newValue = parseInt(value, 10);

    setEmployeeCountState(newValue);
  }, []);

  const handleSubmit = useCallback(() => {
    if (employeeCountState == null) {
      return;
    }
    updateEmployeeCount({
      organizationId,
      employeeCount: {
        count: employeeCountState,
        date: new Date(),
      },
    });
  }, [actions.updateEmployeeCount, organizationId, employeeCountState]);

  return (
    <EmployeeCountsView
      employeeCount={employeeCountState}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateEmployeeCount: wrapAPIDispatch(dispatch, actions.updateEmployeeCount),
});

export default connect(null, mapDispatchToProps)(EmployeeCountsContainer);
