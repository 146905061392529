// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { wrapAPIDispatch } from 'src/store/middleware/api';
import { getEntityData } from 'src/store/utilities';
import * as actions from 'src/store/actions';
import type { Mobility } from 'src/utilities/flowtypes';

import EditEntityView from './EditEntityView';

type Props = {
  match: {
    params: {
      id: string,
    },
  },
  history: {
    push(string): void,
  },
  loadMobility: ({ id: string }) => Promise<void>,
  createEntity: actions.CreateMobilityParams => Promise<{
    result: { mobility: string },
  }>,
  putEntity: actions.PutMobilityParams => Promise<void>,
  deleteEntity: actions.DeleteLifestyleEntityParams => Promise<void>,
  entity: Mobility,
};

type State = {
  loading: boolean,
  error: ?Error,
  successKey: ?string,
};

class EditMobilityContainer extends Component<Props, State> {
  state = {
    loading: true,
    error: null,
    successKey: null,
  };

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      loadMobility,
    } = this.props;
    if (id !== 'new') {
      loadMobility({ id })
        .catch(err => this.setState({ error: err, successKey: null }))
        .finally(() => this.setState({ loading: false }));
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      entity,
      createEntity,
      putEntity,
      deleteEntity,
      history,
    } = this.props;
    const { loading, error, successKey } = this.state;
    const save = ({
      id,
      ...data
    }: $ReadOnly<{
      ...actions.CreateMobilityParams,
      +id?: string,
    }>) => {
      if (id) {
        putEntity({ ...data, id })
          .then(() => {
            this.setState({ error: null, successKey: 'saved' });
          })
          .catch(err => {
            this.setState({ error: err, successKey: null });
          });
      } else {
        createEntity(data)
          .then(res => {
            this.setState({ error: null, successKey: 'created' });
            history.push(
              `/manage-lifestyle/mobility/${encodeURIComponent(
                res.result.mobility,
              )}`,
            );
          })
          .catch(err => {
            this.setState({ error: err, successKey: null });
          });
      }
    };
    const remove =
      entity && entity.id
        ? () => {
            deleteEntity(entity)
              .then(() => {
                history.push('/manage-lifestyle/mobility/');
              })
              .catch(err => {
                this.setState({ error: err, successKey: null });
              });
          }
        : null;
    return (
      <EditEntityView
        loading={loading}
        error={error}
        successKey={successKey}
        initialEntity={entity}
        remove={remove}
        save={save}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps.match.params;
  const mobility = (state.lifestyleEntities.byId.mobility || {})[id];
  return {
    entity: mobility && {
      ...mobility,
      categories: mobility.categories.map(
        categoryId =>
          getEntityData(state, 'mobilityCategory', categoryId).entity,
      ),
    },
  };
};

const mapDispatchToProps = dispatch => ({
  loadMobility: wrapAPIDispatch(
    dispatch,
    actions.loadLifestyleEntity('mobility'),
  ),
  createEntity: wrapAPIDispatch(
    dispatch,
    actions.createLifestyleEntity('mobility'),
  ),
  putEntity: wrapAPIDispatch(dispatch, actions.putLifestyleEntity('mobility')),
  deleteEntity: wrapAPIDispatch(
    dispatch,
    actions.deleteLifestyleEntity('mobility'),
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditMobilityContainer),
);
