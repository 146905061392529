// @flow
import type { Moment } from 'moment';

import type { User } from 'src/utilities/flowtypes';

type Arguments = {
  users: $ReadOnlyArray<User>,
  fromDate: Moment,
  toDate: Moment,
};

const filterUsersByDate = ({
  users = [],
  fromDate,
  toDate,
}: Arguments): User[] => {
  const firstDay = new Date(
    fromDate.year(),
    fromDate.month(),
    fromDate.day(),
  ).toISOString();
  const lastDay = new Date(
    toDate.year(),
    toDate.month(),
    toDate.day(),
  ).toISOString();
  const filteredUsers = users.filter(
    user => user.createdAt > firstDay && user.createdAt < lastDay,
  );
  return filteredUsers;
};

export default filterUsersByDate;
