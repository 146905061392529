// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  withTranslation,
  type TFunc,
  type BackendMessage,
} from '@pija-ab/i18n-react-system';

import { wrapAPIDispatch } from 'src/store/middleware/api';
import * as actions from 'src/store/actions';

import ResetPasswordView from './ResetPasswordView';

type Props = {
  setPassword: ({ password: string, token: string }) => Promise<{
    result: BackendMessage,
  }>,
  location: {
    pathname: string,
  },
  t: TFunc,
};

type State = {
  password: {
    value: string,
  },
  confirmPassword: {
    value: string,
  },
  message: string,
  errorMessage: string,
  completed: boolean,
  loading: boolean,
};

type Event = {
  target: {
    name: string,
    value: string,
  },
};

const initialState = {
  password: {
    value: '',
  },
  confirmPassword: {
    value: '',
  },
  message: '',
  errorMessage: '',
  completed: false,
  loading: false,
};

class ResetPasswordContainer extends Component<Props, State> {
  state = initialState;

  handleChange = (event: Event) => {
    const { name, value } = event.target;
    this.setState(oldState => ({ [name]: { ...oldState[name], value } }));
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    const { password, confirmPassword } = this.state;
    const { t, location, setPassword } = this.props;
    const { pathname } = location;

    const token = pathname.split('/')[pathname.split('/').length - 1];
    if (password.value === confirmPassword.value) {
      setPassword({
        password: password.value,
        token,
      })
        .then(({ result }) => {
          this.setState({
            ...initialState,
            message: result.message,
            completed: true,
            loading: false,
            errorMessage: '',
          });
        })
        .catch(error => {
          this.setState({
            message: '',
            errorMessage: error.message,
            loading: false,
          });
        });
    } else {
      this.setState({
        message: '',
        errorMessage: t('resetPassword.mustMatch'),
        loading: false,
      });
    }
  };

  render() {
    return (
      <ResetPasswordView
        {...this.state}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setPassword: wrapAPIDispatch(dispatch, actions.setPassword),
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  withTranslation(),
)(ResetPasswordContainer);
