// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { wrapAPIDispatch } from 'src/store/middleware/api';
import * as actions from 'src/store/actions';
import {
  withTranslation,
  type TFunc,
  backendT,
} from '@pija-ab/i18n-react-system';

import { getOrganization } from 'src/store/utilities';
import CreateOrganizationView from './CreateOrganizationView';

type Props = {
  availableParentOrganizations: { value: string, text: string }[],
  createOrganization: ({
    contactEmail: string,
    displayName: string,
    subdomain?: string,
    isParentOnly?: boolean,
    parentOrganizationId?: string,
  }) => Promise<void>,
  loadAllOrganizations: () => void,
  t: TFunc,
};

type State = {
  email: {
    value: string,
  },
  name: {
    value: string,
  },
  subdomain: {
    value: string,
  },
  isParentOnly: {
    value: false,
  },
  parentOrganizationId: {
    value: null,
    disabled: false,
  },
  message: string,
  errorMessage: string,
};

type Event = {
  target: {
    name: string,
    value: string,
  },
};

const initialState = {
  email: { value: '' },
  name: { value: '' },
  subdomain: { value: '' },
  isParentOnly: { value: false },
  parentOrganizationId: { value: null, disabled: false },
  message: '',
  errorMessage: '',
  isPostingOrganization: false,
  isLoadingOrganizations: false,
};

class CreateOrganizationContainer extends Component<Props, State> {
  constructor() {
    super();
    this.state = initialState;
  }

  componentDidMount() {
    this.loadOrganizations();
  }

  loadOrganizations = () => {
    const { loadAllOrganizations } = this.props;
    const { isLoadingOrganizations } = this.state;

    if (isLoadingOrganizations) {
      return;
    }
    this.setState({ isLoadingOrganizations: true });
    loadAllOrganizations().finally(() =>
      this.setState({ isLoadingOrganizations: false }),
    );
  };

  handleChange = (event: Event) => {
    const { name, value } = event.target;
    this.setState(oldState => ({ [name]: { ...oldState[name], value } }));
  };

  handleChangeParentOnly = (event: { target: { checked: boolean } }) => {
    const { checked: value } = event.target;

    this.setState(prevState => ({
      isParentOnly: {
        value,
      },
      parentOrganizationId: {
        value: value ? null : prevState.parentOrganizationId.value,
        disabled: value,
      },
    }));
  };

  handleChangeParentId = (e, { value }) => {
    this.setState(prevState => ({
      parentOrganizationId: {
        value,
        disabled: prevState.parentOrganizationId.disabled,
      },
    }));
  };

  createOrganization = () => {
    const { t, createOrganization } = this.props;
    const {
      email,
      name,
      subdomain,
      isParentOnly,
      parentOrganizationId,
      isPostingOrganization,
    } = this.state;

    const submission = {
      contactEmail: email.value,
      displayName: name.value,
      subdomain: subdomain.value,
      isParentOnly: isParentOnly.value,
      parentOrganizationId: isParentOnly.value
        ? null
        : parentOrganizationId.value,
    };

    if (isPostingOrganization) {
      return;
    }

    this.setState({ isPostingOrganization: true });

    createOrganization(submission)
      .then(() => {
        this.setState({
          ...initialState,
          message: t('createOrganization.created'),
          errorMessage: '',
        });
      })
      .catch(error => {
        this.setState({
          message: '',
          errorMessage: backendT(t, error),
        });
      })
      .then(this.loadOrganizations)
      .finally(() => {
        this.setState({ isPostingOrganization: false });
      });
  };

  render() {
    const { availableParentOrganizations } = this.props;

    return (
      <CreateOrganizationView
        {...this.state}
        createOrganization={this.createOrganization}
        availableParentOrganizations={availableParentOrganizations}
        handleChange={this.handleChange}
        handleChangeParentOnly={this.handleChangeParentOnly}
        handleChangeParentId={this.handleChangeParentId}
      />
    );
  }
}

const mapStateToProps = state => {
  const availableParentOrganizations = state.organizations.all
    .map(organizationId => {
      const { displayName, isParentOnly } =
        getOrganization(state, organizationId).entity || {};
      if (isParentOnly) {
        return {
          text: displayName,
          value: organizationId,
        };
      }
      return null;
    })
    .filter(Boolean);
  return {
    availableParentOrganizations: [
      { value: null, text: 'No parent' },
      ...availableParentOrganizations,
    ],
  };
};

const mapDispatchToProps = dispatch => ({
  createOrganization: wrapAPIDispatch(dispatch, actions.createOrganization),
  loadAllOrganizations: wrapAPIDispatch(dispatch, actions.loadAllOrganizations),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(CreateOrganizationContainer);
