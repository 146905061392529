import React, { useEffect } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { Loading } from 'src/components/elements';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Trans, withTranslation, TFunc } from '@pija-ab/i18n-react-system';
import * as actions from 'src/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { wrapAPIDispatch } from 'src/store/middleware/api';
import { getLoggedInUser } from 'src/store/utilities';

type Props = {
  user: {
    email: string;
    role: string;
    createdAt: string;
  };
  t: TFunc;
  match: {
    url: string;
  };
};

const UserView = ({ user, t, match }: Props) => {
  const dispatch = useDispatch();
  if (!user) {
    return <Loading center />;
  }
  const { email, role, createdAt } = user;
  const createdDate = moment(createdAt).format('YYYY-MM-DD');
  const createdTime = moment(createdAt).format('HH:mm:ss');

  const displayedRole = t(`common:userTypes.${role}`, role);

  const { loggedInUser } = useSelector(selectorState => {
    return { loggedInUser: getLoggedInUser(selectorState).entity };
  });

  useEffect(() => {
    const loadLoggedInUser = wrapAPIDispatch(dispatch, actions.loadCurrentUser);
    loadLoggedInUser();
  }, []);

  return (
    <div className="max-width max-width-extra-narrow">
      <Header as="h1" className="page-title">
        {t('user.title')}
      </Header>
      <Segment>
        <div className="grid grid-x0">
          <div className="column">
            <p>
              <Trans i18nKey="user.email">
                <span className="label">Email:</span> {{ email }}
              </Trans>
            </p>
          </div>
          <div className="column">
            <p>
              <Trans i18nKey="user.role">
                <span className="label">Role:</span> {{ role: displayedRole }}
              </Trans>
            </p>
          </div>
          <div className="column">
            <p>
              <Trans i18nKey="user.created">
                <span className="label">Created:</span> {{ createdDate }}
                {/* $FlowIssue: <Trans> does magic here. */}
                <span style={{ opacity: 0.5 }}>
                  <>{{ createdTime }}</>
                </span>
              </Trans>
            </p>
          </div>
        </div>
      </Segment>
      {loggedInUser && (
        <Trans i18nKey="user.edit">
          <Link to={`${match.url}/edit`}>Edit user</Link>
        </Trans>
      )}
    </div>
  );
};

export default compose(withRouter)(withTranslation()(UserView));
