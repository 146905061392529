import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
} from 'src/store/middleware/api';

type Args = {
  id: string;
  email?: string;
  role?: string;
  organizationId?: string;
  goals?: string;
  name?: string;
  personalInfo?: {
    birthYear?: number;
    height?: number;
    weight?: number;
  };
};

export const UPDATE_USER = 'UPDATE_USER';
export const updateUser = wrapAPIActionCreator((body: Args) => ({
  [API_DATA]: {
    type: UPDATE_USER,
    endpoint: `/api/user/${body.id}`,
    authentication: true,
    method: 'PATCH',
    body: {
      ...(body.email && { email: body.email }),
      ...(body.role && { role: body.role }),
      ...(body.organizationId && { organizationId: body.organizationId }),
      ...(body.goals && { goals: body.goals }),
      ...(body.name && { name: body.name }),
      ...(body.personalInfo && { personalInfo: body.personalInfo }),
    },
    schema: {
      user: Schemas.user,
    },
  },
}));
