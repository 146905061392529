// @flow
import React from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
  match: {
    path: string,
  },
};

export default ({ match }: Props) => {
  return (
    <div className="max-width max-width-extra-narrow subnav">
      <NavLink exact to={`${match.path}/`} className="subnav-item">
        Lifestyle Home
      </NavLink>
      <NavLink to={`${match.path}/mobility`} className="subnav-item">
        Mobility
      </NavLink>
      <NavLink to={`${match.path}/mobilityCategory`} className="subnav-item">
        Mobility Category
      </NavLink>
      <NavLink to={`${match.path}/sleep`} className="subnav-item">
        Sleep
      </NavLink>
      <NavLink to={`${match.path}/sleepCategory`} className="subnav-item">
        Sleep Category
      </NavLink>
      <NavLink to={`${match.path}/mind`} className="subnav-item">
        Mind
      </NavLink>
      <NavLink to={`${match.path}/mindCategory`} className="subnav-item">
        Mind Category
      </NavLink>
    </div>
  );
};
