// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { wrapAPIDispatch } from 'src/store/middleware/api';
import { getLoggedInUser } from 'src/store/utilities';
import * as actions from 'src/store/actions';
import {
  withTranslation,
  type TFunc,
  backendT,
} from '@pija-ab/i18n-react-system';
import LoginView from './LoginView';

type Props = {
  forgotPassword: typeof actions.forgotPassword,
  loginUser: (params: { email: string, password: string }) => Promise<void>,
  loadCurrentUser: () => Promise<void>,
  history: {
    push: string => void,
  },
  userData: {
    accesstoken: string,
  },
  loggedInUser: {
    role: string,
    organizationId: string,
  },
  t: TFunc,
};

type State = {
  email: { value: string },
  password: { value: string },
  loading: boolean,
  errorMessage: string,
};

type Event = {
  target: {
    name: string,
    value: string,
  },
};

class LoginContainer extends Component<Props, State> {
  state = {
    email: { value: '' },
    password: { value: '' },
    loading: false,
    errorMessage: '',
  };

  handleChange = (event: Event) => {
    const { name, value } = event.target;
    this.setState(oldState => ({ [name]: { ...oldState[name], value } }));
  };

  handleSubmit = async () => {
    const { t } = this.props;
    this.setState({ loading: true });
    const {
      email: { value: email },
      password: { value: password },
    } = this.state;
    const { loginUser } = this.props;
    try {
      await loginUser({
        email,
        password,
      });
      const { loadCurrentUser } = this.props;
      await loadCurrentUser();
      const { loggedInUser, history } = this.props;
      if (loggedInUser.role === 'hr') {
        history.push(`/organization/${loggedInUser.organizationId}`);
      } else {
        history.push('/');
      }
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ errorMessage: backendT(t, error) });
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      userData,
      userData: { accessToken },
    } = this.props;
    if (accessToken) {
      return <Redirect to="/" />;
    }
    return (
      <LoginView
        {...this.state}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}
const mapStateToProps = state => ({
  userData: state.userData,
  loggedInUser: getLoggedInUser(state).entity,
});

const mapDispatchToProps = dispatch => ({
  loginUser: wrapAPIDispatch(dispatch, actions.loginUser),
  loadCurrentUser: wrapAPIDispatch(dispatch, actions.loadCurrentUser),
});

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation(),
  )(LoginContainer),
);
