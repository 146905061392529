// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { wrapAPIDispatch } from 'src/store/middleware/api';
import * as actions from 'src/store/actions';
import { getEntityData } from 'src/store/utilities';
import type { BaseCategory } from 'src/utilities/flowtypes';

import CategoryListView from 'src/components/Lifestyle/CategoryListView';

type Props = {
  loadAllSleepCategories: () => void,
  categories: $ReadOnlyArray<BaseCategory>,
};

class CategoryListContainer extends Component<Props> {
  componentDidMount() {
    const { loadAllSleepCategories } = this.props;
    loadAllSleepCategories();
  }

  render() {
    return <CategoryListView {...this.props} entityName="sleep" />;
  }
}

const mapStateToProps = state => {
  const categories = (state.lifestyleCategories.all.sleepCategories || []).map(
    sleepCategoryId =>
      getEntityData(state, 'sleepCategory', sleepCategoryId).entity,
  );
  return {
    categories,
  };
};
const mapDispatchToProps = dispatch => ({
  loadAllSleepCategories: wrapAPIDispatch(
    dispatch,
    actions.loadAllLifestyleCategories('sleep'),
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CategoryListContainer),
);
