import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';

export type ActionsDropdownButtonProps = Readonly<{
  options: {
    text: string;
    onClick: () => void;
  }[];
}>;

export default function ActionsDropdownButton({
  options,
}: ActionsDropdownButtonProps) {
  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button className="flex border-none rounded-md bg-transparent text-black hover:bg-gray-400/10 hover:text-primary-500 cursor-pointer">
          <span className="sr-only">Open action menu</span>
          <EllipsisHorizontalIcon
            className="flex-shrink-0 h-6 w-6"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="divide-y divide-gray-100 absolute left-0 z-10 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {options.map(option => (
            <Menu.Item key={option.text}>
              <button
                onClick={option.onClick}
                className="bg-transparent border-none hover:bg-gray-100 block px-4 py-2 text-[14px] text-gray-700 focus:text-secondary-500 w-full text-start cursor-pointer"
              >
                {option.text}
              </button>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
