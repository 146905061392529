// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NavigationComponentView from './NavigationComponentView';

class NavigationComponentContainer extends PureComponent<{}> {
  render() {
    return <NavigationComponentView {...this.props} />;
  }
}

const mapStateToProps = state => ({
  userData: state.userData,
});

export default withRouter(
  connect(mapStateToProps)(NavigationComponentContainer),
);
