import React, { useEffect, useMemo, useState } from 'react';

import { Header, Segment, Button, Select } from 'semantic-ui-react';
import { useTranslation } from '@pija-ab/i18n-react-system';
import * as actions from 'src/store/actions';
import { useToast } from 'src/components/ui/use-toast';
import { useWrappedDispatch } from 'src/hooks/useWrappedDispatch';

const { REACT_APP_DEV_REASSESSMENT_INTERVALS_ENABLED } = process.env;

type AssessmentIntervalProps = Readonly<{
  assessmentIntervalDays?: number;
  organizationId: string;
}>;

function AssessmentInterval({
  assessmentIntervalDays,
  organizationId,
}: AssessmentIntervalProps): JSX.Element {
  const { t } = useTranslation();

  const [intervalDays, setIntervalDays] = useState<number | undefined>(
    assessmentIntervalDays,
  );

  const toast = useToast();

  useEffect(() => {
    setIntervalDays(assessmentIntervalDays);
  }, [assessmentIntervalDays]);

  const options = useMemo(
    () =>
      [
        REACT_APP_DEV_REASSESSMENT_INTERVALS_ENABLED === 'true' && {
          key: 'organization.assessmentInterval.options.fourDays',
          text: t('organization.assessmentInterval.options.fourDays'),
          value: 4,
        },
        REACT_APP_DEV_REASSESSMENT_INTERVALS_ENABLED === 'true' && {
          key: 'organization.assessmentInterval.options.fourteenDays',
          text: t('organization.assessmentInterval.options.fourteenDays'),
          value: 14,
        },
        {
          key: 'organization.assessmentInterval.options.sixWeeks',
          text: t('organization.assessmentInterval.options.sixWeeks'),
          value: 42,
        },
        {
          key: 'organization.assessmentInterval.options.threeMonths',
          text: t('organization.assessmentInterval.options.threeMonths'),
          value: 91,
        },
        {
          key: 'organization.assessmentInterval.options.sixMonths',
          text: t('organization.assessmentInterval.options.sixMonths'),
          value: 182,
        },
      ].filter(Boolean) as {
        key: string;
        text: string;
        value: number;
      }[],
    [t],
  );

  const { isLoading, mutate } = useWrappedDispatch(actions.updateOrganization);

  const handleSubmit = async () => {
    const res = await mutate({
      organizationId,
      fields: { assessmentIntervalDays: intervalDays },
    });
    if (res) {
      toast.toast({
        title: t('common.messages.changesSaved'),
      });
    }
  };
  return (
    <React.Fragment>
      <Header as="h2">
        {t('organization.assessmentInterval.title', 'Assessment interval')}
      </Header>
      <Segment>
        <p>
          {t(
            'organization.assessmentInterval.description',
            'How often should your users receive a new assessment?',
          )}
        </p>
        <div className="flex flex-row">
          <Select
            fluid
            id="intervalDays"
            name="intervalDays"
            placeholder={t('organization.assessmentInterval.placeholder')}
            options={options}
            value={intervalDays}
            onChange={(ev, { value }) => setIntervalDays(value as number)}
          ></Select>
          <Button loading={isLoading} onClick={handleSubmit}>
            {t('common:buttons.update')}
          </Button>
        </div>
      </Segment>
    </React.Fragment>
  );
}

export default AssessmentInterval;
