// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from 'src/store/actions';
import { wrapAPIDispatch } from 'src/store/middleware/api';

import WhitelistEmailView from './WhitelistEmailView';

type Props = {
  match: {
    params: {
      organizationId: string,
    },
  },
  whitelistEmail: ({
    organizationId: string,
    emailWhitelist: string[],
  }) => Promise<*>,
  emailWhitelist: string[],
};

type State = {
  email: {
    value: string,
  },
  isLoading: boolean,
  message: ?{
    header?: string,
    content: string,
    success?: boolean,
    error?: boolean,
  },
};

type Event = {
  target: {
    name: string,
    value: string,
  },
};

class WhitelistEmailContainer extends Component<Props, State> {
  state = {
    email: { value: '' },
    isLoading: false,
    message: null,
  };

  handleChange = (event: Event) => {
    const { name, value } = event.target;
    this.setState(oldState => ({ [name]: { ...oldState[name], value } }));
  };

  handleSubmit = () => {
    const { match, emailWhitelist, whitelistEmail } = this.props;
    const { organizationId } = match.params;
    const {
      email: { value: email },
    } = this.state;
    const newEmail = email.trim();

    const filteredWhitelist = emailWhitelist.filter(
      curEmail => newEmail !== curEmail,
    );
    filteredWhitelist.push(newEmail);

    this.setState({ isLoading: true });

    whitelistEmail({
      organizationId,
      emailWhitelist: filteredWhitelist,
    })
      .then(() => {
        this.setState({
          email: { value: '' },
          isLoading: false,
          message: {
            content: `Added ${newEmail}!`,
            success: true,
          },
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          message: {
            content:
              'An error occurred, please verify internet connectivity and try again',
            error: true,
          },
        });
      });
  };

  render() {
    const { email, isLoading, message } = this.state;
    return (
      <WhitelistEmailView
        {...this.props}
        email={email}
        isLoading={isLoading}
        message={message}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  whitelistEmail: wrapAPIDispatch(dispatch, actions.whitelistEmail),
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(WhitelistEmailContainer);
