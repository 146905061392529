import React, { createContext, useMemo, useReducer } from 'react';

type SearchContextValueType = {
  [path: string]: string;
};

type SearchContextType = {
  search?: SearchContextValueType;
  dispatch?: React.Dispatch<UpdateSearchAction>;
};

const Context = createContext<SearchContextType>({});

export type UpdateSearchAction = {
  type: 'UPDATE_SEARCH';
  path: string;
  term: string;
};

const initialSearch: SearchContextValueType = {};

const searchReducer = (
  state: SearchContextValueType,
  action: UpdateSearchAction,
) => {
  switch (action.type) {
    case 'UPDATE_SEARCH':
      return {
        ...state,
        [action.path]: action.term,
      };
  }
  return state;
};

export function Provider<T>({
  children,
}: React.PropsWithChildren<T>): JSX.Element {
  const [search, dispatch] = useReducer(searchReducer, initialSearch);

  const value = useMemo(
    () => ({
      search,
      dispatch,
    }),
    [search, dispatch],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export const useSearchContext = () => React.useContext(Context);
