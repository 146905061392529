// @flow
import React from 'react';
import { Button } from 'semantic-ui-react';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

type Props = {
  logoutUser: () => void,
  t: TFunc,
};

const LogoutButtonView = ({ logoutUser, t }: Props) => (
  <Button onClick={logoutUser}>{t('common:buttons.logOut')}</Button>
);

export default withTranslation()(LogoutButtonView);
