// @flow
import {
  API_DATA,
  wrapAPIActionCreator,
  Schemas,
} from 'src/store/middleware/api';

export const LOAD_CURRENT_USER = 'LOAD_CURRENT_USER';
export const loadCurrentUser = wrapAPIActionCreator<void>(() => ({
  [API_DATA]: {
    authentication: true,
    type: LOAD_CURRENT_USER,
    endpoint: '/api/user/me',
    method: 'GET',
    schema: {
      user: Schemas.user,
    },
  },
}));
