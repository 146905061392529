import React, { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Assessment, UserAction, Action, Rotation } from 'src/types/common';

const getUserHistory = ({ userId }: { userId: string }) =>
  axios.get(`/api/user/${userId}/history`).then(({ data }) => data);

const useUserHistoryQuery = (userId: string) => {
  return useQuery<{
    assessments: Assessment[];
    userActions: UserAction[];
    actions: Action[];
    actionsFromLatestAssessment: UserAction[];
    currentRotation: Rotation | null;
  }>({
    queryKey: ['getUserHistory', userId],
    queryFn: () => getUserHistory({ userId }),
  });
};

type UserHistoryPageProps = {
  // match: {
  //   params: {
  //     userId: string;
  //   };
  // };
};

type TimelineAssessmentItem = {
  date: Date;
  type: 'assessment';
  assessment: Assessment;
};

type TimelineUserActionItem = {
  date: Date;
  type: 'userAction';
  userAction: UserAction & { action: Action };
};

export default function UserHistoryPage({}: UserHistoryPageProps) {
  const params = useParams<{ userId: string }>();

  const { data } = useUserHistoryQuery(params.userId);

  const [timeline, actionsInRotation] = useMemo(() => {
    if (data) {
      const actionsMap: Record<string, Action> = {};
      data.actions.forEach(action => {
        actionsMap[action.id] = action;
      });

      const timelineUserActions = data.userActions.map(userAction => {
        return {
          date: new Date(userAction.createdAt),
          type: 'userAction',
          userAction: {
            ...userAction,
            action: actionsMap[userAction.actionId],
          },
        };
      });

      const timelineAssessments = data.assessments.map(assessment => {
        return {
          date: new Date(assessment.createdAt),
          type: 'assessment',
          assessment,
        };
      });

      const tl = [...timelineUserActions, ...timelineAssessments].sort(
        (a, b) => a.date.getTime() - b.date.getTime(),
      );

      const rot = data.actionsFromLatestAssessment.map(userAction => {
        return {
          ...userAction,
          action: actionsMap[userAction.actionId],
        };
      });
      return [tl, rot];
    } else {
      return [[], []];
    }
  }, [data]);

  return (
    <div>
      <h1>User History</h1>
      {timeline?.map(item => {
        if (item.type === 'assessment') {
          const timelineAssessment = item as TimelineAssessmentItem;
          return (
            <div key={timelineAssessment.assessment.id}>
              <h2>Assessment</h2>
              <p>{timelineAssessment.assessment.createdAt}</p>
              <p>
                {timelineAssessment.assessment.completed
                  ? 'Completed'
                  : 'Not completed'}
                :{' '}
                {timelineAssessment.assessment.completedAt?.toString() ?? 'N/A'}
              </p>
              <hr />
            </div>
          );
        }

        if (item.type === 'userAction') {
          const timelineAssessment = item as TimelineUserActionItem;
          const { userAction } = timelineAssessment;
          const { action } = userAction;
          return (
            <div key={userAction.id}>
              <h3>UserAction: {action?.title}</h3>
              <p>{userAction.id}</p>
              <p>{userAction.createdAt}</p>
              <p>Expired at: {userAction.expiredAt}</p>
              <p>{userAction.action?.aid}</p>
              <p className="mt-4 text-lg text-blue-500 strong">
                {userAction.done ? 'Done' : 'Not done'}:{' '}
              </p>
              <ol>
                {userAction.completions.map(c => (
                  <li>{c}</li>
                ))}
              </ol>
              <hr />
            </div>
          );
        }
        return null;
      })}
      {/* {data}
      err: {error} */}
      {actionsInRotation?.length > 0 && (
        <h2>Current Rotation: ({data?.currentRotation?._id})</h2>
      )}
      {data?.currentRotation && (
        <div>
          <h3>Rotation</h3>
          <p>{data.currentRotation._id}</p>
          <p>{data.currentRotation.permutation.join(',')}</p>
          <p>{data.currentRotation.currentIndex}</p>
        </div>
      )}
      {actionsInRotation.map(ua => {
        return (
          <div key={ua.id}>
            <h3>{ua.action.title}</h3>
            <p>{ua.createdAt}</p>
            <p>{ua.id}</p>
            <p>Expired at: {ua.expiredAt}</p>
            <p>{ua.action.aid}</p>
            <p className="mt-4 text-lg text-blue-500 strong">
              {ua.done ? 'Done' : 'Not done'}:{' '}
            </p>
            <ol>
              {ua.completions.map(c => (
                <li>{c}</li>
              ))}
            </ol>
            <hr />
          </div>
        );
      })}
    </div>
  );
}
