import React from 'react';
import classnames from 'classnames';

const Segment = ({
  className,
  children,
}: {
  className?: string;
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  return (
    <div className={classnames('relative bg-white p-3.5 shadow-lg', className)}>
      {children}
    </div>
  );
};
export default Segment;
