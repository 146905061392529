// @flow
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import LogIn from 'src/scenes/LogIn';
import InitialPage from 'src/scenes/InitialPage';
import CreateUser from 'src/scenes/CreateUser';
import EditUser from 'src/scenes/EditUser';
import UserHistory from 'src/scenes/UserHistory';
import Users from 'src/scenes/Users';
import CreateOrganization from 'src/scenes/CreateOrganization';
import OrganizationList from 'src/scenes/OrganizationList';
import Organization from 'src/scenes/Organization';
import { NotFoundPage } from 'src/scenes/NotFound';
import RecoverPassword from 'src/scenes/RecoverPassword';
import ResetPassword from 'src/scenes/ResetPassword';
import User from 'src/scenes/User';
import Lifestyle from 'src/scenes/Lifestyle';

import AuthenticatedComponent from 'src/components/AuthenticatedComponent';
import NoAuthComponent from 'src/components/NoAuthComponent';

const NavigationComponentView = () => (
  <Switch>
    <Route exact path="/">
      <AuthenticatedComponent>
        <InitialPage />
      </AuthenticatedComponent>
    </Route>
    <Route exact path="/login">
      <LogIn />
    </Route>
    <Route exact path="/organization/:organizationId">
      <AuthenticatedComponent>
        <Organization />
      </AuthenticatedComponent>
    </Route>
    <Route exact path="/organization/:organizationId/statistics">
      <AuthenticatedComponent>
        <Organization viewType="statisticsView" />
      </AuthenticatedComponent>
    </Route>
    <Route exact path="/organization/:organizationId/settings">
      <AuthenticatedComponent>
        <Organization viewType="settingsView" />
      </AuthenticatedComponent>
    </Route>
    <Route exact path="/organization/:organizationId/users">
      <AuthenticatedComponent>
        <Organization viewType="usersView" />
      </AuthenticatedComponent>
    </Route>
    <Route exact path="/user/:userId">
      <AuthenticatedComponent>
        <User />
      </AuthenticatedComponent>
    </Route>
    <Route exact path="/organizations">
      <AuthenticatedComponent admin customerAdmin>
        <OrganizationList />
      </AuthenticatedComponent>
    </Route>
    <Route exact path="/create-organization">
      <AuthenticatedComponent admin>
        <CreateOrganization />
      </AuthenticatedComponent>
    </Route>
    <Route exact path="/create-user">
      <AuthenticatedComponent admin>
        <CreateUser />
      </AuthenticatedComponent>
    </Route>
    <Route exact path="/users">
      <AuthenticatedComponent admin>
        <Users />
      </AuthenticatedComponent>
    </Route>
    <Route exact path="/user/:userId/edit">
      <AuthenticatedComponent admin>
        <EditUser />
      </AuthenticatedComponent>
    </Route>
    <Route exact path="/user/:userId/history">
      <AuthenticatedComponent admin>
        <UserHistory />
      </AuthenticatedComponent>
    </Route>
    <Route path="/manage-lifestyle" component={Lifestyle} />
    <Route exact path="/recover-password">
      <NoAuthComponent>
        <RecoverPassword />
      </NoAuthComponent>
    </Route>
    <Route exact path="/reset-password/*">
      <NoAuthComponent>
        <ResetPassword />
      </NoAuthComponent>
    </Route>
    <Route>
      <NotFoundPage />
    </Route>
  </Switch>
);

export default NavigationComponentView;
