// @flow
import React, { Component, useMemo } from 'react';
import { connect } from 'react-redux';
import { wrapAPIDispatch } from 'src/store/middleware/api';
import * as actions from 'src/store/actions';

import type { ModifyableBaseEntity } from 'src/utilities/flowtypes';
import { getEntityData } from 'src/store/utilities';
import CategoriesView from './CategoriesView';

type ExposedProps = {
  categories: $PropertyType<ModifyableBaseEntity, 'categories'>,
  update: (
    | $PropertyType<ModifyableBaseEntity, 'categories'>
    | ((
        $PropertyType<ModifyableBaseEntity, 'categories'>,
      ) => $PropertyType<ModifyableBaseEntity, 'categories'>),
  ) => void,
  entityName: string,
};

type Props = {
  ...ExposedProps,
  loadAllLifestyleCategories: () => Promise<void>,
};

type State = {
  loading: boolean,
  error: ?Error,
};

class CategoriesContainer extends Component<Props, State> {
  state = {
    loading: true,
    error: null,
  };

  componentDidMount() {
    const { loadAllLifestyleCategories } = this.props;
    loadAllLifestyleCategories()
      .catch(err => this.setState({ error: err }))
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const { loading, error } = this.state;
    return <CategoriesView {...this.props} loading={loading} error={error} />;
  }
}

export default ({ ...props }: ExposedProps) => {
  const { entityName } = props;
  const Comp = useMemo(() => {
    const mapStateToProps = state => {
      const availableCategories = (
        state.lifestyleCategories.all[`${entityName}Categories`] || []
      ).map(
        categoryId =>
          getEntityData(state, `${entityName}Category`, categoryId).entity,
      );
      return {
        availableCategories,
      };
    };

    const mapDispatchToProps = dispatch => ({
      loadAllLifestyleCategories: wrapAPIDispatch(
        dispatch,
        actions.loadAllLifestyleCategories(entityName),
      ),
    });
    return connect(mapStateToProps, mapDispatchToProps)(CategoriesContainer);
  }, [entityName]);
  return <Comp {...props} />;
};
