// @flow
import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
} from 'src/store/middleware/api';

type UpdateIncentiveTypes = {
  organizationId: string,
  incentive: string,
};

export const UPDATE_INCENTIVE = 'UPDATE_INCENTIVE';
export const updateIncentive = wrapAPIActionCreator<UpdateIncentiveTypes>(
  ({ organizationId, incentive }) => ({
    [API_DATA]: {
      type: UPDATE_INCENTIVE,
      endpoint: `/api/organization/${organizationId}/incentive`,
      method: 'POST',
      authentication: true,
      body: {
        incentive,
      },
      schema: {
        organization: Schemas.organization,
      },
    },
  }),
);
