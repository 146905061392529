// @flow
import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
} from 'src/store/middleware/api';

type LoadOrganizationStatisticsTypes = {
  organizationId: string,
};

export const LOAD_ORGANIZATION_STATISTICS = 'LOAD_ORGANIZATION_STATISTICS';
export const loadOrganizationStatistics = wrapAPIActionCreator<LoadOrganizationStatisticsTypes>(
  ({ organizationId }) => ({
    [API_DATA]: {
      type: LOAD_ORGANIZATION_STATISTICS,
      endpoint: `/api/organization/${organizationId}/statistics`,
      method: 'GET',
      authentication: true,
      schema: {
        organization: Schemas.organization,
        challenges: [Schemas.challenge],
        goals: [Schemas.goal],
      },
    },
  }),
);
