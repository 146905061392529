// @flow
import React, { Component } from 'react';
import { Header } from 'semantic-ui-react';
import {
  Hint,
  HorizontalGridLines,
  LineMarkSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
  makeWidthFlexible,
} from 'react-vis';
import { withTranslation, Trans, type TFunc } from '@pija-ab/i18n-react-system';
import moment from 'moment';

const FlexibleXYPlot = makeWidthFlexible(XYPlot);

type Props = {
  byMonth: {
    label: string,
    numActiveUsers: number,
    numEmployees?: number,
  }[],
  numEmployees: number,
  employeeCounts: {
    _id: string,
    count: number,
    date: date,
  }[],
  t: TFunc,
};

type Mark = {
  x: string,
  y: ?number,
  hint: string,
};

type State = {
  currentValue: ?Mark,
};

class ParticipationRateChart extends Component<Props, State> {
  state = {
    currentValue: null,
  };

  getSeriesData = () => {
    const { employeeCounts } = this.props;
    const { byMonth = [], numEmployees, t } = this.props;
    const activeData = byMonth
      .slice(-5)
      .map(({ label, numActiveUsers, start }) => {
        let numEmployeesByDate;
        if (employeeCounts.length > 0) {
          const parsedDate = moment(start);
          numEmployeesByDate = employeeCounts
            .slice()
            .sort((a, b) => {
              return new Date(b.date) - new Date(a.date);
            })
            .find(({ date }) => moment(date).isBefore(parsedDate));
          if (!numEmployeesByDate) {
            numEmployeesByDate = employeeCounts
              .slice()
              .sort((a, b) => {
                return new Date(a.date) - new Date(b.date);
              })
              .find(({ date }) => moment(date).isAfter(parsedDate));
          }
        }
        return {
          x: label,
          y:
            numEmployees == null && numEmployeesByDate == null
              ? null
              : Math.min(
                  (numActiveUsers /
                    (numEmployeesByDate?.count || numEmployees || 1)) *
                    100,
                  100,
                ),
          hint: t('organization.stats.rateActive', {
            count: numActiveUsers,
            numEmployees:
              numEmployeesByDate?.count ||
              numEmployees ||
              t('organization.stats.unknown'),
          }),
        };
      });

    return [
      {
        key: 'users-fraction-active',
        title: '% Employees Active',
        color: '#521b44',
        data: activeData,
      },
    ];
  };

  handleValueMouseOver = (value: Mark) => {
    this.setState({ currentValue: value });
  };

  handleValueMouseOut = () => {
    this.setState({ currentValue: null });
  };

  render() {
    const { currentValue } = this.state;
    const series = this.getSeriesData();

    const yTickValues = [0, 20, 40, 60, 80, 100];
    const yMax = 100;

    return (
      <div className="organization-statistic-item organization-statistic-item-chart">
        <div className="grid" style={{ width: '100%' }}>
          <div className="column column-12">
            <Header
              as="h3"
              style={{
                textAlign: 'left',
                paddingTop: '1.6rem',
                paddingLeft: '1rem',
                paddingRight: '1rem',
              }}
            >
              <Trans i18nKey="organization.stats.participationRateTitle">
                Participation Rate <small>(% Active Employees)</small>
              </Trans>
            </Header>
          </div>
          <div className="column column-12">
            <FlexibleXYPlot
              xType="ordinal"
              yDomain={[0, yMax]}
              height={14 * 14}
              margin={{
                left: 14 * 3,
                right: 14 * 2,
                top: 14 * 1,
                bottom: 14 * 4,
              }}
              showLabels
            >
              <VerticalGridLines />
              <HorizontalGridLines />
              <XAxis />
              <YAxis tickValues={yTickValues} />
              {series.map(({ data, color, key }) => (
                <LineMarkSeries
                  key={key}
                  data={data}
                  stroke={color}
                  fill={color}
                  onValueMouseOver={this.handleValueMouseOver}
                  onValueMouseOut={this.handleValueMouseOut}
                  getNull={({ y }) => y != null}
                />
              ))}
              {currentValue != null && (
                <Hint value={currentValue}>
                  <div
                    style={{
                      margin: 4,
                      padding: 4,
                      borderRadius: 4,
                      background: 'rgba(0,0,0, 0.7)',
                    }}
                  >
                    <p>{currentValue.hint}</p>
                  </div>
                </Hint>
              )}
            </FlexibleXYPlot>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ParticipationRateChart);
