import React, { SyntheticEvent, useEffect, useState } from 'react';

import { Form, Dropdown, DropdownProps } from 'semantic-ui-react';
import { Button, Message, Modal, Segment } from 'src/components/elements';
import { withTranslation, TFunc } from '@pija-ab/i18n-react-system';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'src/store/actions';
import { getOrganization } from 'src/store/utilities';
import { wrapAPIDispatch } from 'src/store/middleware/api';
import moment from 'moment';

type State = {
  email: string;
  password?: string;
  organizationId: string;
  role: string;
  message: string;
  errorMessage: string;
};
type Props = {
  setState: (state: State) => void;
  state: State;
  onSubmit: () => void;
  t: TFunc;
  type: 'create' | 'edit';
  user?: {
    id: string;
    archived: boolean;
    archiveDate: string;
  };
};

const EditUserForm = ({
  setState,
  state,
  onSubmit,
  t,
  type,
  user,
}: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const userTypes = [
    {
      text: t('common:userTypes.administrator'),
      value: 'administrator',
    },
    {
      text: t('common:userTypes.customerAdmin'),
      value: 'customer_admin',
    },
    {
      text: t('common:userTypes.hr'),
      value: 'hr',
    },
    {
      text: t('common:userTypes.user'),
      value: 'user',
    },
  ].filter(role => {
    if (type === 'create') {
      return role.value !== 'user';
    }
    return true;
  });

  const dispatch = useDispatch();
  useEffect(() => {
    const load = wrapAPIDispatch(dispatch, actions.loadAllOrganizations);
    load();
  }, []);

  const { organizationDomains } = useSelector(
    (selectorState: {
      organizations: {
        all: string[];
      };
    }) => {
      const od = selectorState.organizations.all
        .map(organizationId => {
          const { id, displayName, archived } =
            getOrganization(selectorState, organizationId).entity || {};
          if (archived) {
            return null;
          }
          return {
            text: displayName,
            value: id,
          };
        })
        .filter(org => org !== null) as {
        text: string;
        value: string;
      }[];

      return {
        organizationDomains: od,
      };
    },
  );

  const handleChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleChangeRole = (
    _e: SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps,
  ) => {
    if (typeof value === 'string') {
      setState({ ...state, role: value });
    }
  };

  const handleChangeDomain = (
    _e: SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps,
  ) => {
    if (typeof value === 'string') {
      setState({ ...state, organizationId: value });
    }
  };

  const { email, password, organizationId, role, message, errorMessage } =
    state || {};

  const handleArchiveUser = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    if (user?.archived != true) {
      try {
        setLoading(true);
        if (user) {
          const archive = wrapAPIDispatch(dispatch, actions.archiveUser);
          await archive({ userId: user.id });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleUnarchiveUser = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    if (user?.archived === true) {
      try {
        setLoading(true);
        if (user) {
          const unarchive = wrapAPIDispatch(dispatch, actions.unarchiveUser);
          await unarchive({ userId: user.id });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (user?.archived && user?.archiveDate) {
      const daysLeft = moment(user.archiveDate)
        .startOf('day')
        .add(90, 'days')
        .diff(moment(), 'days');
      const count = daysLeft > 0 ? daysLeft : 0;
      setWarning(
        t(
          'createUser.userArchivedWarning',
          'This user has been archived and will be deleted in {{count}} days.',
          {
            count,
          },
        ),
      );
    }
    if (warning !== '' && user && !user.archived) {
      setWarning('');
    }
  }, [user]);

  return (
    <>
      <Segment>
        <div className="grid grid-x4">
          {(message || errorMessage || warning) && (
            <div className="column">
              <div className="grid">
                {message && (
                  <div className="column">
                    <Message type="success" content={message} />
                  </div>
                )}
                {errorMessage && (
                  <div className="column">
                    <Message type="error" content={errorMessage} />
                  </div>
                )}
                {warning && (
                  <div className="column">
                    <Message type="warning" content={warning} />
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="column">
            <Form onSubmit={onSubmit}>
              <div className="grid grid-x4">
                <div className="column">
                  <div className="grid">
                    <div className="column">
                      <label className="form-label" htmlFor="email">
                        {t('createUser.userEmail')}
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={email}
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                    {type === 'create' && (
                      <div className="column">
                        <label className="form-label" htmlFor="password">
                          {t('createUser.userPassword')}
                          <input
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    )}
                    <div className="column">
                      <label className="form-label" htmlFor="role">
                        {t('createUser.userRole')}
                        <Dropdown
                          fluid
                          selection
                          required
                          name="role"
                          id="role"
                          value={role}
                          options={userTypes}
                          onChange={handleChangeRole}
                        />
                      </label>
                    </div>
                    <div className="column">
                      <label className="form-label" htmlFor="subdomain">
                        {t('createUser.userOrganization')}
                      </label>
                      <Dropdown
                        fluid
                        selection
                        required
                        name="subdomain"
                        id="subdomain"
                        disabled={
                          role !== 'customer_admin' &&
                          role !== 'hr' &&
                          role !== 'user'
                        }
                        value={organizationId}
                        options={organizationDomains}
                        onChange={handleChangeDomain}
                      />
                    </div>
                  </div>
                </div>
                <div className="column">
                  <Button
                    disabled={
                      loading ||
                      !email ||
                      (type === 'create' && !password) ||
                      !role ||
                      (role !== 'administrator' && !organizationId)
                    }
                    type="success"
                    submit
                  >
                    {type === 'create'
                      ? t('createUser.createUserButton')
                      : t('createUser.updateUserButton')}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Segment>
      <div className="flex p-2 justify-end">
        {type === 'edit' && !user?.archived && (
          <Button
            loading={loading}
            onClick={() => setIsModalOpen(true)}
            type="danger"
          >
            <>{t('createUser.archiveUser')}</>
          </Button>
        )}
        {type === 'edit' && user?.archived && (
          <Button
            loading={loading}
            onClick={handleUnarchiveUser}
            type="warning"
          >
            <>{t('createUser.unarchiveUser')}</>
          </Button>
        )}
      </div>
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onClick={handleArchiveUser}
        confirmText={t('createUser.archive.areYouSure')}
        title={t('createUser.archive.modalTitle')}
        description={t('createUser.archive.description')}
        confirmButtonClassNames="bg-rose-500 hover:bg-rose-600 active:bg-rose-700"
        confirmButtonText={t('createUser.archive.confirm')}
      />
    </>
  );
};

export default withTranslation()(EditUserForm);
