// @flow
import React, { useMemo } from 'react';
import { Segment, Dropdown } from 'semantic-ui-react';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

import type {
  VimeoVideo,
  ModifyableVideoEntity,
} from 'src/utilities/flowtypes';
import VideoListEntry from './components/VideoListEntry';
import type { Lang } from './VideosContainer';

export type Video = {
  ...VimeoVideo,
  lng: ?string,
  default: boolean,
  key: number,
};

type Props = {
  videos: $PropertyType<ModifyableVideoEntity, 'videos'>,
  vimeoVideos: $ReadOnlyArray<VimeoVideo>,
  langs: $ReadOnlyArray<Lang>,
  update: (
    | $PropertyType<ModifyableVideoEntity, 'videos'>
    | ((
        $PropertyType<ModifyableVideoEntity, 'videos'>,
      ) => $PropertyType<ModifyableVideoEntity, 'videos'>),
  ) => void,
  t: TFunc,
};

let keyCounter = 0;
const keys = new WeakMap<{}, number>();
const getKey = vid => {
  let key = keys.get(vid);
  if (key != null) return key;
  keyCounter += 1;
  key = keyCounter;
  keys.set(vid, key);
  return key;
};

const VideosView = ({ videos, update, vimeoVideos, langs, t }: Props) => {
  const selected = useMemo<$ReadOnlyArray<Video>>(() => {
    return videos.map(v => ({
      ...v,
      name: (vimeoVideos.find(({ id }) => id === v.id) || { name: v.id }).name,
      key: getKey(v),
    }));
  }, [videos, vimeoVideos]);
  const handleChange = (ev, data) => {
    const { value } = data;
    update(oldVids => {
      const newVideo = vimeoVideos.find(({ id }) => id === value);
      if (!newVideo) return oldVids;
      return [
        ...oldVids,
        {
          ...newVideo,
          lng: null,
          default: !oldVids.length,
        },
      ];
    });
  };
  return (
    <Segment className="grid">
      <div className="column">
        <h3>{t('lifestyle.video.edit.videos', 'Videos')}</h3>
        <hr />
      </div>
      <div className="column">
        {selected.map(vid => {
          const updateVid = newVid => {
            update(oldVids => {
              if (!newVid) {
                const newVids = oldVids.filter(v => getKey(v) !== vid.key);
                if (newVids.length && !newVids.some(v => v.default)) {
                  newVids[0] = { ...newVids[0], default: true };
                }
                return newVids;
              }
              const resetDefault = newVid.default && !vid.default;
              return oldVids.map(v => {
                if (getKey(v) === newVid.key) {
                  return newVid;
                }
                if (resetDefault && v.default) {
                  return {
                    ...v,
                    default: false,
                  };
                }
                return v;
              });
            });
          };
          return (
            <VideoListEntry
              key={vid.key}
              video={vid}
              langs={langs.filter(
                l =>
                  l.code === vid.lng || !selected.some(v => v.lng === l.code),
              )}
              update={updateVid}
            />
          );
        })}
        <Dropdown
          placeholder={t('lifestyle.video.edit.addVideo', 'Add video')}
          fluid
          search
          selection
          options={[
            {
              key: 'abort',
              value: null,
              text: t('lifestyle.video.edit.addVideo', 'Add video'),
            },
            ...vimeoVideos.map(v => ({
              key: v.id,
              value: v.id,
              text: v.name,
            })),
          ]}
          value={null}
          onChange={handleChange}
          selectOnNavigation={false}
        />
      </div>
    </Segment>
  );
};

export default withTranslation()(VideosView);
