// @flow
import React from 'react';
import { createRoot } from 'react-dom/client';
import pluralize from 'pluralize';
import './polyfills';
import './i18n';
import App from './App/App';
import { unregister } from './registerServiceWorker';
import './styles/semantic-ui.css';
import './styles/index.css';

pluralize.addIrregularRule('sleep', 'sleepies');

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
unregister();
