// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { wrapAPIDispatch } from 'src/store/middleware/api';
import * as actions from 'src/store/actions';
import { getOrganization } from 'src/store/utilities';

import OrganizationListView from './OrganizationListView';

type Props = {
  loadAllOrganizations: () => void,
  organizations: {
    displayName: string,
    subdomain: string,
    contactEmail: string,
  },
};

class OrganizationListContainer extends Component<Props> {
  componentDidMount() {
    const { loadAllOrganizations } = this.props;
    loadAllOrganizations();
  }

  render() {
    return <OrganizationListView {...this.props} />;
  }
}

const mapStateToProps = state => {
  const organizations = state.organizations.all.map(
    organizationId => getOrganization(state, organizationId).entity,
  );
  return {
    organizations,
  };
};
const mapDispatchToProps = dispatch => ({
  loadAllOrganizations: wrapAPIDispatch(dispatch, actions.loadAllOrganizations),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationListContainer),
);
