// @flow
import React, { useState, useEffect } from 'react';
import { Loading } from 'src/components/elements';
import { i18n } from '@pija-ab/i18n-react-system';
import type { BaseCategory } from 'src/utilities/flowtypes';

import CategoryListItem from './CategoryListItem';

type Props = {
  categories: $ReadOnlyArray<BaseCategory>,
  entityName: string,
};

const CategoryListView = ({ categories, entityName }: Props) => {
  const [langpackLoading, setLangpackLoading] = useState(
    () => !i18n.hasResourceBundle(i18n.language, entityName),
  );
  useEffect(() => {
    if (!langpackLoading) return undefined;
    let mounted = true;
    /** @type {import('i18next').i18n} */
    const copyOfI18n = i18n;
    copyOfI18n
      .loadNamespaces(entityName)
      .catch(console.error)
      .finally(() => mounted && setLangpackLoading(false));
    return () => {
      mounted = false;
    };
  }, [langpackLoading]);
  if (categories[0] === null || langpackLoading) {
    return <Loading center />;
  }
  return categories.map<React$Node>(category => (
    <CategoryListItem
      key={category.id}
      category={category}
      entityName={entityName}
    />
  ));
};

export default CategoryListView;
