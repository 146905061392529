import React from 'react';
import classNames from 'classnames';

export default function TableFooter({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}): JSX.Element {
  return (
    <tfoot
      className={classNames(
        'flex-1 py-4 px-6 text-xs text-muted-foreground',
        className,
      )}
    >
      {children}
    </tfoot>
  );
}
