// @flow
import * as ActionTypes from 'src/store/actions';

function users(state: string[] = [], action: any): string[] {
  switch (action.type) {
    case ActionTypes.LOAD_ALL_USERS:
      if (action.status === 'success') {
        return action.payload.result.users;
      }
      return state;
    case ActionTypes.LOGOUT_USER:
      if (action.status === 'success') {
        return [];
      }
      return state;
    default:
      return state;
  }
}

export default users;
