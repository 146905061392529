import React, { useEffect, useMemo } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import {
  TFunc,
  useTranslation,
  withTranslation,
} from '@pija-ab/i18n-react-system';

import 'react-vis/dist/style.css';

import ChallengeChart from './components/ChallengeChart';
import HHIChart from './components/HHIChart';
import GoalBreakdownChart from './components/GoalBreakdownChart';
import MatrixChart from './components/MatrixChart';
import {
  HHI,
  Organization,
  OrganizationStatistics,
  SubOrgHHI,
} from 'src/types/common';
import { HHIRawToDisplayed } from 'src/utilities/HHIRawToDisplayed';
import {
  MatrixColumns,
  MatrixDataRow,
  MatrixValueField,
  Trend,
} from './components/MatrixChart/MatrixChart';

function ButtonGroup({
  selectedIndex = 0,
  buttons = [],
}: {
  selectedIndex?: number;
  buttons: {
    key: string;
    title: string;
    onClick: (index: number) => void;
  }[];
}) {
  return (
    <div className="isolate rounded-md shadow-sm">
      {buttons.map((item, index) => {
        let classNames = '';
        if (index === 0) {
          classNames = `relative inline-flex items-center rounded-l-md px-3 py-2 focus:z-10 ${
            selectedIndex === index
              ? 'bg-gray-300 hover:bg-gray-200 focus:z-10 border font-semibold text-gray-900'
              : 'bg-white focus:z-10 hover:bg-gray-300 border text-gray-900 '
          }`;
        } else if (index === buttons.length - 1) {
          classNames = `relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 focus:z-10 ${
            selectedIndex === index
              ? 'bg-gray-300 hover:bg-gray-200 focus:z-10 border font-semibold text-gray-900'
              : 'bg-white focus:z-10 hover:bg-gray-300 border text-gray-900 '
          }`;
        } else {
          classNames = `relative -ml-px inline-flex items-center px-3 py-2 focus:z-10 ${
            selectedIndex === index
              ? 'bg-gray-300 hover:bg-gray-200 focus:z-10 border font-semibold text-gray-900'
              : 'bg-white focus:z-10 hover:bg-gray-300 border text-gray-900 '
          }`;
        }
        return (
          <button
            key={item.key}
            type="button"
            className={classNames}
            onClick={e => {
              e.preventDefault();
              item.onClick(index);
            }}
          >
            {item.title}
          </button>
        );
      })}
    </div>
  );
}

const SingleOrganizationMatrixChart = ({
  organizationStatistics,
}: {
  organizationStatistics: OrganizationStatistics;
}) => {
  const { averageHHIByOrdinal } = organizationStatistics;

  const { t } = useTranslation();

  const [matrixColumns, matrixData] = useMemo(() => {
    const valueColumns = (averageHHIByOrdinal ?? []).filter(
      ({ minimumRequired, assessmentCount }) =>
        assessmentCount >= minimumRequired,
    );

    const fields = [
      'index',
      'foodPreferences',
      'eatingBehavior',
      'sleepQuality',
      'energyLevel',
      'physicalActivity',
    ] as (keyof HHI)[];

    const columns: MatrixColumns[] = [];

    columns.push({
      key: 'title',
      type: 'title',
      id: 'title',
      header: '',
    });

    const [initial, ...rest] = valueColumns ?? [];

    const rows: MatrixDataRow[] = fields.map(field => {
      let title = t(`organization.specStats.HHI.types.${field}`);

      if (field === 'foodPreferences') {
        title = t('organization.specStats.HHI.types.foodPref');
      }
      const row: MatrixDataRow = {
        title,
      };

      if (!initial) {
        return row;
      }

      const initialValues = HHIRawToDisplayed(initial);
      [initial, ...rest].forEach(valueCol => {
        const values = HHIRawToDisplayed(valueCol);

        let trend: Trend = 'unchanged';
        if (valueCol.ordinal !== '1') {
          if (values[field] > initialValues[field]) {
            trend = 'up';
          } else if (values[field] < initialValues[field]) {
            trend = 'down';
          }
        } else {
          trend = null;
        }

        row[valueCol.ordinal] = {
          value: values[field],
          label: t('organization.specStats.HHI.valueCol.respondentCount', {
            count: valueCol.assessmentCount,
          }),
          trend,
        } as MatrixValueField;
      });

      return row;
    });

    valueColumns?.forEach(val => {
      columns.push({
        type: 'value',
        key: val.ordinal,
        id: val.ordinal,
        header:
          val.ordinal === '1'
            ? 'Initial'
            : `Re-assessment #${parseInt(val.ordinal, 10) - 1}`,
      });
    });

    return [columns, rows];
  }, [averageHHIByOrdinal, t]);

  return (
    <div className="">
      <Header as="h3">
        {t(
          'organization.specStats.hhiMatrixSingle.title',
          'Hälsovaneindex, utveckling',
        )}
      </Header>

      <MatrixChart columns={matrixColumns} data={matrixData} />
    </div>
  );
};

const ParentOrganizationMatrixChart = ({
  aggregatedParentHHI,
}: {
  aggregatedParentHHI: {
    organizationHHI: HHI;
    subOrgs?: SubOrgHHI[];
  };
}) => {
  const { subOrgs } = aggregatedParentHHI;

  const ordinals = useMemo(() => {
    let highestOrdinalCount = 0;
    let returnOrdinals: string[] = [];
    subOrgs?.forEach(({ averageHHIByOrdinal }) => {
      if (averageHHIByOrdinal.length > highestOrdinalCount) {
        highestOrdinalCount = averageHHIByOrdinal.length;
        returnOrdinals = averageHHIByOrdinal.map(({ ordinal }) => ordinal);
      }
    });

    return returnOrdinals;
  }, [subOrgs]);

  const { t } = useTranslation();

  const [selectedOrdinalIndex, setSelectedOrdinalIndex] = React.useState(0);

  useEffect(() => {
    if (ordinals.length > 1) {
      setSelectedOrdinalIndex(1);
    }
  }, [ordinals]);
  const selectedOrdinal = ordinals[selectedOrdinalIndex];

  const ordinalButtons = ordinals.map(ordinal => ({
    key: ordinal,
    title:
      ordinal === '1'
        ? 'Initial'
        : `Re-assessment #${parseInt(ordinal, 10) - 1}`,
    onClick: (index: number) => setSelectedOrdinalIndex(index),
  }));

  const [matrixColumns, matrixData] = useMemo(() => {
    const fields = [
      'index',
      'foodPreferences',
      'eatingBehavior',
      'sleepQuality',
      'energyLevel',
      'physicalActivity',
    ] as (keyof HHI)[];

    const columns: MatrixColumns[] = [];

    columns.push({
      key: 'title',
      type: 'title',
      id: 'title',
      header: t(
        'organization.specStats.parentMatrix.titleCol.title',
        'Avdelning',
      ),
      headerClassName: 'pl-0',
    });

    fields.forEach(field => {
      let header = t(`organization.specStats.HHI.types.${field}`);

      if (field === 'foodPreferences') {
        header = t('organization.specStats.HHI.types.foodPref');
      }
      columns.push({
        key: field,
        type: 'value',
        id: field,
        header,
        headerClassName: field === 'index' ? 'font-bold' : '',
      });
    });
    const rows: MatrixDataRow[] = (subOrgs ?? [])?.map(subOrg => {
      const row: MatrixDataRow = {
        title: subOrg.orgName,
      };

      const initial = subOrg.averageHHIByOrdinal?.[0];
      const selected = subOrg.averageHHIByOrdinal?.find(
        s => s.ordinal === selectedOrdinal,
      );

      if (!initial || !selected) {
        fields.forEach(field => {
          row[field] = {
            value: '-',
            label: t('organization.specStats.HHI.valueCol.respondentCount', {
              count: selected?.assessmentCount ?? 0,
            }),
            trend: null,
          } as MatrixValueField;
        });
        return row;
      }

      const initialValues = HHIRawToDisplayed(initial);

      const selectedValues = HHIRawToDisplayed(selected);

      fields.forEach(field => {
        let trend: Trend = 'unchanged';

        if (selected.ordinal !== '1') {
          if (selectedValues[field] > initialValues[field]) {
            trend = 'up';
          } else if (selectedValues[field] < initialValues[field]) {
            trend = 'down';
          }
        } else {
          trend = null;
        }

        row[field] = {
          value: selectedValues[field],
          label: t('organization.specStats.HHI.valueCol.respondentCount', {
            count: selected.assessmentCount,
          }),
          trend,
        } as MatrixValueField;
      });
      return row;
    });

    return [columns, rows];
  }, [ordinals, subOrgs, selectedOrdinal, t]);

  return (
    <div className="">
      <Header as="h3">
        {t(
          'organization.specStats.hhiMatrixSingle.title',
          'Hälsovaneindex, utveckling',
        )}
      </Header>
      <div className="flex justify-end">
        {ordinals.length > 1 && (
          <ButtonGroup
            selectedIndex={selectedOrdinalIndex}
            buttons={ordinalButtons}
            // className="justify-end"
          />
        )}
      </div>
      <MatrixChart columns={matrixColumns} data={matrixData} />
    </div>
  );
};

type OrganizationStatisticsSpecificProps = {
  organizationStatistics: OrganizationStatistics;
  organization: Organization;
  childOrganizations: Organization[];
  aggregatedParentHHI: {
    organizationHHI: HHI;
    subOrgs?: SubOrgHHI[];
  };
  t: TFunc;
};

const OrganizationStatisticsSpecific = ({
  organizationStatistics,
  organization,
  aggregatedParentHHI,
  t,
}: OrganizationStatisticsSpecificProps) => {
  const {
    employeesPerGoal,
    averageHHI,
    globalAverageHHI,
    parentAverageHHI,
    challengeEngagement,
  } = organizationStatistics;

  return (
    <>
      <Header as="h2">{t('organization.specStats.title')}</Header>
      <Segment className="grid">
        <div className="column column-12 column-6-medium display-flex">
          <HHIChart
            averageHHI={parentAverageHHI ?? averageHHI}
            globalAverageHHI={globalAverageHHI}
            divisionAverageHHI={parentAverageHHI ? averageHHI : null}
          />
        </div>
        <div className="column column-12 column-6-medium display-flex">
          <GoalBreakdownChart goalCounts={employeesPerGoal} />
        </div>
      </Segment>
      <Segment>
        <ChallengeChart challengeEngagement={challengeEngagement} />
      </Segment>
      <Segment>
        {organization?.isParentOnly ? (
          <ParentOrganizationMatrixChart
            aggregatedParentHHI={aggregatedParentHHI}
          />
        ) : (
          <SingleOrganizationMatrixChart
            organizationStatistics={organizationStatistics}
          />
        )}
      </Segment>
    </>
  );
};

export default withTranslation()(OrganizationStatisticsSpecific);
