import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'src/store/actions';
import { wrapAPIDispatch } from 'src/store/middleware/api';
import { getUser, getOrganization } from 'src/store/utilities';
import { Link } from 'react-router-dom';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'src/components/Table';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  useReactTable,
  Table as TableType,
} from '@tanstack/react-table';
import { useTranslation } from '@pija-ab/i18n-react-system';
import { useSearchContext } from 'src/utilities/searchContext';
import { Tabs } from 'src/components/elements';
import moment from 'moment';

type BaseColumns = {
  name: string;
  id: string;
  role: string;
  organizationId: string;
  organizationName: string;
  createdAt: string;
};

type ArchivedColumns = BaseColumns & {
  archived: string;
  deleteIn: number;
};

type AnonymizedColumns = BaseColumns & {
  archived: string;
};

const baseColumns: ColumnDef<BaseColumns>[] = [
  {
    accessorKey: 'name',
    header: 'User Name',
  },
  {
    accessorKey: 'createdAt',
    header: 'Created At',
    cell: ({ row }) => {
      return new Date(row.original.createdAt).toLocaleDateString();
    },
  },
  {
    accessorKey: 'role',
    header: 'Role',
  },
  {
    accessorKey: 'organizationId',
    header: 'Organization',
    cell: ({ row }) => {
      return (
        <Link to={`/organization/${row.original.organizationId}`}>
          {row.original.organizationName}
        </Link>
      );
    },
  },
  {
    accessorKey: 'id',
    header: 'Edit',
    cell: ({ row }) => {
      return (
        <Link to={`/user/${row.original.id}/edit`}>
          <PencilSquareIcon width={20} />
        </Link>
      );
    },
  },
];

const archivedColumns: ColumnDef<ArchivedColumns>[] = [
  ...baseColumns.slice(0, 3),
  {
    accessorKey: 'archived',
    header: 'Archived',
  },
  {
    accessorKey: 'deleteIn',
    header: 'Delete in',
    cell: ({ row }) => {
      let days = row.original.deleteIn;
      if (days < 0) {
        days = 0;
      }
      return days ? `${days} days` : '0 day';
    },
  },
  ...baseColumns.slice(3),
] as ColumnDef<ArchivedColumns>[];

const anonymizedColumns: ColumnDef<AnonymizedColumns>[] = [
  ...baseColumns.slice(0, 3),
  {
    accessorKey: 'archived',
    header: 'Archived',
  },
  ...baseColumns.slice(3),
] as ColumnDef<AnonymizedColumns>[];

export default function ListAllUsers(): JSX.Element {
  const { t } = useTranslation();
  const { search } = useSearchContext();
  const searchUsers = search?.['/users'];
  const { allUsers, allOrganizations } = useSelector(
    (state: { users: string[]; organizations: { all: string[] } }) => {
      let usersData = [];
      let organizationsData = [];
      if (state.users) {
        usersData = state.users.map(uid => getUser(state, uid));
      }
      if (state.organizations?.all) {
        organizationsData = state.organizations?.all?.map(id =>
          getOrganization(state, id),
        );
      }
      return {
        allUsers: usersData,
        allOrganizations: organizationsData,
        users: state.users,
        organizations: state.organizations,
      };
    },
    (left, right) => {
      return (
        left.users === right.users && left.organizations === right.organizations
      );
    },
  );
  function lookupOrganizationName(organizationId: string): string {
    const org = allOrganizations.find(o => o.entity.id === organizationId);
    return org?.entity?.displayName;
  }

  const usersData = useMemo(
    () =>
      allUsers
        .filter(user => !user.entity.archived)
        .map(user => {
          return {
            name: user.entity.email,
            id: user.entity.id,
            role: t(`common:userTypes.${user.entity.role}`, user.entity.role),
            organizationId: user.entity.organizationId,
            organizationName: lookupOrganizationName(
              user.entity.organizationId,
            ),
            createdAt: user.entity.createdAt,
          };
        }),
    [allUsers, t],
  );
  const archivedUsersData = useMemo(
    () =>
      allUsers
        .filter(user => user.entity.archived && !user.entity.isAnonymized)
        .map(user => {
          return {
            name: user.entity.email,
            id: user.entity.id,
            role: t(`common:userTypes.${user.entity.role}`, user.entity.role),
            organizationId: user.entity.organizationId,
            organizationName: lookupOrganizationName(
              user.entity.organizationId,
            ),
            createdAt: user.entity.createdAt,
            archived:
              user.entity.archiveDate &&
              moment(user.entity.archiveDate).format('YYYY-MM-DD'),
            deleteIn:
              user.entity.archiveDate &&
              moment(user.entity.archiveDate)
                .startOf('day')
                .add(90, 'days')
                .diff(moment(), 'days'),
          };
        }),
    [allUsers, t],
  );

  const anonymizedUsersData = useMemo(
    () =>
      allUsers
        .filter(user => user.entity.isAnonymized)
        .map(user => {
          return {
            name: user.entity.email,
            id: user.entity.id,
            role: t(`common:userTypes.${user.entity.role}`, user.entity.role),
            organizationId: user.entity.organizationId,
            organizationName: lookupOrganizationName(
              user.entity.organizationId,
            ),
            createdAt: user.entity.createdAt,
            archived:
              user.entity.archiveDate &&
              moment(user.entity.archiveDate).format('YYYY-MM-DD'),
          };
        }),
    [allUsers, t],
  );

  const usersTable = useReactTable({
    data: usersData,
    columns: baseColumns,
    state: {
      globalFilter: searchUsers,
    },
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const archivedUsersTable = useReactTable({
    data: archivedUsersData,
    columns: archivedColumns,
    state: {
      globalFilter: searchUsers,
    },
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  }) as TableType<BaseColumns | ArchivedColumns>;

  const anonymizedUsersTable = useReactTable({
    data: anonymizedUsersData,
    columns: anonymizedColumns,
    state: {
      globalFilter: searchUsers,
    },
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  }) as TableType<BaseColumns | ArchivedColumns>;

  const dispatch = useDispatch();
  useEffect(() => {
    wrapAPIDispatch(dispatch, actions.loadAllUsers)();
    wrapAPIDispatch(dispatch, actions.loadAllOrganizations)();
  }, []);

  const renderTable = (tableData: TableType<BaseColumns | ArchivedColumns>) => {
    return (
      <div className="max-width">
        <Table>
          <TableHead>
            {tableData.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id} hover={false}>
                {headerGroup.headers.map(header => (
                  <TableHeadCell key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHeadCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {tableData.getRowModel().rows?.length ? (
              tableData.getRowModel().rows.map(row => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={baseColumns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow hover={false}>
              <TableCell colSpan={baseColumns.length}>
                {tableData.getFilteredRowModel().rows.length} row(s).
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  };

  const tabs = [
    {
      tab: t('listUsers.title'),
      content: renderTable(usersTable),
    },
    {
      tab: t('listUsers.archived', 'Archived ({{count}})', {
        count: archivedUsersData.length,
      }),
      content: renderTable(archivedUsersTable),
    },
    process.env.NODE_ENV === 'development'
      ? {
          tab: `Anonymized (${anonymizedUsersData.length}) (dev)`,
          content: renderTable(anonymizedUsersTable),
        }
      : undefined,
    ,
  ].filter(Boolean) as {
    tab: string;
    content: React.JSX.Element;
  }[];

  return <Tabs tabs={tabs} />;
}
