// @flow
import React, { useState, useEffect, useMemo } from 'react';
import * as actions from 'src/store/actions';
import { Segment, Form, Button, Message } from 'semantic-ui-react';
import { Loading } from 'src/components/elements';
import {
  withTranslation,
  backendT,
  type TFunc,
} from '@pija-ab/i18n-react-system';

import type {
  VideoEntity,
  ModifyableVideoEntity,
} from 'src/utilities/flowtypes';

import BasicInfo from './BasicInfo';
import Categories from './Categories';
import Videos from './Videos';
import ImageURL from './ImageURL';

type Props = {|
  initialEntity: ?VideoEntity,
  loading: boolean,
  error: ?Error,
  successKey: ?string,
  remove: null | (() => void),
  save: (
    $ReadOnly<{
      ...actions.CreateVideoEntityParams,
      +id?: string,
    }>,
  ) => void | Promise<void>,
  t: TFunc,
  entityName: string,
|};

type MapStateUpdate = <T: $Keys<ModifyableVideoEntity>>(
  prop: T,
) => (
  | $ElementType<ModifyableVideoEntity, T>
  | ((
      $ElementType<ModifyableVideoEntity, T>,
    ) => $ElementType<ModifyableVideoEntity, T>),
) => void;

const DEFAULT_ENTITY: ModifyableVideoEntity = {
  referenceTitle: '',
  author: '',
  referenceDescription: '',
  referenceSteps: [],
  videos: [],
  imageURL: '',
  categories: [],
  published: true,
};

const EditVideoEntityView = ({
  initialEntity,
  loading,
  remove,
  error,
  successKey,
  save,
  t,
  entityName,
}: Props) => {
  const [entity, updateVideoEntity] = useState<ModifyableVideoEntity>(
    initialEntity || DEFAULT_ENTITY,
  );
  useEffect(() => {
    updateVideoEntity(initialEntity || DEFAULT_ENTITY);
  }, [initialEntity]);
  const mapStateUpdate: MapStateUpdate = useMemo(
    () => prop => {
      return mutator => {
        updateVideoEntity(oldVideoEntity => {
          // $FlowIssue: Should be fine.
          const mutation = {
            // $FlowIssue: Should be fine.
            [prop]:
              typeof mutator === 'function'
                ? // $FlowIssue: Should be fine.
                  mutator(oldVideoEntity[prop])
                : mutator,
          };
          if (mutation[prop] === oldVideoEntity[prop]) return oldVideoEntity;
          return {
            ...oldVideoEntity,
            ...mutation,
          };
        });
      };
    },
    [updateVideoEntity],
  );
  if (loading) {
    return <Loading center />;
  }
  return (
    <Form
      onSubmit={() =>
        save({
          ...entity,
          videos: entity.videos.map(({ id, lng, default: def }) => ({
            id,
            lng: lng || '',
            default: def,
          })),
          categories: entity.categories.map(({ id }) => id),
        })
      }
      success={successKey != null}
      error={error != null}
    >
      {(error != null || successKey != null) && (
        <Segment className="grid">
          <div className="column">
            {error != null && <Message error content={backendT(t, error)} />}
            {successKey != null && (
              <Message
                success
                content={t(`lifestyle.entity.edit.success.${successKey}`)}
              />
            )}
          </div>
        </Segment>
      )}
      <BasicInfo entity={entity} update={updateVideoEntity} />
      <Categories
        entityName={entityName}
        categories={entity.categories}
        update={mapStateUpdate('categories')}
      />
      <Videos videos={entity.videos} update={mapStateUpdate('videos')} />
      <ImageURL imgURL={entity.imageURL} update={updateVideoEntity} />
      {(error != null || successKey != null) && (
        <Segment className="grid">
          <div className="column">
            {error != null && <Message error content={backendT(t, error)} />}
            {successKey != null && (
              <Message
                success
                content={t(`lifestyle.entity.edit.success.${successKey}`)}
              />
            )}
          </div>
        </Segment>
      )}
      <Segment className="grid">
        <div
          className={['column', remove && 'column-6'].filter(Boolean).join(' ')}
        >
          <Button fluid>{t('common:buttons.save')}</Button>
        </div>
        {remove != null && (
          <div className="column column-6">
            <Button
              type="button"
              color="red"
              fluid
              onClick={() =>
                // eslint-disable-next-line no-alert
                window.confirm(t('lifestyle.entity.edit.confirmRemove')) &&
                remove()
              }
            >
              {t('common:buttons.remove')}
            </Button>
          </div>
        )}
      </Segment>
    </Form>
  );
};

export default withTranslation()<Props>(EditVideoEntityView);
