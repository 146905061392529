// @flow
import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
  type SuccessCallback,
  type ErrorCallback,
} from 'src/store/middleware/api';

export type CreateLifestyleCategoryParams = {
  +referenceTitle: string,
  +referenceDescription: string,
  +image: ?string,
  +published: boolean,
};

export const CREATE_LIFESTYLE_CATEGORY = 'CREATE_LIFESTYLE_CATEGORY';
const apiFunc = wrapAPIActionCreator<
  CreateLifestyleCategoryParams & { entityName: string },
>(({ entityName, ...body }) => ({
  [API_DATA]: {
    type: CREATE_LIFESTYLE_CATEGORY,
    endpoint: `/api/${encodeURIComponent(entityName)}category`,
    method: 'POST',
    authentication: true,
    body,
    schema: {
      [`${entityName}Category`]: Schemas[`${entityName}Category`],
    },
  },
}));

export function createLifestyleCategory(
  entityName: string,
): <T: CreateLifestyleCategoryParams>(
  params: $Exact<T>,
  onSuccess?: SuccessCallback,
  onError?: ErrorCallback,
) => any {
  return (params, onSuccess, onError) =>
    apiFunc({ ...params, entityName }, onSuccess, onError);
}
