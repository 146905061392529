// @flow
import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
} from 'src/store/middleware/api';

type ArchiveOrganizationTypes = {
  organizationId: string,
};

export const ARCHIVE_ORGANIZAION = 'ARCHIVE_ORGANIZAION';
export const archiveOrganization = wrapAPIActionCreator<ArchiveOrganizationTypes>(
  ({ organizationId }) => ({
    [API_DATA]: {
      type: ARCHIVE_ORGANIZAION,
      endpoint: `/api/organization/${organizationId}/archive`,
      method: 'POST',
      authentication: true,
      schema: {
        organization: Schemas.organization,
      },
    },
  }),
);
