import React from 'react';
import classnames from 'classnames';

const Loading = ({
  className,
  center,
}: {
  className?: string;
  center?: boolean;
}): JSX.Element => {
  return (
    <div
      className={center ? 'w-full h-full flex justify-center items-center' : ''}
    >
      <svg
        className={classnames(
          'w-10 h-w-10 text-paradiso-500 animate-spin',
          className,
        )}
        viewBox="0 0 160 160"
      >
        <circle
          r="70"
          cx="80"
          cy="80"
          fill="transparent"
          stroke="#e0e0e0"
          strokeWidth="16px"
        ></circle>
        <circle
          r="70"
          cx="80"
          cy="80"
          fill="transparent"
          stroke="currentColor"
          strokeWidth="16px"
          strokeDasharray="439.6px"
          strokeDashoffset="109.9px"
        ></circle>
      </svg>
    </div>
  );
};
export default Loading;
