// @flow
import React from 'react';
/* :: import * as R from 'react'; */

import {
  Form,
  Button,
  Segment,
  Message,
  Input,
  Dropdown,
  Checkbox,
} from 'semantic-ui-react';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

type Props = {
  createOrganization: () => void,
  handleChange: () => void,
  handleChangeParentOnly: () => void,
  handleChangeParentId: () => void,
  subdomain: {
    value: string,
  },
  name: {
    value: string,
  },
  email: {
    value: string,
  },
  isParentOnly: {
    value: boolean,
  },
  parentOrganizationId: {
    value: string | null,
    disabled: boolean,
  },
  availableParentOrganizations: { value: string, text: string }[],
  message: string,
  errorMessage: string,
  t: TFunc,
  isPostingOrganization: boolean,
  isLoadingOrganizations: boolean,
};

function CreateOrganization({
  createOrganization,
  handleChange,
  handleChangeParentOnly,
  handleChangeParentId,
  subdomain,
  name,
  email,
  isParentOnly,
  parentOrganizationId,
  availableParentOrganizations,
  message,
  errorMessage,
  isPostingOrganization,
  isLoadingOrganizations,
  t,
}: Props): R.Node {
  return (
    <Segment>
      <div className="grid grid-x4">
        {(message || errorMessage) && (
          <div className="column">
            <div className="grid">
              {message && (
                <div className="column">
                  <Message success content={message} />
                </div>
              )}
              {errorMessage && (
                <div className="column">
                  <Message error content={errorMessage} />
                </div>
              )}
            </div>
          </div>
        )}
        <div className="column">
          <Form onSubmit={createOrganization}>
            <div className="grid grid-x4">
              <div className="column">
                <div className="grid">
                  <div className="column">
                    <label className="form-label" htmlFor="subdomain">
                      {t('createOrganization.organizationDomain')}
                    </label>
                    <Input
                      fluid
                      type="text"
                      id="subdomain"
                      name="subdomain"
                      label={{
                        basic: true,
                        content: '@holistal.com',
                      }}
                      labelPosition="right"
                      value={subdomain.value}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="column">
                    <label className="form-label" htmlFor="name">
                      {t('createOrganization.organizationName')}
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={name.value}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="column">
                    <label className="form-label" htmlFor="email">
                      {t('createOrganization.contactEmail')}
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={email.value}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="column">
                    <label className="form-label" htmlFor="isParentOnly">
                      {t('createOrganization.isParentOnly')}
                    </label>
                    <div>
                      <Checkbox
                        id="published"
                        name="published"
                        checked={isParentOnly.value}
                        onClick={handleChangeParentOnly}
                      />
                    </div>
                  </div>
                  <div className="column">
                    <label className="form-label" htmlFor="subdomain">
                      {t('createOrganization.parentOrganization')}
                    </label>
                    <Dropdown
                      fluid
                      selection
                      name="parentOrganizationId"
                      id="parentOrganizationId"
                      disabled={parentOrganizationId.disabled}
                      value={parentOrganizationId.value}
                      options={availableParentOrganizations}
                      onChange={handleChangeParentId}
                    />
                  </div>
                </div>
              </div>
              <div className="column">
                <Button
                  fluid
                  loading={isPostingOrganization || isLoadingOrganizations}
                  disabled={isPostingOrganization || isLoadingOrganizations}
                >
                  {t('createOrganization.createOrganizationButton')}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Segment>
  );
}

export default withTranslation()(CreateOrganization);
