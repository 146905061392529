import React from 'react';
import Header from 'src/components/elements/Header';
import { withTranslation, TFunc } from '@pija-ab/i18n-react-system';

import LogoutButton from 'src/components/LogoutButton';

const UserPage = ({ t }: { t: TFunc }) => {
  return (
    <div>
      <Header as="h1">{t('userPage.welcomeUser')}</Header>
      <LogoutButton />
    </div>
  );
};

export default withTranslation()(UserPage);
