// @flow
import React, { useState } from 'react';
/* :: import * as R from 'react'; */

import { RadialChart, makeVisFlexible, Hint } from 'react-vis';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

type Props = {
  numUsers: number,
  numHrUsers: number,
  numWhitelisted: number,
  t: TFunc,
};

function UserTypeChart({
  numUsers,
  numHrUsers,
  numWhitelisted,
  t,
}: Props): R.Node {
  const [hoveredValue, setHoveredValue] = useState(null);
  const total = numUsers + numHrUsers + numWhitelisted;
  const data = [
    {
      angle: numUsers / total,
      color: '#521b44',
      // new keys for this could be added
      title: t('organization.tabMenu.users', {
        usersCount: numUsers,
      }),
    },
    {
      angle: numHrUsers / total,
      color: '#286b74',
      title: t('organization.tabMenu.hrUsers', {
        hrUsersCount: numHrUsers,
      }),
    },
    {
      angle: numWhitelisted / total,
      color: '#ed6540',
      title: t('organization.tabMenu.whitelistEmail', {
        emailsCount: numWhitelisted,
      }),
    },
  ];
  const FlexibleRadialChart = makeVisFlexible(RadialChart);
  return (
    <div className="organization-statistic-item organization-statistic-item-chart">
      <FlexibleRadialChart
        data={data}
        colorType="literal"
        onValueMouseOver={v => {
          setHoveredValue(v);
        }}
        onValueMouseOut={() => setHoveredValue(null)}
      >
        {hoveredValue && (
          <Hint value={hoveredValue}>
            <div
              style={{
                margin: 4,
                padding: 4,
                borderRadius: 4,
                background: 'rgba(0,0,0, 0.7)',
              }}
            >
              <span>{hoveredValue.title}</span>
            </div>
          </Hint>
        )}
      </FlexibleRadialChart>
    </div>
  );
}

export default withTranslation()(UserTypeChart);
