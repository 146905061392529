// @flow
import React, { useState, useEffect } from 'react';
import { Input, Segment, Checkbox } from 'semantic-ui-react';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

import type { ModifyableBaseEntity } from 'src/utilities/flowtypes';

type Props = {|
  entity: ModifyableBaseEntity,
  update: <T: ModifyableBaseEntity>(T | (T => T)) => void,
  t: TFunc,
|};

const BasicInfo = ({ entity, update, t }: Props) => {
  const [referenceTitle, setReferenceTitle] = useState<string>('');
  const [referenceDescription, setReferenceDescription] = useState<string>('');
  const [author, setAuthor] = useState<string>('');
  useEffect(() => {
    setReferenceTitle(entity.referenceTitle);
    setReferenceDescription(entity.referenceDescription);
    setAuthor(entity.author);
  }, [entity.referenceTitle, entity.referenceDescription, entity.author]);
  const onBlur = () => {
    update<ModifyableBaseEntity>(m => ({
      ...m,
      referenceTitle,
      referenceDescription,
      author,
    }));
  };
  const setPublished = published =>
    update<ModifyableBaseEntity>(m => ({ ...m, published }));
  return (
    <Segment className="grid">
      <div className="column">
        <h3>{t('lifestyle.baseEntity.edit.basics', 'Basic info')}</h3>
        <hr />
      </div>
      <div className="column">
        <label className="form-label" htmlFor="referenceTitle">
          {t('lifestyle.baseEntity.edit.referenceTitle', 'Reference title')}
        </label>
        <Input
          fluid
          type="text"
          id="referenceTitle"
          name="referenceTitle"
          value={referenceTitle}
          onChange={(ev, { value }) => setReferenceTitle(value)}
          onBlur={onBlur}
        />
      </div>
      <div className="column">
        <label className="form-label" htmlFor="referenceDescription">
          {t(
            'lifestyle.baseEntity.edit.referenceDescription',
            'Reference description',
          )}
        </label>
        <Input
          fluid
          type="text"
          id="referenceDescription"
          name="referenceDescription"
          value={referenceDescription}
          onChange={(ev, { value }) => setReferenceDescription(value)}
          onBlur={onBlur}
        />
      </div>
      <div className="column">
        <label className="form-label" htmlFor="author">
          {t('lifestyle.basicEntity.edit.author', 'Author')}
        </label>
        <Input
          fluid
          type="text"
          id="author"
          name="author"
          value={author}
          onChange={(ev, { value }) => setAuthor(value)}
          onBlur={onBlur}
        />
      </div>
      <div className="column">
        <label className="form-label" htmlFor="published">
          {t('lifestyle.basicEntity.edit.published', 'Published')}
        </label>
        <div>
          <Checkbox
            id="published"
            name="published"
            checked={
              entity && entity.published != null ? entity.published : true
            }
            onClick={ev => setPublished(ev.target.checked)}
          />
        </div>
      </div>
    </Segment>
  );
};

const thing = withTranslation()(BasicInfo);

export default thing;
