// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { wrapAPIDispatch } from 'src/store/middleware/api';
import * as actions from 'src/store/actions';

import LogoutButtonView from './LogoutButtonView';

type Props = {
  logoutUser: () => void,
  history: {
    push: string => void,
  },
};

class LogoutButtonContainer extends PureComponent<Props> {
  logoutUser = () => {
    const { logoutUser, history } = this.props;
    logoutUser();
    history.push('/');
  };

  render() {
    return <LogoutButtonView logoutUser={this.logoutUser} />;
  }
}

const mapDispatchToProps = dispatch => ({
  logoutUser: wrapAPIDispatch(dispatch, actions.logoutUser),
});

export default withRouter(
  connect(null, mapDispatchToProps)(LogoutButtonContainer),
);
