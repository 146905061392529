// @flow
import React, { useRef } from 'react';
import { Segment, Input, Header, Button, Icon } from 'semantic-ui-react';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

import type { ModifyableMobility } from 'src/utilities/flowtypes';

type Props = {
  referenceSteps: $PropertyType<ModifyableMobility, 'referenceSteps'>,
  update: (
    | $PropertyType<ModifyableMobility, 'referenceSteps'>
    | ((
        $PropertyType<ModifyableMobility, 'referenceSteps'>,
      ) => $PropertyType<ModifyableMobility, 'referenceSteps'>),
  ) => void,
  t: TFunc,
};

const Steps = ({ referenceSteps, update, t }: Props) => {
  const inputRef = useRef();
  const add = step => {
    update(oldReferenceSteps => {
      return [...oldReferenceSteps, step];
    });
  };
  const remove = stepIndex => {
    // Do not use mutator function
    // We depend on the index during render :3
    update([
      ...referenceSteps.slice(0, +stepIndex),
      ...referenceSteps.slice(+stepIndex + 1),
    ]);
  };
  const moveUp = stepIndex => {
    // Do not use mutator function
    // We depend on the index during render :3
    if (stepIndex <= 0) return;
    update([
      ...referenceSteps.slice(0, +stepIndex - 1),
      referenceSteps[stepIndex],
      referenceSteps[stepIndex - 1],
      ...referenceSteps.slice(+stepIndex + 1),
    ]);
  };
  const moveDown = stepIndex => {
    // Do not use mutator function
    // We depend on the index during render :3
    if (stepIndex >= referenceSteps.length - 1) return;
    update([
      ...referenceSteps.slice(0, +stepIndex),
      referenceSteps[stepIndex + 1],
      referenceSteps[stepIndex],
      ...referenceSteps.slice(+stepIndex + 2),
    ]);
  };
  return (
    <Segment className="grid">
      <div className="column">
        <h3>{t('lifestyle.mobility.edit.steps', 'Steps')}</h3>
        <hr />
      </div>
      <div className="column">
        {referenceSteps.map((referenceStep, index) => {
          return (
            <div
              // iterating over primitives. Change is denoted by
              // either index or referenceStep changing. Hence this
              // is a valid key when iterating over primitives.
              // eslint-disable-next-line react/no-array-index-key
              key={`${index}-${referenceStep}`}
              className="inner-segment"
            >
              <div className="grid align-items-center">
                <div className="column min-width-0 flex-grow-1 flex-basis-0">
                  <div className="grid grid-x0">
                    <div className="column">
                      <Header as="h3" size="small" className="secondary">
                        Step {index + 1}
                      </Header>
                    </div>
                    <div className="column">
                      <p>{referenceStep}</p>
                    </div>
                  </div>
                </div>
                <div className="column flex-basis-auto">
                  <div>
                    <Button
                      icon
                      type="button"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={() => moveUp(index)}
                    >
                      <Icon name="arrow up" />
                    </Button>
                  </div>
                  <div>
                    <Button
                      icon
                      type="button"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={() =>
                        // eslint-disable-next-line no-alert
                        window.confirm(
                          `You sure you wanna remove step ${index +
                            1} from this mobility?`,
                        ) && remove(index)
                      }
                    >
                      <Icon name="delete" />
                    </Button>
                  </div>
                  <div>
                    <Button
                      icon
                      type="button"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={() => moveDown(index)}
                    >
                      <Icon name="arrow down" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className="display-flex">
          <div className="flex-grow-1">
            <Input
              placeholder={t('lifestyle.mobility.edit.addStep', 'Add step')}
              fluid
              ref={inputRef}
              onKeyDown={ev => {
                if (ev.key !== 'Enter') return;
                const { target } = ev;
                ev.preventDefault();
                ev.stopPropagation();
                if (target.value) {
                  add(target.value);
                  target.value = '';
                }
              }}
            />
          </div>
          <Button
            icon
            type="button"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              if (!inputRef.current || !inputRef.current.inputRef.current)
                return;
              const target = inputRef.current.inputRef.current;
              if (target.value) {
                add(target.value);
                target.value = '';
              }
            }}
          >
            <Icon name="add" />
          </Button>
        </div>
      </div>
    </Segment>
  );
};

export default withTranslation()(Steps);
