// @flow
import { mapValues, merge } from 'lodash';
import { RESET_ENTITY } from 'src/store/actions/entity';

const initialState = {
  users: {},
  goals: {},
  questions: {},
  assessments: {},
  answers: {},
  actions: {},
  challenges: {},
  recipes: {},
  tips: {},
  organizations: {},
  mobilities: {},
};

const PERSIST_REHYDRATE = 'persist/REHYDRATE';

// Merges new entities from any action with payload.entities set
export default function entities(
  state: typeof initialState = initialState,
  action: any,
): typeof initialState {
  if (action.type === PERSIST_REHYDRATE) {
    // Ignore rehydration actions--these contain the entire persisted state as
    // the payload, which includes the EntityData wrapper metadata already.
    return state;
  }

  if (action.payload && action.payload.entities) {
    const metadata = {
      isFetching: action.status === 'request',
      lastUpdated: Date.now(),
    };
    const wrapMetadata = entity => ({ ...metadata, entity });

    const update = mapValues(action.payload.entities, coll =>
      mapValues(coll, wrapMetadata),
    );
    return merge({}, state, update);
  }
  if (action.type === RESET_ENTITY) {
    const { entityType, entityId } = action.payload;
    const typedEntities = state[entityType];
    typedEntities[entityId].entity = null;
    return {
      ...state,
      [entityType]: typedEntities,
    };
  }
  return state;
}
