import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
} from 'src/store/middleware/api';

export const LOAD_CHILD_ORGANIZATIONS = 'LOAD_CHILD_ORGANIZATIONS';
export const loadChildOrganizations = wrapAPIActionCreator(
  ({ organizationId }: { organizationId: string }) => ({
    [API_DATA]: {
      type: LOAD_CHILD_ORGANIZATIONS,
      endpoint: `/api/organization/${organizationId}/childOrganizations`,
      method: 'GET',
      authentication: true,
      schema: {
        childOrganizations: [Schemas.organization],
      },
    },
  }),
);
