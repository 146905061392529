// @flow
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import type { User } from 'src/utilities/flowtypes';
import * as ActionTypes from 'src/store/actions';

function accessToken(state: ?string = null, action: any): ?string {
  switch (action.type) {
    case ActionTypes.LOGIN_USER:
    case ActionTypes.REFRESH_SESSION:
      if (action.status === 'success') {
        return action.payload.result.accessToken;
      }
      return state;
    case ActionTypes.LOGOUT_USER:
      return null;
    default:
      return state;
  }
}

function refreshToken(state: ?string = null, action: any): ?string {
  switch (action.type) {
    case ActionTypes.LOGIN_USER:
    case ActionTypes.REFRESH_SESSION:
      if (action.status === 'success') {
        return action.payload.result.refreshToken;
      }
      return state;
    case ActionTypes.LOGOUT_USER:
      return null;
    default:
      return state;
  }
}

function isRefreshingSession(state: boolean = false, action: any): boolean {
  switch (action.type) {
    case ActionTypes.REFRESH_SESSION:
      switch (action.status) {
        case 'request':
          return true;
        case 'success':
          return false;
        case 'error':
          return false;
        default:
          return false;
      }
    default:
      return state;
  }
}

function user(state: ?User = null, action: any): ?User {
  switch (action.type) {
    case ActionTypes.LOGIN_USER:
    case ActionTypes.LOAD_CURRENT_USER:
      if (action.status === 'success') {
        return action.payload.result.user;
      }
      return state;
    case ActionTypes.LOGOUT_USER:
      return null;
    default:
      return state;
  }
}

function isLoadingUser(state: boolean = false, action: any): boolean {
  switch (action.type) {
    case ActionTypes.LOAD_CURRENT_USER: {
      if (action.status === 'request') return true;
      return false;
    }
    default:
      return state;
  }
}

const userData = combineReducers({
  accessToken,
  refreshToken,
  isRefreshingSession,
  user,
  isLoadingUser,
});

export const persistConfig = {
  key: 'userData',
  storage,
  whitelist: ['accessToken', 'refreshToken', 'user'],
};

export default persistReducer(persistConfig, userData);
