// @flow
import React, { Component } from 'react';
import {
  XYPlot,
  makeWidthFlexible,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  DiscreteColorLegend,
  Hint,
} from 'react-vis';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

const FlexibleXYPlot = makeWidthFlexible(XYPlot);

const getYTickValues = data => {
  const maxY = Math.max(...data.map(({ y }) => y));
  const yTickStep = maxY < 20 ? 5 : 10;

  const yTickValues = [];
  for (let y = 0; y < maxY; y += yTickStep) {
    yTickValues.push(y);
  }
  yTickValues.push(maxY);

  return yTickValues;
};

type Props = {
  byMonth: {
    label: string,
    numCreatedUsers: number,
    numActiveUsers: number,
  }[],
  t: TFunc,
};

type Mark = {
  x: string,
  y: number,
  format: number => string,
};

type State = {
  currentValue: ?Mark,
};

class ActiveUserChart extends Component<Props, State> {
  state = {
    currentValue: null,
  };

  getSeriesData = () => {
    const { byMonth = [], t } = this.props;

    const createdData = byMonth.slice(-5).map(({ label, numCreatedUsers }) => ({
      x: label,
      y: numCreatedUsers,
      format: v => t('organization.stats.countCreated', { count: v }),
    }));
    const activeData = byMonth.slice(-5).map(({ label, numActiveUsers }) => ({
      x: label,
      y: numActiveUsers,
      format: v => t('organization.stats.countActive', { count: v }),
    }));

    return [
      {
        key: 'users-created',
        title: t('organization.stats.usersCreated'),
        color: '#ed6540',
        data: createdData,
      },
      {
        key: 'users-active',
        title: t('organization.stats.usersActive'),
        color: '#521b44',
        data: activeData,
      },
    ];
  };

  handleValueMouseOver = (value: Mark) => {
    this.setState({ currentValue: value });
  };

  handleValueMouseOut = () => {
    this.setState({ currentValue: null });
  };

  render() {
    const { currentValue } = this.state;
    const series = this.getSeriesData();

    const allData = [].concat(...series.map(({ data }) => data));
    const yTickValues = getYTickValues(allData);
    const yMax = yTickValues.slice(-1)[0];
    return (
      <div className="organization-statistic-item organization-statistic-item-chart ">
        <div className="grid" style={{ width: '100%' }}>
          <div className="column column-12" style={{ paddingTop: '2rem' }}>
            <DiscreteColorLegend orientation="horizontal" items={series} />
          </div>
          <div className="column column-12">
            <FlexibleXYPlot
              xType="ordinal"
              yDomain={[0, yMax]}
              height={14 * 14}
              margin={{
                left: 14 * 3,
                right: 14 * 2,
                top: 14 * 1,
                bottom: 14 * 5,
              }}
              showLabels
            >
              <VerticalGridLines />
              <HorizontalGridLines />
              <XAxis />
              <YAxis tickValues={yTickValues} />
              {series.map(({ data, color, key }) => (
                <VerticalBarSeries
                  key={key}
                  data={data}
                  stroke={color}
                  fill={color}
                  onValueMouseOver={this.handleValueMouseOver}
                  onValueMouseOut={this.handleValueMouseOut}
                />
              ))}
              {currentValue != null && (
                <Hint value={currentValue}>
                  <div
                    style={{
                      margin: 4,
                      padding: 4,
                      borderRadius: 4,
                      background: 'rgba(0,0,0, 0.7)',
                    }}
                  >
                    <p>{currentValue.format(currentValue.y)}</p>
                  </div>
                </Hint>
              )}
            </FlexibleXYPlot>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ActiveUserChart);
