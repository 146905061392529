// @flow
import React from 'react';
import { Loading } from 'src/components/elements';
import { withTranslation } from '@pija-ab/i18n-react-system';
import type { BaseEntity } from 'src/utilities/flowtypes';
import EntityListItem from 'src/components/Lifestyle/EntityListItem';

type Props = {
  entities: $ReadOnlyArray<BaseEntity>,
  entityName: string,
};

const EntityListView = ({ entities, entityName }: Props) => {
  if (entities[0] === null) {
    return <Loading cener />;
  }
  return entities.map(entity => (
    <EntityListItem key={entity.id} entity={entity} entityName={entityName} />
  ));
};

export default withTranslation()<Props>(EntityListView);
