// @flow
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { NotFoundPage } from 'src/scenes/NotFound';

import AuthenticatedComponent from 'src/components/AuthenticatedComponent';

import Nav from './Nav';

import Start from './Start';

import Mobility from './Mobility';
import EditMobility from './Mobility/edit';
import MobilityCategory from './MobilityCategory';
import EditMobilityCategory from './MobilityCategory/edit';

import Sleep from './Sleep';
import EditSleep from './Sleep/edit';
import SleepCategory from './SleepCategory';
import EditSleepCategory from './SleepCategory/edit';

import Mind from './Mind';
import EditMind from './Mind/edit';
import MindCategory from './MindCategory';
import EditMindCategory from './MindCategory/edit';

type Props = {
  match: {
    url: string,
    path: string,
  },
};

const LifestyleRouter = ({ match }: Props) => (
  <AuthenticatedComponent admin>
    <Nav match={match} />
    <Switch>
      <Route exact path={`${match.path}/`} component={Start} />
      <Route exact path={`${match.path}/mobility`} component={Mobility} />
      <Route
        exact
        path={`${match.path}/mobility/:id`}
        component={EditMobility}
      />
      <Route
        exact
        path={`${match.path}/mobilityCategory`}
        component={MobilityCategory}
      />
      <Route
        exact
        path={`${match.path}/mobilityCategory/:id`}
        component={EditMobilityCategory}
      />

      <Route exact path={`${match.path}/sleep`} component={Sleep} />
      <Route exact path={`${match.path}/sleep/:id`} component={EditSleep} />
      <Route
        exact
        path={`${match.path}/sleepCategory`}
        component={SleepCategory}
      />
      <Route
        exact
        path={`${match.path}/sleepCategory/:id`}
        component={EditSleepCategory}
      />

      <Route exact path={`${match.path}/mind`} component={Mind} />
      <Route exact path={`${match.path}/mind/:id`} component={EditMind} />
      <Route
        exact
        path={`${match.path}/mindCategory`}
        component={MindCategory}
      />
      <Route
        exact
        path={`${match.path}/mindCategory/:id`}
        component={EditMindCategory}
      />

      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  </AuthenticatedComponent>
);

export default LifestyleRouter;
