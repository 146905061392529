// @flow
import type { User } from 'src/utilities/flowtypes';

// Store projection functions -------------------
export function getIsLoggedIn(state: Object): boolean {
  return state.userData.accessToken != null;
}

// (Meta)data representing an entity missing from the store
const ENTITY_NOT_FOUND = {
  isFetching: false,
  lastUpdated: 0,
  entity: null,
  collection: null,
};

/**
 * Fetches the (meta)data for an entity given its collection key & ID.
 * Returns an object of the shape { ...metadata, entity }, where `entity` is
 * the actual entity in question (or `null` if not found).
 */
export function getEntityData(
  state: Object,
  key: string,
  id: ?(string | string),
): any {
  if (key == null || state.entities[key] == null) return ENTITY_NOT_FOUND;
  const entityData = state.entities[key][id] || ENTITY_NOT_FOUND;
  return { ...entityData, collection: key };
}

export const getChallenge = (state: Object, id: ?string) =>
  getEntityData(state, 'challenges', id);
export const getUser = (state: Object, id: ?string) =>
  getEntityData(state, 'users', id);
export const getOrganization = (state: Object, id: ?string) =>
  getEntityData(state, 'organizations', id);
export const getGoal = (state: Object, id: ?string) =>
  getEntityData(state, 'goals', id);
export const getVimeoVideo = (state: Object, id: ?string) =>
  getEntityData(state, 'vimeoVideo', id);

export function getLoggedInUser(state: Object): { entity: User } {
  return getUser(state, state.userData.user);
}
