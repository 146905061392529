// @flow
import React, { useState } from 'react';
/* :: import * as R from 'react'; */

import { Header } from 'semantic-ui-react';
import {
  DiscreteColorLegend,
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
  makeWidthFlexible,
  Hint,
} from 'react-vis';
import { withTranslation, type TFunc } from '@pija-ab/i18n-react-system';

const FlexibleXYPlot = makeWidthFlexible(XYPlot);

type HHI = {
  index: number,
  foodPreferences: number,
  eatingBehavior: number,
  energyLevel: number,
  sleepQuality: number,
  physicalActivity: number,
};

type Props = {
  averageHHI: HHI,
  globalAverageHHI: HHI,
  divisionAverageHHI: HHI,
  t: TFunc,
  hideTitle?: boolean,
  divisionName?: string,
};

type Column = {
  key: string,
  label: string,
  type?: string,
};
const columns: Column[] = [
  { key: 'index', label: 'organization.specStats.HHI.types.HHI' },
  { key: 'sep-1', label: '', type: 'separator' },
  {
    key: 'foodPreferences',
    label: 'organization.specStats.HHI.types.foodPref',
  },
  {
    key: 'eatingBehavior',
    label: 'organization.specStats.HHI.types.eatingBehavior',
  },
  { key: 'energyLevel', label: 'organization.specStats.HHI.types.energyLevel' },
  {
    key: 'sleepQuality',
    label: 'organization.specStats.HHI.types.sleepQuality',
  },
  {
    key: 'physicalActivity',
    label: 'organization.specStats.HHI.types.physicalActivity',
  },
];

const tickFormat = (v, t) => {
  const str = (columns[v] || { label: '' }).label;
  if (!str) return str;
  return t(str)
    .split(' ')
    .map((word, i) => (
      <tspan key={word} x={4} dy={4 + 8 * i}>
        {word}
      </tspan>
    ));
};

const getData = hhi =>
  columns.map(({ key, type }: Column, x) => ({
    x,
    y: type === 'separator' ? 0 : 100 * hhi[key],
  }));

function HHIChart({
  averageHHI,
  globalAverageHHI,
  divisionAverageHHI,
  hideTitle = false,
  divisionName,
  t,
}: Props): R.Node {
  const companyData =
    averageHHI &&
    getData(averageHHI).map(({ x, y }) => ({ x: x.toString(), y }));
  const globalData =
    globalAverageHHI &&
    getData(globalAverageHHI).map(({ x, y }) => ({ x: x.toString(), y }));
  const divisionData =
    divisionAverageHHI &&
    getData(divisionAverageHHI).map(({ x, y }) => ({ x: x.toString(), y }));
  const flatData =
    companyData && companyData.map((_, i) => ({ x: i.toString(), y: 0 }));

  const [hoveredValue, setHoveredValue] = useState(null);

  const series = [
    {
      key: 'division',
      title: divisionName
        ? `${divisionName} ${t(
            'organization.specStats.HHI.average',
            'average',
          )}`
        : t('organization.specStats.HHI.divisionAverage'),
      disabled: divisionAverageHHI && divisionAverageHHI.index === 0,
      color: '#286b74',
      data: divisionData,
    },
    {
      key: 'company',
      title: t('organization.specStats.HHI.companyAverage'),
      color: '#ed6540',
      disabled: averageHHI && averageHHI.index === 0,
      data: companyData,
    },
    {
      key: 'global',
      title: t('organization.specStats.HHI.globalAverage'),
      disabled: globalAverageHHI && globalAverageHHI.index === 0,
      color: '#521b44',
      data: globalData,
    },
  ].filter(({ data }) => data != null);

  return (
    <div style={{ height: '100%' }}>
      <div className="grid" style={{ width: '100%' }}>
        {!hideTitle && (
          <div className="column column-12">
            <Header as="h3">{t('organization.specStats.HHI.title')}</Header>
          </div>
        )}
        <div className="column column-12" style={{ textAlign: 'center' }}>
          <DiscreteColorLegend orientation="horizontal" items={series} />
          {averageHHI && averageHHI.index === 0 && (
            <p style={{ color: '#AAA' }}>
              {t('organization.specStats.HHI.needMoreData')}
            </p>
          )}
        </div>
        <div className="column column-12">
          <FlexibleXYPlot
            xType="ordinal"
            yDomain={[0, 100]}
            height={14 * 18}
            margin={{
              left: 14 * 3,
              right: 14 * 1,
              top: 14 * 1,
              bottom: 14 * 6,
            }}
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            <VerticalBarSeries data={flatData} />
            {series.map(({ key, data, color, title }) => (
              <VerticalBarSeries
                key={key}
                data={data}
                stroke={color}
                fill={color}
                animation
                hover
                onValueMouseOver={v => setHoveredValue({ ...v, title })}
                onValueMouseOut={() => setHoveredValue(null)}
              />
            ))}
            <VerticalBarSeries data={flatData} />
            <XAxis
              tickFormat={v => tickFormat(v, t)}
              style={{
                text: {
                  textAnchor: 'start',
                  transform: 'rotate(45deg)',
                },
              }}
            />
            <YAxis />
            {hoveredValue && (
              <Hint value={hoveredValue}>
                <div
                  style={{
                    margin: 4,
                    padding: 4,
                    borderRadius: 4,
                    background: 'rgba(0,0,0, 0.7)',
                  }}
                >
                  <span>
                    {hoveredValue.title}: {Math.floor(hoveredValue.y)}
                  </span>
                </div>
              </Hint>
            )}
          </FlexibleXYPlot>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(HHIChart);
