// @flow
import {
  API_DATA,
  Schemas,
  wrapAPIActionCreator,
} from 'src/store/middleware/api';

export const LOAD_ALL_VIMEO_VIDEOS = 'LOAD_ALL_VIMEO_VIDEOS';
export const loadAllVimeoVideos = wrapAPIActionCreator<void>(() => ({
  [API_DATA]: {
    type: LOAD_ALL_VIMEO_VIDEOS,
    endpoint: '/api/vimeo',
    method: 'GET',
    authentication: true,
    schema: { vimeoVideos: [Schemas.vimeoVideo] },
  },
}));
